import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// hooks

import { useConfigContext } from '../../../Config/hooks/useConfig';

// constants
import { CUSTOMER } from '../../../../constraints';
import appRoutes from '../../../../app-routes';

// Components
import SingleCustomCard from '../../../../components/SingleCustomCard';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const LoginCard = ({ children }) => {
  const history = useHistory();
  const { customer } = useConfigContext();

  const handleRegisterClick = () => {
    history.push(appRoutes.REGISTER);
  };

  return (
    <SingleCustomCard variant="full-width" disableBodyPaddingTop>
      <Styled.Title>
        {
          {
            [CUSTOMER.DIPLOMA]: (
              <FormattedMessage
                id="Panel for publishing diploma on blockchain"
                defailtMessage="Panel for publishing diploma on blockchain"
              />
            ),
            [CUSTOMER.DEFAULT]: (
              <>
                <FormattedMessage
                  id="Panel for publishing documents"
                  defailtMessage="Panel for publishing documents"
                />
              </>
            ),
            [CUSTOMER.OPEN_PLATFORM]: (
              <>
                <FormattedMessage
                  id="Panel for publishing documents"
                  defailtMessage="Panel for publishing documents"
                />
              </>
            ),
            [CUSTOMER.TAURON]: (
              <>
                <FormattedMessage
                  id="Panel for publishing documents"
                  defailtMessage="Corporate publication panel"
                />
              </>
            ),
          }[customer]
        }
      </Styled.Title>

      {[CUSTOMER.DEFAULT, CUSTOMER.TAURON, CUSTOMER.OPEN_PLATFORM].includes(
        customer,
      ) && (
        <Styled.SubTitle>
          <FormattedMessage id="on blockchain" defailtMessage="on blockchain" />
        </Styled.SubTitle>
      )}

      {[CUSTOMER.DIPLOMA].includes(customer) && (
        <Styled.Description>
          <FormattedMessage
            id="Please sign in to publish diploma"
            defailtMessage="Please sign in to publish diploma"
          />
        </Styled.Description>
      )}

      {children}

      {customer === CUSTOMER.TAURON ? (
        <Styled.SupportLabel>
          <FormattedMessage
            id="Forgot your password"
            defaultMessage="Forgot your password"
          />
          {'? '}
          <Link to={appRoutes.SUPPORT}>
            <FormattedMessage
              id="Contact the support team"
              defaultMessage="Contact the support team"
            />
          </Link>
        </Styled.SupportLabel>
      ) : (
        [CUSTOMER.DEFAULT, CUSTOMER.DIPLOMA, CUSTOMER.OPEN_PLATFORM].includes(
          customer,
        ) && (
          <Styled.SupportLabel>
            <Link to={appRoutes.RECOVER_PASSWORD}>
              <FormattedMessage
                id="Forgot your password"
                defaultMessage="Forgot your password"
              />
              {'?'}
            </Link>
          </Styled.SupportLabel>
        )
      )}

      {customer === CUSTOMER.OPEN_PLATFORM && (
        <>
          <Styled.LineHeaderRowContainer>
            <Styled.LineHeaderRowLine />
            <FormattedMessage id="or" defaultMessage="or" />
            <Styled.LineHeaderRowLine />
          </Styled.LineHeaderRowContainer>

          <Button
            block
            size="lg"
            outline
            type="button"
            onClick={handleRegisterClick}
          >
            <FormattedMessage id="Sign up" defaultMessage="Sign up" />
          </Button>
        </>
      )}
    </SingleCustomCard>
  );
};

LoginCard.propTypes = {
  customer: PropTypes.string.isRequired,
};

export default LoginCard;
