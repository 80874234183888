import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

// hooks
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useNotificationContentForm } from './hooks/useNotificationContentForm';

// helpers
import { getNotificationTemplateType } from '../../../../helpers/getNotificationTemplateType';

// constants
import {
  SETTINGS_CONSTANTS,
  PUBLICATION_NOTIFICATION_FIELD_NAMES,
} from '../../../../../../constraints';

// Components
import * as Styled from './styled';
import {
  RenderedNotificationContainer,
  RenderedSMS,
} from '../../../RenderedNotificationContainer/RenderedNotificationContainer';
import { ControlledTextAreaField } from '../../../../../../components/ControlledTextAreaField';
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { FormFooter } from '../../../../../../components/FormFooter';
import HeaderWithToolTip from '../../../../../../components/HeaderWithToolTip';
import { Form as FormModule, Grid, Utils, Layout } from 'billon-ui';

const { Content } = Layout;
const { ErrorContent } = Content;
const { Loader: LoaderModule, Text } = Utils;
const { PageLoader } = LoaderModule;
const { FormGroup } = FormModule;
const { Col, Row } = Grid;

export const NotificationContentForm = ({
  onSubmit,
  onBack,
  disabledTitle,
  isSubmitting,
  defaultValues = {},
  documentBlockchainAddress,
  documentType,
  notificationType,
}) => {
  const { formatMessage } = useIntl();
  const {
    enableSigning,
    notificationTheme,
    enableNotificationManager,
    notificationsConfig,
    isOpenPlatform,
  } = useConfigContext();
  const { enableSms } = notificationsConfig;
  const { inputColumnSize, templateColumnSize } = notificationTheme || {};

  const publicDocumentType = defaultValues?.publicDocumentType?.value;
  const categoryNotificationTemplate =
    defaultValues?.category?.notificationTemplate;
  const categoryId = defaultValues?.category?.value;
  const tenantId = defaultValues?.tenantId;

  const titleMessageId = isOpenPlatform
    ? 'Notification message'
    : 'Notification messages';
  const emailMessageId = isOpenPlatform
    ? 'Example email notification'
    : 'Email notification';

  const notificationTemplateType = useMemo(() => {
    const signingOrder = defaultValues?.signingOrder?.value;
    return getNotificationTemplateType(
      documentType,
      enableSigning,
      signingOrder,
      notificationType,
    );
  }, [defaultValues, enableSigning, documentType, notificationType]);

  const formMethods = useForm({
    defaultValues: {
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_INPUT]: '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_INPUT]: '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_INPUT]: '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_USER_INPUT_TAG_FIELD]:
        '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD]:
        '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD]:
        '',
      ...defaultValues,
    },
  });
  const { handleSubmit, watch, setValue } = formMethods;

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const emailSubjectUserInputTagField = watch(
    PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_USER_INPUT_TAG_FIELD,
  );
  const emailContenttUserInputTagField = watch(
    PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD,
  );
  const smsContenttUserInputTagField = watch(
    PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD,
  );

  const { getSettingValueByKey } = useSettingsContext();
  const maxSmsLength =
    getSettingValueByKey(SETTINGS_CONSTANTS.MAX_SMS_USER_INPUT_LENGTH) || 70;

  const {
    isLoading,
    isError,
    contents,
    defaultInputs,
    isEmailSubjectUserInputTagFieldEnabled,
    isEmailContenttUserInputTagFieldEnabled,
    isSmsContentUserInputTagFieldEnabled,
    emailSubjectRenderValue,
    emailRenderValue,
    smsRenderValue,
  } = useNotificationContentForm({
    documentBlockchainAddress,
    categoryId,
    categoryNotificationTemplate,
    tenantId,
    publicDocumentType,
    notificationTemplateType,
    emailSubjectUserInputTagField,
    emailContenttUserInputTagField,
    smsContenttUserInputTagField,
  });

  useEffect(() => {
    const { emailUserInput, smsUserInput } = defaultInputs || {};
    if (!!emailUserInput) {
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD,
        emailUserInput,
      );
    }
    if (!!smsUserInput) {
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD,
        smsUserInput,
      );
    }
  }, [defaultInputs]);

  useEffect(() => {
    if (!!contents) {
      const { subjectInput, emailInput, smsInput } = contents || {};
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_INPUT,
        subjectInput,
      );
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_INPUT,
        emailInput,
      );
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_INPUT,
        smsInput,
      );
    }
  }, [contents]);

  if (isLoading) {
    return (
      <div>
        <PageLoader
          message={
            <FormattedMessage
              id="Templates are loading..."
              defaultMessage="Templates are loading..."
            />
          }
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <ErrorContent>
          <FormattedMessage
            id="Error while loading templates..."
            defaultMessage="Error while loading templates..."
          />
        </ErrorContent>
        <FormFooter onBack={onBack} isLoading={isLoading} />
      </div>
    );
  }

  if (
    enableNotificationManager &&
    !documentBlockchainAddress &&
    !publicDocumentType &&
    !categoryNotificationTemplate
  ) {
    return (
      <div>
        <ErrorContent>
          <FormattedMessage
            id="Notification content has not been configured for the category"
            defaultMessage="Notification content has not been configured for the category"
          />
        </ErrorContent>
        <FormFooter onBack={onBack} nextBtnDisabled />
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        {!disabledTitle && (
          <Text as="h2" fontWeight={800}>
            <FormattedMessage
              id={titleMessageId}
              defaultMessage={titleMessageId}
            />
            <br />
          </Text>
        )}
        <Text as="h4" fontWeight={800}>
          {isOpenPlatform ? (
            <HeaderWithToolTip
              tooltipId="publication-email-NotificationContentForm"
              title={formatMessage({
                id: emailMessageId,
                defaultMessage: emailMessageId,
              })}
            >
              <FormattedMessage
                id={
                  'such message will be sent by email to the recipient indicated in the previous step'
                }
                defaultMessage={
                  'such message will be sent by email to the recipient indicated in the previous step'
                }
              />
            </HeaderWithToolTip>
          ) : (
            <FormattedMessage
              id={emailMessageId}
              defaultMessage={emailMessageId}
            />
          )}
        </Text>
        <Row>
          <Col md={inputColumnSize}>
            {isEmailSubjectUserInputTagFieldEnabled && (
              <FormGroup>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Enter Subject content"
                    defaultMessage="Enter Subject content"
                  />
                </Text>
                <ControlledTextField
                  name={
                    PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_USER_INPUT_TAG_FIELD
                  }
                  maxLength={null}
                />
              </FormGroup>
            )}
            {isEmailContenttUserInputTagFieldEnabled && (
              <FormGroup>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Enter message"
                    defaultMessage="Enter message"
                  />
                </Text>
                <ControlledTextAreaField
                  name={
                    PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD
                  }
                  maxLength={null}
                  rows={12}
                />
              </FormGroup>
            )}
          </Col>
          <Col
            md={
              !isEmailContenttUserInputTagFieldEnabled ? 12 : templateColumnSize
            }
          >
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              {!!emailSubjectRenderValue && (
                <Styled.EmailSubject>
                  <Styled.EmailSubjectTag>
                    <FormattedMessage id="Subject" defaultMessage="Subject" />
                  </Styled.EmailSubjectTag>
                  {`: ${emailSubjectRenderValue}`}
                </Styled.EmailSubject>
              )}
              <RenderedNotificationContainer
                isFullHeight
                dangerouslySetInnerHTML={{
                  __html: emailRenderValue,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        {enableSms && (
          <>
            <Text as="h4" fontWeight={800}>
              <FormattedMessage
                id="SMS notification"
                defaultMessage="SMS notification"
              />
            </Text>
            <Row>
              {isSmsContentUserInputTagFieldEnabled && (
                <Col md={inputColumnSize}>
                  <FormGroup>
                    <Styled.LabelFlexRow>
                      <Text as="h6" fontSize="12px" fontWeight="600">
                        <FormattedMessage
                          id="Enter message"
                          defaultMessage="Enter message"
                        />
                      </Text>
                      <Text as="h6" fontSize="12px" fontWeight="600">
                        <FormattedMessage
                          id="Number of characters"
                          defaultMessage="Number of characters"
                        />{' '}
                        {smsContenttUserInputTagField?.length || 0}
                        {'/'}
                        {maxSmsLength}
                      </Text>
                    </Styled.LabelFlexRow>
                    <ControlledTextAreaField
                      name={
                        PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD
                      }
                      maxLength={maxSmsLength}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col
                md={
                  !isSmsContentUserInputTagFieldEnabled
                    ? 12
                    : templateColumnSize
                }
              >
                <FormGroup>
                  <Text as="h6" fontSize="12px" fontWeight="600">
                    <FormattedMessage id="Preview" defaultMessage="Preview" />
                  </Text>
                  <RenderedSMS>{smsRenderValue}</RenderedSMS>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <FormFooter onBack={onBack} isLoading={isSubmitting} />
      </Styled.Form>
    </FormProvider>
  );
};
