import { CUSTOMER } from '../../../constraints';

const config = {
  backendUrl:
    'https://apiopenplatformpublication.demo.tdm.bybillon.com/application/v1.0',
  pageTitle: 'Trusted Document Register - Publication Platform',
  customer: CUSTOMER.OPEN_PLATFORM,

  // publication features
  enablePublic: true,
  enablePrivate: false,
  enableSigning: false,
  enableActiveDelivery: false,
  enablePublicationDelay: false,

  // recipients
  manageRecipients: false,
  enableSharedSecret: false,

  availableLanguages: ['en', 'pl'],
  language: sessionStorage.getItem('language') ?? 'en',

  // panels
  mainPanelUrl: 'https://blockchain.billongroup.com',
  readingPanelUrl: 'https://reader.billongroup.com',
};

export default config;
