import React, { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

// hooks
import { usePrivateContentSchema } from '../../../../../../hooks/schemas/usePrivateContentSchema';

// constants
import {
  NOTIFICATION_PREVIEW_TYPES,
  NOTIFICATION_TEMPLATES,
} from '../../../../../../../../constraints';

// Components
import { FormFooter } from '../../../../../../../../components/FormFooter';
import { ControlledTextAreaField } from '../../../../../../../../components/ControlledTextAreaField';
import { HorizontalTenantsList } from '../../../GroupContent/components/HorizontalTenantsList';
import TemplatePreview from '../../../TemplatePreview';
import FieldTitle from '../FieldTitle';
import { Form as FormModule, Grid } from 'billon-ui';

const { FormGroup } = FormModule;
const { Row, Col } = Grid;

const EnterTemplateContentForm = ({
  defaultValues,
  onBack,
  onSubmit,
  submitButtonText,
  labelMessageId,
  placeholderMessageId,
  isLoading,
  name,
  tenants,
  previewType,
}) => {
  const { formatMessage } = useIntl();
  const schema = usePrivateContentSchema({ name });
  const formMethods = useForm({
    defaultValues: { ...defaultValues },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, watch } = formMethods;

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const templateType =
    defaultValues?.notificationType?.value ??
    NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED;
  const templateValue = watch(name);

  const numberOfRows = useMemo(() => {
    const length = templateValue?.length;
    if (previewType === NOTIFICATION_PREVIEW_TYPES.SMS) {
      return 5;
    }
    if (length < 800) {
      return 12;
    }
    return Math.floor(length * (3 / 195));
  }, [templateValue]);

  const firstTenantId = tenants?.length > 0 && tenants[0]?.id;
  const [activeTenantId, setActiveTenantId] = useState(firstTenantId);

  const handleActiveTenantIdChange = (id) => {
    setActiveTenantId(id);
  };

  const handleBack = () => {
    onBack({ [name]: templateValue });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row xl={12}>
          <Col xl={4}>
            <FormGroup>
              <FieldTitle as="h2">
                <FormattedMessage
                  id={labelMessageId}
                  defaultMessage={labelMessageId}
                />
              </FieldTitle>
              <ControlledTextAreaField
                name={name}
                maxLength={null}
                placeholder={formatMessage({
                  id: placeholderMessageId,
                  defaultMessage: placeholderMessageId,
                })}
                rows={numberOfRows}
              />
            </FormGroup>
          </Col>

          <Col xl={8}>
            {tenants?.length > 0 && (
              <Row>
                <HorizontalTenantsList
                  small
                  tenants={tenants}
                  activeTenantId={activeTenantId}
                  handleActiveTenantIdChange={handleActiveTenantIdChange}
                />
              </Row>
            )}
            {tenants?.length > 0 &&
              tenants.map(({ id }) => (
                <Row md={12}>
                  <TemplatePreview
                    tenantId={id}
                    templateType={templateType}
                    previewType={previewType}
                    template={templateValue}
                    activeTenantId={activeTenantId}
                  />
                </Row>
              ))}
          </Col>
        </Row>
        <FormFooter
          onBack={handleBack}
          isLoading={isLoading}
          submitButtonText={submitButtonText}
        />
      </form>
    </FormProvider>
  );
};

export default EnterTemplateContentForm;
