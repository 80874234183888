import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useConfigContext } from '../../../../modules/Config/hooks/useConfig';

import NewTabBadgeAnchor from '../../../NewTabAnchor/NewTabBadgeAnchor';
import {
  WidthSensitiveWrapper,
  BillonPanelTypeBadge,
  OpenPlatformPanelTypeBadge,
} from './styled';

const PanelsNavigation = ({ enableIfMobile }) => {
  const { isOpenPlatform, panelsNavigation } = useConfigContext();

  const history = useHistory();

  const urls = {
    home: '/',
    mainPanel: panelsNavigation?.mainPanelUrl,
    readingPanel: panelsNavigation?.readingPanelUrl,
  };

  const homeClick = () => {
    history.push(urls.home);
  };

  if (isOpenPlatform) {
    return (
      <WidthSensitiveWrapper enableIfMobile={enableIfMobile}>
        <NewTabBadgeAnchor
          enableIfMobile={enableIfMobile}
          href={urls?.mainPanel}
        >
          <FormattedMessage id="Main Panel" defaultMessage="Main Panel" />
        </NewTabBadgeAnchor>
        <OpenPlatformPanelTypeBadge
          active
          onClick={homeClick}
          enableIfMobile={enableIfMobile}
        >
          <FormattedMessage
            id="Publishing Panel"
            defaultMessage="Publishing Panel"
          />
        </OpenPlatformPanelTypeBadge>
        <NewTabBadgeAnchor
          enableIfMobile={enableIfMobile}
          href={urls?.readingPanel}
        >
          <FormattedMessage id="Reading Panel" defaultMessage="Reading Panel" />
        </NewTabBadgeAnchor>
      </WidthSensitiveWrapper>
    );
  }

  return null;

  return (
    <BillonPanelTypeBadge>
      <FormattedMessage
        id="Publishing Panel"
        defaultMessage="Publishing Panel"
      />
    </BillonPanelTypeBadge>
  );
};

export default PanelsNavigation;
