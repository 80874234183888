import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import appRoutes from '../../../../app-routes';
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../constraints';

// hooks
import useFilters from '../../../../hooks/useFilters';
import useValidNotificationGroupContent from '../../hooks/privateNotifications/useValidNotificationGroupContent';
import useActiveNotificationGroupId from '../../hooks/privateNotifications/useActiveNotificationGroupId';

// components
import {
  HeaderWithLoader,
  HeaderWithGroupCreation,
  GroupContent,
  HorizontalNotificationGroupList,
  HeaderNoGroupsWithCreation,
} from './components';
import { Grid, Utils, Layout } from 'billon-ui';

const { Row } = Grid;
const { Text } = Utils;
const { Content } = Layout;
const { ErrorContent } = Content;

const Templates = () => {
  const { filters, navigateWithSearchQuery } = useFilters();
  const {
    activeNotificationGroupId,
    activeNotificationType,
    activeNotificationGroupArchivedSince,
    notificationGroups,
    areNotificationGroupsLoading,
  } = useActiveNotificationGroupId();

  if (!filters?.filters?.groupId && activeNotificationGroupId) {
    navigateWithSearchQuery({
      filters: { groupId: activeNotificationGroupId },
    });
  }

  const { areGroupContentsLoading, validGroupContent } =
    useValidNotificationGroupContent({ activeNotificationGroupId });

  const handleNotificationGroupClick = (groupId) => {
    navigateWithSearchQuery({
      filters: { groupId },
    });
  };

  if (areNotificationGroupsLoading || areGroupContentsLoading) {
    return (
      <HeaderWithLoader
        primaryUrl={appRoutes.NOTIFICATION_TEMPLATES}
        archiveUrl={appRoutes.NOTIFICATION_ARCHIVE}
      />
    );
  }

  if (notificationGroups?.count === 0) {
    return (
      <HeaderNoGroupsWithCreation
        primaryUrl={appRoutes.NOTIFICATION_TEMPLATES}
        archiveUrl={appRoutes.NOTIFICATION_ARCHIVE}
      />
    );
  }

  return (
    <>
      <Row md={12}>
        <HeaderWithGroupCreation
          primaryUrl={appRoutes.NOTIFICATION_TEMPLATES}
          archiveUrl={appRoutes.NOTIFICATION_ARCHIVE}
        />
      </Row>
      <Row margin={'0 0 20px 0'}>
        <HorizontalNotificationGroupList
          activeNotificationGroupId={activeNotificationGroupId}
          notificationGroups={notificationGroups}
          handleNotificationGroupClick={handleNotificationGroupClick}
        />
      </Row>
      {!!validGroupContent ? (
        <GroupContent
          groupContent={validGroupContent}
          notificationType={activeNotificationType}
          archivedSince={activeNotificationGroupArchivedSince}
        />
      ) : (
        <ErrorContent>
          <Text as="h4" margin="4rem 0 0 0">
            <FormattedMessage
              id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_ERROR}
              defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_ERROR}
            />
          </Text>
        </ErrorContent>
      )}
    </>
  );
};

export default Templates;
