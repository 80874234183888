import React from 'react';

import * as Styled from './styled';

const NewTabAnchor = ({ children, href }) => (
  <Styled.Anchor rel="noopener noreferrer" href={href} target="_blank">
    {children}
  </Styled.Anchor>
);

export default NewTabAnchor;
