import styled from 'styled-components';
import { FailureBadge } from '../../../../../../ui/Badge';

export const SuspendedBadge = styled(FailureBadge)`
  font-size: ${(props) => props.theme.fontSizeSm} !important;
  fill: ${(props) => props.theme.palette.grayLighter} !important;
  font-weight: ${(props) => props.theme.fontWeightMedium} !important;
  padding: 8px 24px;
`;

export const SuspendedBadgeWrapper = styled('div')`
  margin-bottom: 16px;
`;
