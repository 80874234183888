import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  USER_FILTER_FIELD_NAMES,
  MAP_USER_FIELD_NAMES,
  MAP_USER_PLACEHOLDERS,
} from '../../../../constraints';

// hooks
import { useConfigContext } from '../../../Config/hooks/useConfig';

// Components
import { ControlledPhoneNumberField } from '../../../../components/ControlledPhoneNumberField';
import { ControlledTextField } from '../../../../components/ControlledTextField';
import { Grid } from 'billon-ui';

const { Col, Row } = Grid;

const DataTab = () => {
  const { isOpenPlatform } = useConfigContext();
  const departmentLabelMessageId = isOpenPlatform
    ? 'Company'
    : MAP_USER_FIELD_NAMES.DEPARTMENT;

  return (
    <>
      <Row>
        <Col xl={6}>
          <ControlledTextField
            name={RECIPIENT_FILTER_FIELD_NAMES.FIRST_NAME}
            label={
              <FormattedMessage
                id={MAP_USER_FIELD_NAMES.FIRST_NAME}
                defaultMessage={MAP_USER_FIELD_NAMES.FIRST_NAME}
              />
            }
            placeholder={MAP_USER_PLACEHOLDERS.FIRST_NAME}
          />
        </Col>
        <Col xl={6}>
          <ControlledTextField
            name={RECIPIENT_FILTER_FIELD_NAMES.LAST_NAME}
            label={
              <FormattedMessage
                id={MAP_USER_FIELD_NAMES.LAST_NAME}
                defaultMessage={MAP_USER_FIELD_NAMES.LAST_NAME}
              />
            }
            placeholder={MAP_USER_PLACEHOLDERS.LAST_NAME}
          />
        </Col>
      </Row>
      <ControlledPhoneNumberField
        name={RECIPIENT_FILTER_FIELD_NAMES.PHONE_NUMBER}
        label={
          <FormattedMessage
            id={MAP_USER_FIELD_NAMES.PHONE_NUMBER}
            defaultMessage={MAP_USER_FIELD_NAMES.PHONE_NUMBER}
          />
        }
        autoHideDialCode
      />
      <ControlledTextField
        name={RECIPIENT_FILTER_FIELD_NAMES.EMAIL}
        label={
          <FormattedMessage
            id={MAP_USER_FIELD_NAMES.EMAIL}
            defaultMessage={MAP_USER_FIELD_NAMES.EMAIL}
          />
        }
        placeholder={MAP_USER_PLACEHOLDERS.EMAIL}
      />
      <ControlledTextField
        name={USER_FILTER_FIELD_NAMES.DEPARTMENT}
        label={
          <FormattedMessage
            id={departmentLabelMessageId}
            defaultMessage={departmentLabelMessageId}
          />
        }
        placeholder={MAP_USER_PLACEHOLDERS.DEPARTMENT}
      />
    </>
  );
};

export default DataTab;
