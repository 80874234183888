import React from 'react';

import useMeRequest from '../../../Settings/hooks/useMeRequest';
import { useSingleCategory } from '../../../Categories/hooks/useSingleCategory';

import MeDetails from '../../components/MeDetails';
import ContentContainerGrey from '../../../../ui/ContentContainerGrey';
import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const MePage = () => {
  const { data: me, isLoading: isMeLoading } = useMeRequest();
  const { data: category, isLoading: isCategoryLoading } = useSingleCategory(
    { id: me?.categoryId },
    { accountingNumberOfDocuments: true },
    { enabled: !!me?.categoryId },
  );
  const { name: publisherName } = category || {};

  if (isMeLoading || isCategoryLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainerGrey>
      <MeDetails me={me} publisherName={publisherName} />
    </ContentContainerGrey>
  );
};

export default MePage;
