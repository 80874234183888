import { useIntl } from 'react-intl';

import { MAP_VALIDATION_LOGIN_ERRORS } from '../../../../constraints';

const BlockedMessage = ({ rawDate }) => {
  const diff = Math.abs(new Date() - new Date(rawDate));
  const delayInMinutes = Math.floor(diff / 1000 / 60);

  const displayedDelay =
    delayInMinutes > 0 ? delayInMinutes : Math.floor(diff / 1000);

  const displayedUnit = delayInMinutes > 0 ? 'min' : 's';

  const { formatMessage } = useIntl();

  return formatMessage({
    id: MAP_VALIDATION_LOGIN_ERRORS.ACCOUNT_TEMPORARY_BLOCKED,
  })
    .replace('{n}', displayedDelay)
    .replace('{unit}', displayedUnit);
};

export default BlockedMessage;
