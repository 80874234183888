import React from 'react';
import PropTypes from 'prop-types';
import { Utils } from 'billon-ui';
import { useConfigContext } from '../../modules/Config/hooks/useConfig';
import logoTd from '../../ui/Assets/tauron-td/logo-td-horizontal.png';

const { BillonLogo } = Utils;

const DMLogo = ({ width, height = 23 }) => {
  const { customerFeatures } = useConfigContext();

  if (customerFeatures?.enableTdLogo) {
    return <img src={logoTd} width={width} height={height} alt="logoTd" />;
  }

  const styles = {};
  if (width) {
    styles.width = `${width}`;
  }
  if (height) {
    styles.height = `${height}`;
  }

  return <BillonLogo style={styles} />;
};

DMLogo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DMLogo;
