import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// hooks
import { useAdministrativePatchPassword } from '../../hooks/useAdministrativePatchPassword';

// Components
import SetPasswordSuccess from '../SetPasswordSuccess';
import PasswordForm from '../PasswordForm';
import SingleCustomCard from '../../../../components/SingleCustomCard';

const AdministrativePasswordChange = ({ id, mode, role }) => {
  const history = useHistory();
  const [reasons, setReasons] = useState([]);

  const handleOnFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };
  const {
    mutate: patchPassword,
    isLoading,
    isSuccess,
  } = useAdministrativePatchPassword({
    onError: handleOnFailure,
  });

  const handleOnValidationSuccess = (passwords) => {
    patchPassword({ id: id, passwordData: passwords });
  };

  const handleOnClose = () => {
    history.push(`/user/${id}`);
  };

  const formConfig = {
    mode: mode,
    handleSuccess: handleOnValidationSuccess,
    passwordLabelId: 'New password',
    repeatPasswordLabelId: 'Repeat new password',
    submitMessageId: 'Change password',
  };

  if (isSuccess) {
    return (
      <SetPasswordSuccess
        onClose={handleOnClose}
        messageId={'Password successfully changed'}
      />
    );
  }

  return (
    <SingleCustomCard titleId="Password change">
      <PasswordForm
        deliveredRole={role}
        formConfig={formConfig}
        deliveredReasons={reasons}
        deliveredLoading={isLoading}
      />
    </SingleCustomCard>
  );
};

export default AdministrativePasswordChange;
