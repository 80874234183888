import styled from 'styled-components';

import { Utils } from 'billon-ui';

import NavButton from '../NavButton';

export const CardHeader = styled(Utils.Text)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: ${(props) => props.theme.fontSize24};
  margin-bottom: 0;
`;

export const CardHeaderNeighbour = styled('span')`
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  font-size: ${(props) => props.theme.fontSize18};
  color: ${(props) => props.theme.palette.primary};
  margin-left: 0.75rem;
`;

export const NavTextButton = styled(NavButton)`
  color: ${(props) => props.theme.bodyColor};
`;
