import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { CalendarField } from '../../containers';

export const ControlledCalendarField = ({
  name,
  disabled,
  placeholder,
  onInputChange,
  range,
  minDate,
  maxDate,
  enableTime,
  displayTime,
  showMonths,
  onClear,
}) => {
  const intl = useIntl();
  const { locale } = intl || {};
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const handleInputChange = (...args) => {
    onInputChange && onInputChange(...args);
  };
  const handleClear = (...args) => {
    onClear && onClear(...args);
  };

  const dateFormat = useMemo(() => {
    if (displayTime) {
      if (locale === 'pl') {
        return 'd.m.Y, H:i:ss';
      }
      return 'm/d/Y, h:i:ss K';
    } else {
      if (locale === 'pl') {
        return 'd.m.Y';
      }
      return 'm/d/Y';
    }
  }, [displayTime, locale]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <CalendarField
          input={{
            ...field,
            onInputChange: handleInputChange,
            onClear: handleClear,
          }}
          meta={{
            touched: fieldState.invalid,
            error: errors?.[field.name]?.message,
          }}
          enableTime={enableTime}
          dateFormat={dateFormat}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          placeholder={placeholder}
          range={range}
          showMonths={showMonths}
          onClear={onClear}
        />
      )}
    />
  );
};
