import React from 'react';

// constants
import {
  NOTIFICATION_MODULE_MENU_LABEL,
  NOTIFICATION_PREVIEW_TYPES,
} from '../../../../../../constraints';
import { useTenantList } from '../../../../../Tenants/hooks/useTenantList';

// hooks
import { usePrivateSingleNotificationGroupContent } from '../../../../hooks/privateNotifications/usePrivateSingleNotificationGroupContent';

// components
import {
  NotificationSection,
  GroupHeaderManageButton,
  PlannedArchivization,
} from './components';
import { Grid, Utils } from 'billon-ui';

const { Row } = Grid;
const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const GroupContent = ({ groupContent, notificationType, archivedSince }) => {
  const { data: groupContentData, isFetching: isGroupContentLoading } =
    usePrivateSingleNotificationGroupContent(groupContent?.id, {});

  const { data: tenants, isLoading: areTenantsLoading } = useTenantList();

  if (isGroupContentLoading || areTenantsLoading) {
    return <Loader width={100} />;
  }

  return (
    <>
      <Row>
        <GroupHeaderManageButton
          titleMessageId={NOTIFICATION_MODULE_MENU_LABEL.CONTENTS_CONFIGURATION}
        />
      </Row>
      {!!archivedSince && (
        <Row>
          <PlannedArchivization archivedSince={archivedSince} />
        </Row>
      )}
      <Row md={12}>
        <NotificationSection
          titleMessageId={NOTIFICATION_MODULE_MENU_LABEL.EMAIL}
          validSince={groupContentData?.validSince}
          validUntil={groupContentData?.validUntil}
          template={groupContentData?.emailTemplate}
          tenants={tenants}
          previewType={NOTIFICATION_PREVIEW_TYPES.EMAIL}
          isMinusMarginTop={true}
          notificationType={notificationType}
        />
      </Row>
      <br />
      <Row md={12}>
        <NotificationSection
          titleMessageId={NOTIFICATION_MODULE_MENU_LABEL.SMS}
          validSince={groupContentData?.validSince}
          validUntil={groupContentData?.validUntil}
          template={groupContentData?.smsTemplate}
          tenants={tenants}
          previewType={NOTIFICATION_PREVIEW_TYPES.SMS}
          notificationType={notificationType}
        />
      </Row>
    </>
  );
};

export default GroupContent;
