import styled from 'styled-components';

import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const WebViewer = styled('div')`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: none;
`;

export const CloseViewerButton = styled(Button)`
  z-index: 1001;
  position: absolute;
  bottom: 50px;
  right: 100px;
  display: none;
`;
