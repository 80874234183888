import apiRoutes from '../../../api-routes';
import { QueryKeys } from '../../../queryKeys';

import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

import { getRequest } from '../../../helpers/apiClient';

const useMeRequest = (options, token) => {
  return useQueryWithLogout(
    [QueryKeys.ME],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.ME,
        headers: {
          Authorization: token ?? sessionStorage.getItem('token'),
        },
      });
      return data;
    },
    options,
  );
};

export default useMeRequest;
