import styled from 'styled-components';

import { Utils, Grid } from 'billon-ui';

const { Text } = Utils;
const { Row } = Grid;

export const Centered = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  background-color: white;
  padding-top: 0;
`;

export const EmptySubtitle = styled(Text)`
  font-size: ${(props) => props.theme.fontSizeLg};
  font-weight: ${(props) => props.theme.fontWeightMedium};
  color: ${(props) => props.theme.palette.gray};
`;
