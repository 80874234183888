import React from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { RecoverPasswordSuccess } from '../../components';
import { DefaultContentCol } from '../../../../components/UniCardContent/UniCardContent';

const RecoverPasswordSuccessPage = () => {
  const history = useHistory();
  const onClose = () => {
    history.push('/');
  };
  return (
    <DefaultContentCol>
      <RecoverPasswordSuccess onClose={onClose} />
    </DefaultContentCol>
  );
};

export default RecoverPasswordSuccessPage;
