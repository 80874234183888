import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// hooks
import { useTenantList } from '../../../../../Tenants/hooks/useTenantList';
import { useAddPrivateNotificationTemplateGroup } from '../../../../hooks/privateNotifications/useAddPrivateNotificationTemplateGroup';

// constants
import {
  NOTIFICATION_MODULE_MENU_LABEL,
  NOTIFICATION_TEMPLATE_FIELD_NAMES,
  NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS,
  NOTIFICATION_PREVIEW_TYPES,
  NOTIFICATION_FIELD_ERRORS,
} from '../../../../../../constraints';

// helpers
import { parseCreateTemplateData } from '../../../../helpers/parseCreateTemplateData';

// components
import { EnterTemplateNameForm, EnterTemplateContentForm } from './components';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const CreateTemplateForm = ({ initialValues, handleSuccess, handleError }) => {
  const { formatMessage } = useIntl();
  const { data: tenants, isLoading: areTenantsLoading } = useTenantList();
  const [step, setStep] = useState(1);
  const { ...restInfo } = initialValues || {};
  const [formData, setFormData] = useState({
    [NOTIFICATION_TEMPLATE_FIELD_NAMES.NAME]: '',
    [NOTIFICATION_TEMPLATE_FIELD_NAMES.NOTIFICATION_TYPE]: null,
    [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE]: '',
    [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE]: '',
    ...restInfo,
  });

  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const { mutate: createTemplate, isLoading: isSaving } =
    useAddPrivateNotificationTemplateGroup({
      onSuccess: (data) => {
        const { id } = data || {};
        handleSuccess(id);
      },
      onError: ({ response }) => {
        const { statusCode } = response?.data || {};
        const errorFormattedMessageId = NOTIFICATION_FIELD_ERRORS[statusCode];
        const errorFormattedMessage =
          !!errorFormattedMessageId &&
          formatMessage({
            id: errorFormattedMessageId,
            defaultMessage: errorFormattedMessageId,
          });
        handleError(errorFormattedMessage);
      },
    });

  const handleFormSubmit = (values) => {
    createTemplate({
      data: parseCreateTemplateData(values),
    });
  };

  const handleSubmitStep = (stepData) => {
    handleAppendFormData(stepData);
    setStep(step + 1);
  };

  const handleBack = (stepData) => {
    handleAppendFormData(stepData);
    setStep(step - 1);
  };

  if (areTenantsLoading) {
    return (
      <PageLoader
        message={
          <FormattedMessage
            id="Templates are loading..."
            defaultMessage="Templates are loading..."
          />
        }
      />
    );
  }

  return (
    <>
      {step === 1 && (
        <EnterTemplateNameForm
          defaultValues={formData}
          onSubmit={handleSubmitStep}
          submitButtonText="Next"
          isLoading={isSaving}
        />
      )}
      {step === 2 && (
        <EnterTemplateContentForm
          defaultValues={formData}
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          submitButtonText="Next"
          labelMessageId={
            NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_EMAIL_CONTENT_FIELD
          }
          placeholderMessageId={
            NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.CONTENT_EMAIL_TEMPLATE
          }
          name={NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE}
          isLoading={isSaving}
          tenants={tenants}
          previewType={NOTIFICATION_PREVIEW_TYPES.EMAIL}
        />
      )}
      {step === 3 && (
        <EnterTemplateContentForm
          defaultValues={formData}
          onSubmit={handleFormSubmit}
          onBack={handleBack}
          submitButtonText="Confirm2"
          labelMessageId={
            NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_SMS_CONTENT_FIELD
          }
          placeholderMessageId={
            NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.CONTENT_SMS_TEMPLATE
          }
          name={NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE}
          isLoading={isSaving}
          tenants={tenants}
          previewType={NOTIFICATION_PREVIEW_TYPES.SMS}
        />
      )}
    </>
  );
};

export default CreateTemplateForm;
