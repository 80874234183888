import { QueryKeys } from '../../../../queryKeys';
import { getRequest } from '../../../../helpers/apiClient';
import apiRoutes from '../../../../api-routes';
import { useQueryWithLogout } from '../../../../hooks/useQueryWithLogout';

export const usePrivateSingleNotificationTemplateGroup = (groupId, options) => {
  return useQueryWithLogout(
    [QueryKeys.PRIVATE_SINGLE_NOTIFICATION_TEMPLATE_GROUP, groupId],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.PRIVATE_SINGLE_NOTIFICATION_TEMPLATE_GROUP.replace(
          '{id}',
          groupId,
        ),
      });
      return data;
    },
    options,
  );
};
