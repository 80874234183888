import React, { useState, useMemo } from 'react';

// utils
import PropTypes from 'prop-types';
import { PASSWORD_FORM_MODES } from '../../../../constraints';

// hooks
import { useHistory } from 'react-router-dom';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { usePatchPassword } from '../../hooks/usePatchPassword';
import useSmsCodeRequest from '../../../../modules/Settings/hooks/useSmsCodeRequest';
import { useTokenParamContext } from '../../../Auth/hooks/useTokenParam';

// components
import TypeVerificationCode from '../TypeVerificationCode';
import SetPasswordSuccess from '../SetPasswordSuccess';
import PasswordForm from '../PasswordForm';
import SingleCustomCard from '../../../../components/SingleCustomCard';
import { SendSmsCodeFailureModal } from '../SendSmsCodeFailureModal';

const TwoStepPasswordSet = ({ titleId, onFinish }) => {
  const history = useHistory();
  const { token } = useTokenParamContext();
  const { usersConfig } = useConfigContext();
  const { enableTwoStepPassword } = usersConfig || {};

  const [step, setStep] = useState(1);
  const [passwords, setPasswords] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [sendSmsCodeFailure, setSmsCodeFailure] = useState(false);

  const handleOnBack = () => {
    setStep(step - 1);
  };
  const handleOnClose = () => {
    if (onFinish) {
      onFinish();
    } else {
      history.push('/');
    }
  };

  const handleOnSendSmsCodeSuccess = () => {
    setStep(step + 1);
  };
  const handleOnSendSmsCodeFailure = ({ response }) => {
    setSmsCodeFailure(true);
  };
  const { mutate: sendSmsCode, isLoading: isSendingSmsCode } =
    useSmsCodeRequest(
      {
        onSuccess: handleOnSendSmsCodeSuccess,
        onFailure: handleOnSendSmsCodeFailure,
      },
      token,
    );
  const handleSmsCodeFailureModalClose = () => {
    setSmsCodeFailure(false);
  };

  const handlePatchPasswordSuccess = () => {
    setStep(3);
  };
  const handlePatchPasswordFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };
  const { mutate: patchPassword, isLoading: isPatchingPassword } =
    usePatchPassword(
      {
        onSuccess: handlePatchPasswordSuccess,
        onError: handlePatchPasswordFailure,
      },
      token,
    );

  const handleOnValidatePasswordSuccess = (passwords) => {
    setPasswords(passwords);
    if (enableTwoStepPassword) {
      sendSmsCode();
    } else {
      patchPassword({
        passwordData: {
          oldPassword: passwords.oldPassword,
          password: passwords.password,
        },
      });
    }
  };

  const formConfig = {
    mode: PASSWORD_FORM_MODES.PASSWORD_SET,
    handleSuccess: handleOnValidatePasswordSuccess,
    passwordLabelId: 'Password',
    repeatPasswordLabelId: 'Repeat password',
    submitMessageId: 'Set password',
  };

  const isLoading = useMemo(
    () => isPatchingPassword || isSendingSmsCode,
    [isPatchingPassword, isSendingSmsCode],
  );

  return (
    <>
      {
        {
          1: (
            <SingleCustomCard titleId={titleId ? titleId : 'Set password'}>
              <PasswordForm
                formConfig={formConfig}
                deliveredReasons={reasons}
                deliveredErrorHandler={handlePatchPasswordFailure}
                onBack={handleOnClose}
                deliveredLoading={isLoading}
              />
              <SendSmsCodeFailureModal
                isOpen={sendSmsCodeFailure}
                toggle={handleSmsCodeFailureModalClose}
                onClose={handleSmsCodeFailureModalClose}
                closeIconEnabled={false}
              />
            </SingleCustomCard>
          ),
          2: (
            <TypeVerificationCode
              passwords={passwords}
              handleOnSubmitCodeSuccess={handleOnSendSmsCodeSuccess}
              handleOnBack={handleOnBack}
            />
          ),
          3: (
            <SetPasswordSuccess
              onClose={handleOnClose}
              messageId={'Password has been set'}
            />
          ),
        }[step]
      }
    </>
  );
};

TwoStepPasswordSet.propTypes = {
  titleId: PropTypes.string,
};

export default TwoStepPasswordSet;
