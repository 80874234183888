import React, { useState, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

// constants
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  RECIPIENT_STATUSES,
} from '../constraints';

// Hooks
import { useConfigContext } from '../modules/Config/hooks/useConfig';
import { useIdentityList } from '../modules/Recipients/hooks/useIdentityList';

// Helpers
import { IDENTITY_DOCUMENT_TYPE } from '../constraints';
import { removeUndefinedValues } from '../helpers/removeUndefinedValues';
import { blurSingleFormatter, boundLengthFormatter } from '../formatters';

// Components
import { ShowMoreLabelButton } from '../components/ShowMoreLabelButton/ShowMoreLabelButton';

export const useIdentitiesOptions = (
  valueName = RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER,
  enabled,
) => {
  const [identityDocumentNumber, setIdentityDocumentNumber] =
    useState(undefined);

  const [identityName, setIdentityName] = useState(undefined);

  const setSearchTextDebounced = useRef(
    debounce((callback) => callback(), 300),
  ).current;

  const { formatMessage } = useIntl();
  const [pageIncreamentation, setPageIncreamentation] = useState(1);

  const pageLimit = 20;

  const identityStatusList = [
    RECIPIENT_STATUSES.SHELL,
    RECIPIENT_STATUSES.PUBLISHED,
    RECIPIENT_STATUSES.PUBLISHING,
    RECIPIENT_STATUSES.ERROR,
    RECIPIENT_STATUSES.UPDATING,
  ];

  const { data: identitiesData, isFetching: isIdentitiesFetching } =
    useIdentityList(
      {
        filters: removeUndefinedValues({
          documentNumber: identityDocumentNumber,
          name: identityName,
          statusList: identityStatusList,
          isGdprSuspended: false,
          isActive: true,
        }),
        pagination: {
          limit: pageLimit,
          page: pageIncreamentation,
        },
      },
      {
        enabled: enabled,
        refetchOnMount: false,
      },
    );

  const { rows: identitiesRows, count } = identitiesData || {};

  const { recipientsConfig } = useConfigContext();
  const { isFullName, enableDocumentNumberBlur } = recipientsConfig || {};

  const identities = useMemo(
    () =>
      (identitiesRows || []).reduce((arr, identity) => {
        if (identity.isActive && !identity.isGdprSuspended) {
          arr.push({
            value: identity[valueName],
            internalId: identity.id,
            documentNumber: identity.documentNumber,
            valueName: valueName,
            label: (
              <>
                {blurSingleFormatter(
                  boundLengthFormatter(identity.documentNumber),
                  enableDocumentNumberBlur &&
                    identity?.documentType === IDENTITY_DOCUMENT_TYPE.PESEL,
                )}
                {isFullName
                  ? ` ${identity.name}`
                  : ` ${identity.firstName} ${identity.lastName}`}
              </>
            ),
          });
        }
        return arr;
      }, []),
    [identitiesRows, valueName, enableDocumentNumberBlur, isFullName],
  );

  const handlePreviousClick = (e) => {
    e.stopPropagation();
    if (pageIncreamentation > 1) {
      setPageIncreamentation(pageIncreamentation - 1);
    }
  };

  const handleShowMoreClick = (e) => {
    e.stopPropagation();
    setPageIncreamentation(pageIncreamentation + 1);
  };

  const previousOption = {
    value: -2,
    label: (
      <ShowMoreLabelButton
        color="link"
        type="button"
        onClick={handlePreviousClick}
      >
        {formatMessage({ id: 'show previous...' })}
      </ShowMoreLabelButton>
    ),
    disabled: true,
  };

  const showMoreOption = {
    value: -1,
    label: (
      <ShowMoreLabelButton
        color="link"
        type="button"
        onClick={handleShowMoreClick}
      >
        {formatMessage({ id: 'show more...' })}
      </ShowMoreLabelButton>
    ),
    disabled: true,
  };

  const identityOptions = useMemo(() => {
    const rowsCount = identities?.length;

    if (count === 0) {
      return identities;
    }

    if (pageIncreamentation > 1 && rowsCount > 0 && rowsCount === pageLimit) {
      return [previousOption, ...identities, showMoreOption];
    }

    if (
      (pageIncreamentation > 1 && rowsCount >= 0) ||
      (rowsCount === 0 && count > 0)
    ) {
      return [previousOption, ...identities];
    }

    if (count < pageLimit && rowsCount < pageLimit) {
      return identities;
    }

    return [...identities, showMoreOption];
  }, [identities, count, previousOption, showMoreOption, pageIncreamentation]);

  const onChangeIdentityInput = (inputText) => {
    if (
      valueName === RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER &&
      inputText !== identityDocumentNumber
    ) {
      setSearchTextDebounced(() => {
        setPageIncreamentation(1);
        setIdentityDocumentNumber(inputText);
      });
    }
    if (
      valueName === RECIPIENT_FILTER_FIELD_NAMES.NAME &&
      inputText !== identityName
    ) {
      setSearchTextDebounced(() => {
        setPageIncreamentation(1);
        setIdentityName(inputText);
      });
    }
  };
  return {
    identities: identityOptions,
    isIdentitiesFetching,
    onChangeIdentityInput,
  };
};
