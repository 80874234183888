import { QueryKeys } from '../../../queryKeys';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';
import { getRequest } from '../../../helpers/apiClient';

export const useSingleCategory = ({ id }, params, options) => {
  return useQueryWithLogout(
    [QueryKeys.SINGLE_CATEGORY, id],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.SINGLE_CATEGORY.replace('{id}', id),
        params,
      });
      return data;
    },
    options,
  );
};
