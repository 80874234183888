import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

// constants
import {
  NOTIFICATION_TEMPLATE_FIELD_NAMES,
  NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS,
  NOTIFICATION_MODULE_MENU_LABEL,
} from '../../../../../../../../constraints';

// hooks
import { usePrivateNameSchema } from '../../../../../../hooks/schemas/usePrivateNameSchema';
import { useNotificationTemplateTypeOptions } from '../../../../../../hooks/useNotificationTemplateTypeOptions';

// Components
import { FormFooter } from '../../../../../../../../components/FormFooter';
import { ControlledTextField } from '../../../../../../../../components/ControlledTextField';
import SelectNotificationTemplateType from '../SelectNotificationTemplateType';
import FieldTitle from '../FieldTitle';

import { Form as FormModule, Grid } from 'billon-ui';
const { FormGroup } = FormModule;
const { Row, Col } = Grid;

const EnterTemplateNameForm = ({
  defaultValues,
  onSubmit,
  submitButtonText,
  isLoading,
}) => {
  const { formatMessage } = useIntl();
  const { defaultNotificationTemplateTypeOption } =
    useNotificationTemplateTypeOptions();
  const schema = usePrivateNameSchema({
    name: NOTIFICATION_TEMPLATE_FIELD_NAMES.NAME,
  });
  const formMethods = useForm({
    defaultValues: {
      ...defaultValues,
      [NOTIFICATION_TEMPLATE_FIELD_NAMES.NOTIFICATION_TYPE]:
        defaultValues[NOTIFICATION_TEMPLATE_FIELD_NAMES.NOTIFICATION_TYPE] ??
        defaultNotificationTemplateTypeOption,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, watch } = formMethods;
  const nameWatch = watch(NOTIFICATION_TEMPLATE_FIELD_NAMES.NAME);
  const typeWatch = watch(NOTIFICATION_TEMPLATE_FIELD_NAMES.NOTIFICATION_TYPE);

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row
          xl={12}
          style={{ justifyContent: 'center' }}
          padding={'0 16px 0 16px'}
        >
          <Col xl={6}>
            <FieldTitle as="h2">
              <FormattedMessage
                id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_NAME_FIELD}
                defaultMessage={
                  NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_NAME_FIELD
                }
              />
            </FieldTitle>
            <FormGroup>
              <ControlledTextField
                name={NOTIFICATION_TEMPLATE_FIELD_NAMES.NAME}
                placeholder={formatMessage({
                  id: NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.NAME,
                  defaultMessage: NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.NAME,
                })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row
          xl={12}
          style={{ justifyContent: 'center' }}
          padding={'0 16px 0 16px'}
        >
          <Col xl={6}>
            <FieldTitle as="h2">
              <FormattedMessage
                id={
                  NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_NOTIFICATION_TYPE_FIELD
                }
                defaultMessage={
                  NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_NOTIFICATION_TYPE_FIELD
                }
              />
            </FieldTitle>
            <FormGroup>
              <SelectNotificationTemplateType />
            </FormGroup>
          </Col>
        </Row>
        <FormFooter
          isLoading={isLoading}
          submitButtonText={submitButtonText}
          backBtnDisabled
          disabled={!nameWatch || !typeWatch}
        />
      </form>
    </FormProvider>
  );
};

export default EnterTemplateNameForm;
