import React, { createContext, useContext, useMemo, useCallback } from 'react';
import useSettingsRequest from './useSettingsRequest';
import { useTokenParamContext } from '../../Auth/hooks/useTokenParam';
import {
  PageLoader,
  PageLoadingFailed,
} from '../../../components/PageLoader/PageLoader';
import { decodeJwtToken } from '../../Login/hooks';

const SettingsContext = createContext(false);

export const useSettingsContext = () => useContext(SettingsContext);

export const SettingsContextProvider = ({ children }) => {
  const { token } = useTokenParamContext();

  const {
    data: settings,
    isLoading: areSettingsLoading,
    error,
  } = useSettingsRequest({}, token);

  const { role } =
    useMemo(() => {
      if (token) {
        return decodeJwtToken(token);
      } else {
        return decodeJwtToken(sessionStorage.getItem('token'));
      }
    }, [token]) || {};

  const isTokenExpired = useMemo(() => {
    const tokenToDecode = token ?? sessionStorage.getItem('token');
    const { exp, refreshExpirationDate } = decodeJwtToken(tokenToDecode) || {};
    const dateNow = Date.now() * (1 / 1000);
    return dateNow > exp || dateNow > refreshExpirationDate;
  }, [token]);

  const getSettingValueByKey = useCallback(
    (givenKey) => settings?.find(({ key }) => key === givenKey)?.value,
    [settings],
  );

  const contextValue = useMemo(
    () => ({
      role,
      isTokenExpired,
      settings,
      isLoading: areSettingsLoading,
      getSettingValueByKey,
    }),
    [role, isTokenExpired, settings, getSettingValueByKey],
  );

  if (areSettingsLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <PageLoadingFailed />;
  }

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};
