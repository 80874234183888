import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Styled from './styled';

const SuspendedBadge = ({ children }) => (
  <Styled.SuspendedBadge>
    <FormattedMessage id="Suspended" defaultMessage="Suspended" />
    {children}
  </Styled.SuspendedBadge>
);

export default SuspendedBadge;
