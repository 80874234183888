import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getPasswordStructureComplexity } from '../../../../heplers/passwordStructureValidator';

import * as Styled from './styled';

const ValidationIndicator = ({
  name = 'password',
  minLength = 1,
  minComplexity = 1,
}) => {
  const { watch } = useFormContext();
  const passwordValue = watch(name);

  const structureComplexityCheck = useMemo(() => {
    const structureComplexity = getPasswordStructureComplexity(passwordValue);

    const lengthCheck = passwordValue?.length >= minLength;
    const checkComplexity_1 = structureComplexity >= 1;
    const checkComplexity_2 =
      minComplexity > 1
        ? structureComplexity >= minComplexity - 1
        : checkComplexity_1;
    const checkComplexity_3 = structureComplexity >= minComplexity;

    return {
      check_1: lengthCheck && checkComplexity_1,
      check_2: lengthCheck && checkComplexity_2,
      check_3: lengthCheck && checkComplexity_3,
    };
  }, [passwordValue, minLength, minComplexity]);

  return (
    <Styled.ValidationIndicatorRow>
      <Styled.ValidationIndicatorLine
        check={structureComplexityCheck?.check_1}
      />
      <Styled.ValidationIndicatorLine
        check={structureComplexityCheck?.check_2}
      />
      <Styled.ValidationIndicatorLine
        check={structureComplexityCheck?.check_3}
      />
    </Styled.ValidationIndicatorRow>
  );
};

export default ValidationIndicator;
