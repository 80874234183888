import React from 'react';
import { useIntl } from 'react-intl';

// constants
import {
  CUSTOMER,
  SIGNING_ORDER,
  MAP_SIGNING_ORDER,
  MAP_SIGNING_TYPE,
  SIGNING_TYPE,
  MAP_ACTIVE_DELIVERY,
  DOCUMENT_TYPE,
  MAP_PUBLIC_DOCUMENT_TYPES,
} from '../../../../../../constraints';

// formatters
import {
  withGmtFormatter,
  momentFormatter,
  momentInTimezoneFormatter,
  momentFormatterWithoutTime,
} from '../../../../../../formatters';
import { formatIdentity } from '../../../../formatters';
import { documentStatusFormatter } from '../../../../formatters/documentStatusFormatter';
import { userInfoFormatter } from '../../../../../../formatters';
import { capitalizeFirstLetter } from '../../../../../../helpers/capitalizeFirstLetter';

// hooks
import useFeatureEnabled from '../../../../../Config/hooks/useFeatureEnabled';
import { useTenantsContext } from '../../../../../Tenants/hooks/useTenants';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// Components
import DataDetailsSection from '../DataDetailsSection/DataDetailsSection';

const DetailsSection = ({ document }) => {
  const { getTenantNameByValue, tenantHeaderMessageId } = useTenantsContext();
  const { customer, enableSigning, isOpenPlatform } = useConfigContext();
  const { featureEnabled } = useFeatureEnabled();
  const { formatMessage } = useIntl();

  const enabledDetailsCol1 = [
    {
      title: 'Publication start date',
      data: momentFormatter(document.publicationStartDate),
      enabled: featureEnabled('enableDocumentDetails.publicationStartDate', [
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Publication date',
      data: withGmtFormatter(
        document.publicationDate,
        momentFormatter(document.publicationDate),
        isOpenPlatform,
      ),
      enabled: featureEnabled('enableDocumentDetails.publicationDate', [
        CUSTOMER.OPEN_PLATFORM,
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    document?.publicDocumentType && {
      title: 'Document type',
      data: MAP_PUBLIC_DOCUMENT_TYPES[document?.publicDocumentType]
        ? formatMessage({
            id: MAP_PUBLIC_DOCUMENT_TYPES[document?.publicDocumentType],
          })
        : '---',
      enabled: true,
    },
    document?.category && {
      title: 'Category',
      data: document.category.fullPath,
      enabled: featureEnabled('enableDocumentDetails.categoryFullPath', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Status',
      data: documentStatusFormatter(
        enableSigning,
        true,
        true,
      )(
        enableSigning &&
          [SIGNING_ORDER.SENDER_FIRST, SIGNING_ORDER.RECEIVER_ONLY].includes(
            document?.signingOrder,
          )
          ? document?.signingStatus
          : document?.status,
        document,
      ),

      enabled: featureEnabled('enableDocumentDetails.status', [
        CUSTOMER.OPEN_PLATFORM,
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: formatMessage({
        id: tenantHeaderMessageId,
      }),
      data: getTenantNameByValue(document?.tenant),
      enabled: featureEnabled('enableDocumentDetails.publisherName', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Published by',
      data: document?.publishedBy
        ? userInfoFormatter('', document.publishedBy)
        : '',
      enabled: featureEnabled('enableDocumentDetails.publishedBy', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    document?.identity && {
      title: 'Recipient',
      data: formatIdentity(document.identity, customer),
      enabled: featureEnabled(null, [
        CUSTOMER.OPEN_PLATFORM,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
      ]),
    },
  ];

  const enabledDetailsCol2 = [
    document?.activeDelivery &&
      ![CUSTOMER.TAURON, CUSTOMER.DIPLOMA].includes(customer) &&
      document?.documentType === DOCUMENT_TYPE.PRIVATE && {
        title: 'e-Delivery',
        data: capitalizeFirstLetter(
          formatMessage({
            id: MAP_ACTIVE_DELIVERY[document.activeDelivery?.type],
          }),
        ),
        enabled: true,
      },
    document?.versionName && {
      title: 'Document version',
      data: document.versionName,
      enabled: featureEnabled('enableDocumentDetails.versionName', [
        CUSTOMER.OPEN_PLATFORM,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Retain until',
      data: momentFormatterWithoutTime(document.retainUntil),
      enabled: featureEnabled('enableDocumentDetails.retainUntil', [
        CUSTOMER.OPEN_PLATFORM,
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title:
        customer === CUSTOMER.DIPLOMA
          ? 'Date of issue of the diploma'
          : 'Is valid from',
      data: [CUSTOMER.TAURON, CUSTOMER.DIPLOMA].includes(customer)
        ? momentFormatterWithoutTime(document.validSince)
        : withGmtFormatter(
            document.validSince,
            momentInTimezoneFormatter(document.validSince),
            isOpenPlatform,
          ),
      enabled: featureEnabled('enableDocumentDetails.validSince', [
        CUSTOMER.OPEN_PLATFORM,
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    document?.validUntil && {
      title: 'Valid until',
      data:
        customer === CUSTOMER.TAURON
          ? momentFormatterWithoutTime(document.validUntil)
          : withGmtFormatter(
              document.validUntil,
              momentInTimezoneFormatter(document.validUntil),
              isOpenPlatform,
            ),
      enabled: featureEnabled('enableDocumentDetails.validUntil', [
        CUSTOMER.OPEN_PLATFORM,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    document?.identity && {
      title: 'Student',
      data: formatIdentity(document.identity),
      enabled: featureEnabled('enableDocumentDetails.identity', [
        CUSTOMER.DIPLOMA,
      ]),
    },
    document?.identity && {
      title: 'Index number',
      data: document.identity.documentNumber,
      enabled: featureEnabled('enableDocumentDetails.documentNumber', [
        CUSTOMER.DIPLOMA,
      ]),
    },
    document?.signingOrder && {
      title: 'Publication Type',
      data: capitalizeFirstLetter(
        formatMessage({
          id: MAP_SIGNING_ORDER[document.signingOrder],
        }),
      ),
      enabled: featureEnabled('enableSigning', [CUSTOMER.DEFAULT]),
    },
    document?.signingOrder &&
      [SIGNING_ORDER.RECEIVER_ONLY, SIGNING_ORDER.SENDER_FIRST].includes(
        document.signingOrder,
      ) && {
        title: 'Signing type',
        data: formatMessage({
          id: MAP_SIGNING_TYPE[SIGNING_TYPE.STANDARD],
        }),
        enabled: featureEnabled('enableSigning', [
          CUSTOMER.OPEN_PLATFORM,
          CUSTOMER.DEFAULT,
          CUSTOMER.TAURON,
          CUSTOMER.TAURON_AGREEMENT,
        ]),
      },
  ];

  return (
    <DataDetailsSection
      headerMessageId={'Document details'}
      columns={[enabledDetailsCol1, enabledDetailsCol2]}
    />
  );
};

export default DetailsSection;
