import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

// Components
import { SuccessModal as CustomSuccessModal } from '../../../../components/SuccessModal';
import SingleCustomCard from '../../../../components/SingleCustomCard';
import RegisterForm from '../../components/RegisterForm';
import RegisterPassword from '../../components/RegisterPassword';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const RegisterPage = () => {
  const history = useHistory();

  const isLoading = false;

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [profileInfo, setProfileInfo] = useState();
  const [passwords, setPasswords] = useState();
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState();

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  const handleSuccess = () => {
    setSuccessModalOpen(true);
  };

  const handleFailure = () => {};

  const handleExit = () => {
    history.push('/');
  };

  const handleOnCloseSuccess = () => {
    setSuccessModalOpen(false);
    handleExit();
  };

  const handleProfileInfoSave = (data) => {
    setProfileInfo(data);
    setStep(2);
  };

  const handlePasswordsSave = (data, stepErrors) => {
    setPasswords(data);
    setErrors(stepErrors);
    setStep(1);
  };

  return (
    <>
      <SingleCustomCard titleId={'Sign up'} disableBodyPaddingTop>
        {step === 1 && (
          <RegisterForm
            defaultValues={profileInfo}
            onSuccess={handleProfileInfoSave}
            onBack={handleExit}
            errors={errors}
          />
        )}
        {step === 2 && (
          <RegisterPassword
            defaultValues={passwords}
            profileInfo={profileInfo}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            onBack={handlePasswordsSave}
            deliveredErrors={errors}
          />
        )}
      </SingleCustomCard>
      <CustomSuccessModal
        isOpen={successModalOpen}
        toggle={handleOnCloseSuccess}
        onClose={handleOnCloseSuccess}
        closeIconEnabled={false}
      >
        <FormattedMessage
          id={
            'The account activation link has been sent to the given email address'
          }
          defaultMessage={
            'The account activation link has been sent to the given email address'
          }
        />
      </CustomSuccessModal>
    </>
  );
};

export default RegisterPage;
