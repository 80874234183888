import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { closeWebViewer } from '../../webviewer';

import * as Styled from './styled';

export const WebViewer = () => {
  const history = useHistory();

  const handleCloseWebViewer = () => {
    closeWebViewer();
  };

  const handleCloseViewerButton = () => {
    history.goBack();
    closeWebViewer();
  };

  useEffect(() => {
    window.addEventListener('popstate', handleCloseWebViewer);
    return () => window.removeEventListener('popstate', handleCloseWebViewer);
  });

  return (
    <>
      <Styled.WebViewer className="webviewer" />
      <Styled.CloseViewerButton
        className="webviewer-button-close"
        onClick={handleCloseViewerButton}
      >
        <FormattedMessage id="Close preview" defaultMessage="Close preview" />
      </Styled.CloseViewerButton>
    </>
  );
};
