import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const DefaultPanelTypeBadge = styled('div')`
  margin-left: 16px;
  background-color: '#fff';
  font-size: 12px;
  color: black;
  font-weight: ${({ theme }) => theme.fontWeightSemiBold};
  border-radius: 24px;
  padding: 6px 12px;
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.palette.primary} 0%,
    ${({ theme }) => theme.palette.primary} 100%
  );
  background-origin: border-box;
  box-shadow: inset 0 100vw white;
  border: 3px solid transparent;
`;

export const BillonPanelTypeBadge = styled(DefaultPanelTypeBadge)`
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.palette.billonGreen} 0%,
    ${({ theme }) => theme.palette.billonBlue} 100%
  );
`;

export const OpenPlatformPanelTypeBadge = styled('div')`
  margin-left: 16px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeightSemiBold};
  border: 3px solid;
  border-color: ${({ theme }) => theme.palette.billonGreen};
  border-radius: 24px;
  padding: 6px 12px;
  min-width: 150px;
  text-align: center;
  background-color: ${(props) =>
    props?.active ? props.theme.palette.billonGreen : 'none'};
  color: ${(props) => (props?.active ? 'white' : 'black')};
  &:hover {
    background-color: ${(props) => props.theme.palette.billonGreen};
    color: white !important;
  }
  cursor: pointer;

  @media (max-width: 1199px) {
    margin-bottom: ${(props) => (props?.enableIfMobile ? '0.5rem' : 0)};
    margin-right: ${(props) => (props?.enableIfMobile ? '16px' : 0)};
  }
`;

export const WidthSensitiveWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  @media (max-width: 1199px) {
    margin-top: ${(props) => (props?.enableIfMobile ? '1.5rem' : '0')};
    display: ${(props) => (props?.enableIfMobile ? 'flex' : 'none')};
    flex-direction: ${(props) => (props?.enableIfMobile ? 'column' : 'row')};
  }
  @media (min-width: 1200px) {
    display: ${(props) => (props?.enableIfMobile ? 'none' : 'flex')};
    flex-direction: row;
  }
`;
