import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 0rem 3rem 5rem 3rem;
  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
