import styled from 'styled-components';

import { Form as FormModule, Utils } from 'billon-ui';

const { Text } = Utils;

export const Form = styled(FormModule.Form)`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    width: calc(100% - 35px);
  }
`;

export const FormGroup = styled(FormModule.FormGroup)`
  position: relative;
`;

export const LabelFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EmailSubject = styled(Text)`
  margin-bottom: 8px;
`;

export const EmailSubjectTag = styled.span`
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  font-size: ${(props) => props.theme.fontSizeSm};
`;
