import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Card: BillonCard } = Utils;

export const Card = styled(BillonCard)`
  margin: 0;
  @media (max-width: 600px) {
    padding: 1rem;
    box-shadow: none;
    background-color: transparent !important;
    margin-bottom: 5rem;
  }
`;

export const Title = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const CardBodyWrapper = styled.div`
  padding-top: ${(props) => (props?.disableBodyPaddingTop ? 0 : '35px')};
`;
