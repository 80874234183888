import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuthenticatedContext } from '../../../../../Auth/hooks/useAuthenticated';
import { useSingleCategory } from '../../../../../Categories/hooks/useSingleCategory';

import { Layout as LayoutModule, Utils } from 'billon-ui';
import billonEmptyList from '../../../../../../ui/Assets/billon-empty-document-list.png';
import * as Styled from './styled';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const EmptyDocumentList = () => {
  const { user } = useAuthenticatedContext();
  const { data: category, isLoading } = useSingleCategory(
    { id: user?.categoryId },
    { accountingNumberOfDocuments: true },
    { enabled: !!user?.categoryId },
  );
  const { noOfDocs } = category || {};

  if (isLoading) {
    return <PageLoader />;
  }

  if (noOfDocs === 0) {
    return (
      <Content fluid>
        <Styled.Centered>
          <img
            src={billonEmptyList}
            height={360}
            alt="billonEmptyDocumentList"
          />
        </Styled.Centered>
        <Styled.Centered>
          <Styled.EmptySubtitle>
            <FormattedMessage
              id="Ready to securely store Your first documents"
              defaultMessage="Ready to securely store Your first documents"
            />
          </Styled.EmptySubtitle>
        </Styled.Centered>
      </Content>
    );
  }

  return null;
};

export default EmptyDocumentList;
