import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { union } from 'lodash';

// constants
import {
  VALIDATION_ERRORS,
  VALIDATION_ERROR_TYPES,
  MAP_VALIDATION_ERROR_TYPES,
  PASSWORD_FORM_MODES,
  PASSWORD_FORM_FIELD_NAMES,
  UserRoles,
  USER_FILTER_FIELD_NAMES,
  MAP_USER_FIELD_NAMES,
  MAP_USER_PLACEHOLDERS,
  MAP_VALIDATION_USER_ERRORS,
} from '../../../../constraints';

// hooks
import { useRegisterSchema } from './useRegisterSchema';
import { useValidationConstraints } from './hooks/useValidationConstraints';
import { useCreateUser } from '../../hooks/useCreateUser';

// helpers
import { parseRegisterData } from '../../heplers';

// components
import {
  ValidationIndicator,
  PasswordInfo,
  ControlledPasswordField,
  FormNavigator,
} from './components';
import * as Styled from './styled';
import { ControlledTextField } from '../../../../components/ControlledTextField';

const RegisterPasswordForm = ({
  deliveredRole,
  formConfig,
  deliveredReasons,
  profileInfo,
  deliveredErrorHandler,
  onBack,
  defaultValues,
  onSuccess,
  deliveredErrors,
}) => {
  const {
    mode,
    submitMessageId,
    oldPasswordId = 'Old password',
    passwordLabelId,
    repeatPasswordLabelId,
  } = formConfig || {};

  const history = useHistory();
  const { validationConstraints } = useValidationConstraints({
    mode,
    keyPrefix: 'USER',
    deliveredRole,
  });

  const schema = useRegisterSchema({
    validationConstraints,
  });
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, formState, getValues, setError } = formMethods;
  const { isSubmitting } = formState || {};

  const [reasons, setReasons] = useState(deliveredReasons);

  const handleBack = () => {
    onBack(getValues(), deliveredErrors);
  };
  const handleError = ({ response }) => {
    const errorInfo = response?.data?.errorInfo;
    if (
      errorInfo['EMAIL'] === 'NON_UNIQUE' ||
      errorInfo['PHONE_NUMBER'] === 'NON_UNIQUE' ||
      errorInfo['PHONE_NUMBER'] === 'INVALID'
    ) {
      onBack(getValues(), errorInfo);
    }
    if (errorInfo['USERNAME'] === 'NON_UNIQUE') {
      setError('username', {
        type: 'custom',
        message: MAP_VALIDATION_USER_ERRORS.NON_UNIQUE_USERNAME,
      });
    }
    setReasons(response?.data?.reasons || []);
  };

  const { mutate: createUser, isLoading: isCreating } = useCreateUser({
    onSuccess,
    onError: handleError,
  });

  const isSubmitButtonLoading = useMemo(
    () => isSubmitting || isCreating,
    [isSubmitting, isCreating],
  );

  const onSubmit = (values) => {
    createUser({ data: parseRegisterData({ ...profileInfo, ...values }) });
  };

  useEffect(() => {
    if (deliveredErrors?.USERNAME === 'NON_UNIQUE') {
      setError('username', {
        type: 'custom',
        message: MAP_VALIDATION_USER_ERRORS.NON_UNIQUE_USERNAME,
      });
    }
  }, [deliveredErrors]);

  useEffect(() => {
    setReasons(union(reasons, deliveredReasons));
    if (deliveredReasons?.includes(VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS)) {
      if (mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE) {
        setError(PASSWORD_FORM_FIELD_NAMES.OLD_PASSWORD, {
          type: 'custom',
          message: MAP_VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS,
        });
      } else {
        setError(PASSWORD_FORM_FIELD_NAMES.PASSWORD, {
          type: 'custom',
          message: VALIDATION_ERRORS.EMPTY,
        });
        setError(PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD, {
          type: 'custom',
          message: MAP_VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS,
        });
      }
    }
    if (deliveredReasons?.includes(VALIDATION_ERROR_TYPES.IDENTICAL)) {
      setError(PASSWORD_FORM_FIELD_NAMES.PASSWORD, {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError(PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD, {
        type: 'custom',
        message: MAP_VALIDATION_ERROR_TYPES.IDENTICAL,
      });
    }
    if (deliveredReasons?.includes(VALIDATION_ERROR_TYPES.CHANGED_TOO_OFTEN)) {
      setError(PASSWORD_FORM_FIELD_NAMES.PASSWORD, {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError(PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD, {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
    }
  }, [deliveredReasons]);

  return (
    <FormProvider {...formMethods}>
      <Styled.Form>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            name={USER_FILTER_FIELD_NAMES.USERNAME}
            label={
              <FormattedMessage
                id={MAP_USER_FIELD_NAMES.USERNAME}
                defaultMessage={MAP_USER_FIELD_NAMES.USERNAME}
              />
            }
            placeholder={MAP_USER_PLACEHOLDERS.USERNAME}
          />
          {mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE && (
            <ControlledPasswordField
              name={PASSWORD_FORM_FIELD_NAMES.OLD_PASSWORD}
              labelMessageId={oldPasswordId}
            />
          )}
          <ControlledPasswordField
            name={PASSWORD_FORM_FIELD_NAMES.PASSWORD}
            labelMessageId={passwordLabelId}
          />
          <ValidationIndicator
            name={PASSWORD_FORM_FIELD_NAMES.PASSWORD}
            minLength={validationConstraints?.minLength}
            minComplexity={validationConstraints?.complexity}
          />
          <ControlledPasswordField
            name={PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD}
            labelMessageId={repeatPasswordLabelId}
          />
          <PasswordInfo reasons={reasons} role={UserRoles.VISITOR} />
          <FormNavigator
            isSubmitButtonLoading={isSubmitButtonLoading}
            handleBack={handleBack}
            submitMessageId={submitMessageId}
          />
        </form>
      </Styled.Form>
    </FormProvider>
  );
};

export default RegisterPasswordForm;
