import React from 'react';
import { FormattedMessage } from 'react-intl';

import { MAP_NOTIFICATION_TEMPLATE_TYPE_OPTIONS } from '../../../../../../../../constraints';

import { Utils } from 'billon-ui';
const { Text } = Utils;

const NotificationTypeLabel = ({ notificationType }) => {
  const messageId = MAP_NOTIFICATION_TEMPLATE_TYPE_OPTIONS[notificationType];

  return (
    <div>
      <Text as="h6" fontSize="12px" fontWeight="600" margin={0}>
        <FormattedMessage
          id="Notification Type"
          defaultMessage="Notification Type"
        />
        {': '}
        {!!messageId && (
          <FormattedMessage id={messageId} defaultMessage={messageId} />
        )}
      </Text>
    </div>
  );
};

export default NotificationTypeLabel;
