import apiRoutes from '../../../api-routes';
import { patchRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const useActivateUser = (options, token) => {
  return useMutationWithLogout(async ({ id }) => {
    const { data: activateData } = await patchRequest({
      url: apiRoutes.ACTIVATE_USER.replace('{id}', id),
      headers: {
        Authorization: token ?? sessionStorage.getItem('token'),
      },
    });
    return activateData;
  }, options);
};
