import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// hooks
import { useAuthenticatedContext } from '../../modules/Auth/hooks/useAuthenticated';
import { useConfigContext } from '../../modules/Config/hooks/useConfig';

// constants
import appRoutes from '../../app-routes';
import { CUSTOMER, UserRoles, USER_STATUSES } from '../../constraints';

// components
import { Layout } from '../index';
import { UnauthorizedLayout } from '../UnauthorizedLayout/UnauthorizedLayout';
import { UnauthorizedRoutes } from '../UnauthorizedRoutes';
import { PasswordlessRoutes } from '../PasswordlessRoutes';
import { ProtectedComponent } from '../../modules/Auth/components';
import { ConfigBasedRoute } from '../ConfigBasedRoute/ConfigBasedRoute';

// screens & pages
import { screens as CategoryScreens } from '../../modules/Categories';
import { screens as HomePageScreens } from '../../modules/HomePage';
import { screens as RecipientsScreens } from '../../modules/Recipients';
import { screens as GDPRScreens } from '../../modules/GDPR';
import { screens as DailyReportsScreens } from '../../modules/DailyReports';
import { screens as DocumentScreens } from '../../modules/Documents';
import { screens as UserScreens } from '../../modules/Users';
import { screens as ReportScreens } from '../../modules/Reports';
import { screens as NotificationScreens } from '../../modules/Notifications';

const { HelpPage } = HomePageScreens;
const { HomePage: CategoryHomePage } = CategoryScreens;
const { HomePage: DailyReportsHomePage, DailyReportDetailsPage } =
  DailyReportsScreens;
const { HomePage: GDPRHomePage, RecipientPage: GDPRRecipientPage } =
  GDPRScreens;
const {
  HomePage: ReportHomePage,
  AnalyticsPage: ReportAnalyticsPage,
  StatisticsPage: ReportStatisticsPage,
  DeliveryReportsPage: ReportDeliveryPage,
} = ReportScreens;
const {
  ResendNotificationPage,
  NotificationHistoryPage,
  DocumentDetailsPage,
  DocumentUpdatePage,
  HomePage: DocumentHomePage,
  RootRedirectPage,
  SelectDocumentTypePage,
  PublishDocumentPage,
  PublicationPreviewPage,
  DocumentPreviewPage,
} = DocumentScreens;
const {
  HomePage: UsersHomePage,
  UserDetailsPage,
  MePage,
  ChangePasswordPage,
  SetTemporaryPasswordPage,
  AdministrativePasswordChangePage,
} = UserScreens;
const {
  HomePage: RecipientsHomePage,
  RecipientDetailsPage,
  RecipientManagerPage,
  RecipientCreateEditPage,
} = RecipientsScreens;
const {
  HomePage: NotificationsHomePage,
  CreateTemplatePage: NotificationsCreateTemplatePage,
  ManageNotificationPage: NotificationsManageNotificationPage,
  CreateContentPage: NotificationsCreateContentPage,
  NotificationTemplatesPage: NotificationsTemplatesPage,
  NotificationCategoriesPage: NotificationsCategoriesPage,
  NotificationArchivePage: NotificationsArchivePage,
  ArchiveTemplatePage: NotificationsArchiveTemplatePage,
} = NotificationScreens;

export const Routes = () => {
  const { customer } = useSelector(({ config }) => ({
    customer: config.customer,
    remoteSign: config.remoteSign,
    observer: config.observer,
  }));

  const { isAuthenticated, status, role } = useAuthenticatedContext();
  const { enableReports, enableNotificationManager } = useConfigContext();

  if (!isAuthenticated || !status) {
    return (
      <UnauthorizedLayout>
        <UnauthorizedRoutes />
      </UnauthorizedLayout>
    );
  }

  if (
    [USER_STATUSES.PENDING, USER_STATUSES.PASSWORD_EXPIRED].includes(status)
  ) {
    return (
      <UnauthorizedLayout>
        <PasswordlessRoutes />
      </UnauthorizedLayout>
    );
  }

  return (
    <Layout>
      {
        {
          [CUSTOMER.TAURON]: (
            <>
              <Switch>
                <Route exact path="/" component={RootRedirectPage} />
                <Route path={appRoutes.SUPPORT} component={HelpPage} />
                <Route
                  path={appRoutes.DOCUMENTS}
                  component={DocumentHomePage}
                />
                <Route
                  path={appRoutes.DOCUMENT_PREVIEW}
                  component={DocumentPreviewPage}
                />
                <Route
                  path={appRoutes.PUBLISH_DOCUMENT}
                  component={PublishDocumentPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS}
                  component={DocumentDetailsPage}
                />
                <Route
                  path={appRoutes.RECIPTIENT_DETAILS}
                  component={RecipientDetailsPage}
                />
                <Route path={appRoutes.ME} component={MePage} />
                <Route
                  path={appRoutes.PASSWORD_CHANGE}
                  component={ChangePasswordPage}
                />
                <Route
                  path={appRoutes.CATEGORIES}
                  component={CategoryHomePage}
                />
                <Route
                  path={appRoutes.ADMIN_PASSWORD_CHANGE}
                  component={AdministrativePasswordChangePage}
                />
                <Route path={appRoutes.USERS} component={UsersHomePage} />
                <Route
                  path={appRoutes.USER_DETAILS}
                  component={UserDetailsPage}
                />
                <Route
                  path={appRoutes.GDPR_RECIPIENT}
                  component={GDPRRecipientPage}
                />
                <Route
                  path={appRoutes.RECIPTIENTS}
                  component={RecipientsHomePage}
                />
                <Route
                  path={appRoutes.DAILY_REPORTS_BLANK}
                  component={DailyReportsHomePage}
                />
                <Route
                  path={appRoutes.DAILY_REPORT_DETAILS}
                  component={DailyReportDetailsPage}
                />
                <ConfigBasedRoute
                  exact
                  configParameter={
                    enableNotificationManager &&
                    [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                  }
                  path={appRoutes.NOTIFICATIONS}
                  component={NotificationsHomePage}
                />
                <ConfigBasedRoute
                  configParameter={
                    enableNotificationManager &&
                    [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                  }
                  path={appRoutes.NOTIFICATION_TEMPLATES}
                  component={NotificationsTemplatesPage}
                />
                <ConfigBasedRoute
                  configParameter={
                    enableNotificationManager &&
                    [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                  }
                  path={appRoutes.NOTIFICATION_ARCHIVE}
                  component={NotificationsArchivePage}
                />
                <ConfigBasedRoute
                  configParameter={
                    enableNotificationManager &&
                    [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                  }
                  path={appRoutes.NOTIFICATION_CATEGORIES}
                  component={NotificationsCategoriesPage}
                />
                <ProtectedComponent roles={[UserRoles.DPI]} render={false}>
                  <Route path={appRoutes.GDPR} component={GDPRHomePage} />
                </ProtectedComponent>
              </Switch>
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY}
                component={NotificationHistoryPage}
              />
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_RESEND_NOTIFICATION}
                component={ResendNotificationPage}
              />
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_UPDATE}
                component={DocumentUpdatePage}
              />
              <Route
                path={appRoutes.TEMPORATY_PASSWORD_SET}
                component={SetTemporaryPasswordPage}
              />
              <Route
                path={appRoutes.DOCUMENT_PUBLICATION_PREVIEW}
                component={PublicationPreviewPage}
              />
              <Route
                path={appRoutes.RECIPIENT_MANAGER}
                component={RecipientManagerPage}
              />
              <Route
                path={appRoutes.RECIPIENT_CREATOR}
                component={RecipientCreateEditPage}
              />
              <Route
                path={appRoutes.RECIPIENT_EDITOR}
                component={RecipientCreateEditPage}
              />
              <ConfigBasedRoute
                configParameter={
                  enableNotificationManager &&
                  [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                }
                path={appRoutes.NOTIFICATION_TEMPLATE_CREATOR}
                component={NotificationsCreateTemplatePage}
              />
              <ConfigBasedRoute
                configParameter={
                  enableNotificationManager &&
                  [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                }
                path={appRoutes.NOTIFICATION_ARCHIVE_MANAGE}
                component={NotificationsManageNotificationPage}
              />
              <ConfigBasedRoute
                configParameter={
                  enableNotificationManager &&
                  [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                }
                path={appRoutes.NOTIFICATION_TEMPLATE_MANAGE}
                component={NotificationsManageNotificationPage}
              />
              <ConfigBasedRoute
                configParameter={
                  enableNotificationManager &&
                  [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                }
                path={appRoutes.NOTIFICATION_TEMPLATE_MANAGE_NEW}
                component={NotificationsCreateContentPage}
              />
              <ConfigBasedRoute
                configParameter={
                  enableNotificationManager &&
                  [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                }
                path={appRoutes.NOTIFICATION_TEMPLATE_MANAGE_EDIT}
                component={NotificationsCreateContentPage}
              />
              <ConfigBasedRoute
                configParameter={
                  enableNotificationManager &&
                  [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                }
                path={appRoutes.NOTIFICATION_TEMPLATE_MANAGE_RESTORE}
                component={NotificationsCreateContentPage}
              />
              <ConfigBasedRoute
                configParameter={
                  enableNotificationManager &&
                  [UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN].includes(role)
                }
                path={appRoutes.NOTIFICATION_TEMPLATE_MANAGE_ARCHIVE}
                component={NotificationsArchiveTemplatePage}
              />
            </>
          ),
          [CUSTOMER.DIPLOMA]: (
            <>
              <Switch>
                <Route exact path="/" component={RootRedirectPage} />
                <Route
                  path={appRoutes.PASSWORD_CHANGE_BLANK}
                  component={ChangePasswordPage}
                />
                <Route
                  path={appRoutes.PUBLISH_DOCUMENT}
                  component={PublishDocumentPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS}
                  component={DocumentHomePage}
                />
                <Route
                  path={appRoutes.DOCUMENT_PREVIEW}
                  component={DocumentPreviewPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS}
                  component={DocumentDetailsPage}
                />
                <Route
                  path={appRoutes.RECIPTIENT_DETAILS}
                  component={RecipientDetailsPage}
                />
                <Route
                  path={appRoutes.CATEGORIES}
                  component={CategoryHomePage}
                />
                <Route
                  path={appRoutes.RECIPTIENTS}
                  component={RecipientsHomePage}
                />
                <Route path={appRoutes.ME} component={MePage} />
                <Route path={appRoutes.SUPPORT} component={HelpPage} />
                <Route
                  path={appRoutes.USER_DETAILS}
                  component={() => (
                    <ProtectedComponent
                      roles={[UserRoles.ADMIN]}
                      render={false}
                    >
                      <UserDetailsPage />
                    </ProtectedComponent>
                  )}
                />
                <Route
                  path={appRoutes.GDPR_RECIPIENT}
                  component={GDPRRecipientPage}
                />
                <Route path={appRoutes.USERS} component={UsersHomePage} />
                <ProtectedComponent roles={[UserRoles.DPI]} render={false}>
                  <Route path={appRoutes.GDPR} component={GDPRHomePage} />
                </ProtectedComponent>
              </Switch>
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY}
                component={NotificationHistoryPage}
              />
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_RESEND_NOTIFICATION}
                component={ResendNotificationPage}
              />
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_UPDATE}
                component={DocumentUpdatePage}
              />
              <Route
                path={appRoutes.DOCUMENT_PUBLICATION_PREVIEW}
                component={PublicationPreviewPage}
              />
              <Route
                path={appRoutes.RECIPIENT_MANAGER}
                component={RecipientManagerPage}
              />
              <Route
                path={appRoutes.RECIPIENT_CREATOR}
                component={RecipientCreateEditPage}
              />
              <Route
                path={appRoutes.RECIPIENT_EDITOR}
                component={RecipientCreateEditPage}
              />
            </>
          ),
          [CUSTOMER.DEFAULT]: (
            <>
              <Switch>
                <Route exact path="/" component={RootRedirectPage} />
                <Route
                  path={appRoutes.SELECT_DOCUMENT_TYPE}
                  component={SelectDocumentTypePage}
                />
                <Route path={appRoutes.SUPPORT} component={HelpPage} />
                <Route
                  path={appRoutes.DOCUMENTS}
                  component={DocumentHomePage}
                />
                <Route
                  path={appRoutes.DOCUMENT_PREVIEW}
                  component={DocumentPreviewPage}
                />
                <ConfigBasedRoute
                  configParameter={enableReports}
                  path={appRoutes.REPORTS}
                  component={ReportHomePage}
                />
                <ConfigBasedRoute
                  configParameter={enableReports}
                  path={appRoutes.REPORT_STATISTICS}
                  component={ReportStatisticsPage}
                />
                <ConfigBasedRoute
                  configParameter={enableReports}
                  path={appRoutes.REPORT_ANALYTICS}
                  component={ReportAnalyticsPage}
                />
                <ConfigBasedRoute
                  configParameter={enableReports}
                  path={appRoutes.REPORT_DELIVERY}
                  component={ReportDeliveryPage}
                />
                <Route
                  path={appRoutes.PUBLISH_DOCUMENT}
                  component={PublishDocumentPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS}
                  component={DocumentDetailsPage}
                />
                <Route
                  path={appRoutes.RECIPTIENT_DETAILS}
                  component={RecipientDetailsPage}
                />
                <Route
                  path={appRoutes.RECIPTIENTS}
                  component={RecipientsHomePage}
                />
                <Route path={appRoutes.ME} component={MePage} />
                <Route
                  path={appRoutes.PASSWORD_CHANGE}
                  component={ChangePasswordPage}
                />
                <Route
                  path={appRoutes.CATEGORIES}
                  component={CategoryHomePage}
                />
                <Route
                  path={appRoutes.USER_DETAILS}
                  component={() => (
                    <ProtectedComponent
                      roles={[UserRoles.ADMIN]}
                      render={false}
                    >
                      <UserDetailsPage />
                    </ProtectedComponent>
                  )}
                />
                <Route
                  path={appRoutes.GDPR_RECIPIENT}
                  component={GDPRRecipientPage}
                />
                <Route path={appRoutes.USERS} component={UsersHomePage} />
                <Route
                  path={appRoutes.DAILY_REPORTS_BLANK}
                  component={DailyReportsHomePage}
                />
                <Route
                  path={appRoutes.DAILY_REPORT_DETAILS}
                  component={DailyReportDetailsPage}
                />
                <ProtectedComponent roles={[UserRoles.DPI]} render={false}>
                  <Route path={appRoutes.GDPR} component={GDPRHomePage} />
                </ProtectedComponent>
              </Switch>
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY}
                component={NotificationHistoryPage}
              />
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_RESEND_NOTIFICATION}
                component={ResendNotificationPage}
              />
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_UPDATE}
                component={DocumentUpdatePage}
              />
              <Route
                path={appRoutes.DOCUMENT_PUBLICATION_PREVIEW}
                component={PublicationPreviewPage}
              />
              <Route
                path={appRoutes.RECIPIENT_MANAGER}
                component={RecipientManagerPage}
              />
              <Route
                path={appRoutes.RECIPIENT_CREATOR}
                component={RecipientCreateEditPage}
              />
              <Route
                path={appRoutes.RECIPIENT_EDITOR}
                component={RecipientCreateEditPage}
              />
            </>
          ),
          [CUSTOMER.OPEN_PLATFORM]: (
            <>
              <Switch>
                <Route exact path="/" component={RootRedirectPage} />
                <Route path={appRoutes.SUPPORT} component={HelpPage} />
                <Route path={appRoutes.ME} component={MePage} />
                <Route
                  path={appRoutes.PASSWORD_CHANGE}
                  component={ChangePasswordPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS}
                  component={DocumentHomePage}
                />
                <Route
                  path={appRoutes.DOCUMENT_PREVIEW}
                  component={DocumentPreviewPage}
                />
                <Route
                  path={appRoutes.PUBLISH_DOCUMENT}
                  component={PublishDocumentPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS}
                  component={DocumentDetailsPage}
                />
              </Switch>
              <Route
                path={appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY}
                component={NotificationHistoryPage}
              />
            </>
          ),
        }[customer]
      }
    </Layout>
  );
};
