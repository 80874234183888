export const UTILS = {
  CALENDAR_DATE_FORMAT: 'YYYY-MM-DD',
  API_TIMEZONE: 'Europe/Warsaw',
  TIMEOUT: 'TIMEOUT',
};

export const VALIDATION_ERRORS = {
  REQUIRED: 'Field required',
  FUTURE_DATE: 'Pick future date',
  AFTER_OR_SAME_VALID_SINCE_DATE: 'Pick date after or same as valid from date',
  AFTER_OR_SAME_VALID_UNTIL_DATE: 'Pick date after or same as valid until date',
  TEN_YEARS_AHEAD: 'Pick date at least 10 years from now.',
  MAXIMUM_LENGTH_EXCEEDED: 'Maximum length exceeded',
  MAXIMUM_LENGTH_EXCEEDED_LENGTH: 'Maximum length exceeded: {length}',
  MINIMUM_LENGTH_REQUIRED: 'Manimum length required: {length}',
  FILE_SIZE_TOO_LARGE: 'Size of the file is too large.',
  DATE_BETWEEN_1900_2500: 'Select a date from 01.01.1900 to 12.31.2500',
  SAME_AS_PREVIOUS: 'You typed value same as previous',
  INVALID_CODE: 'Invalid code, retry',
  PHONE_INVALID: 'Given phone number is invalid',
  EMAIL_INVALID: 'Email is invalid',
  SLASH_PRESENCE: 'Category name must not contain "/"',
  FILE_FORMAT: 'Invalid file format',
  EMPTY: ' ',
  WHITESPACES_ONLY: 'Field must not contain only whitespaces',
};

export const SETTINGS_CONSTANTS = {
  CUSTOM_LOGO: 'CUSTOM_LOGO',
  PUBLICATOR_NAME: 'PUBLICATOR_NAME',
  PUBLISHER_NAME: 'PUBLISHER_NAME',
  DESCRIPTION: 'DESCRIPTION',
  PUBLISHER_ID: 'PUBLISHER_ID',
  NOTIFICATION_SMS_MESSAGE: 'NOTIFICATION_SMS_MESSAGE',
  NOTIFICATION_EMAIL_MESSAGE: 'NOTIFICATION_EMAIL_MESSAGE',
  NOTIFICATION_EMAIL_TITLE: 'NOTIFICATION_EMAIL_TITLE',
  DOCUMENT_PREVIEW_URL: 'DOCUMENT_PREVIEW_URL',
  SUPPORT_EMAIL: 'SUPPORT_EMAIL',
  PASSWORD_EXPIRATION_TIME: 'PASSWORD_EXPIRATION_TIME',
  RECIPIENT_SOURCE_SYSTEM_NAMES: 'RECIPIENT_SOURCE_SYSTEM_NAMES',
  MAX_SMS_USER_INPUT_LENGTH: 'MAX_SMS_USER_INPUT_LENGTH',
  MAX_PDF_FILE_SIZE: 'MAX_PDF_FILE_SIZE',
  MAX_RECIPIENTS_FILE_SIZE: 'MAX_RECIPIENTS_FILE_SIZE',
  RECIPIENTS_OBLIGATORY: 'RECIPIENTS_OBLIGATORY',
  PUBLISHING_INSTITUTION: 'PUBLISHING_INSTITUTION',
  ONE_STEP_PUBLISH: 'ONE_STEP_PUBLISH',
  SENDER_FIRST_ONE_STEP_PUBLISH: 'SENDER_FIRST_ONE_STEP_PUBLISH',
  PUBLICATION_DELAY_MINUTES: 'PUBLICATION_DELAY_MINUTES',
  PUBLIC_ONE_STEP_PUBLISH: 'PUBLIC_ONE_STEP_PUBLISH',
  PRIVATE_ONE_STEP_PUBLISH: 'PRIVATE_ONE_STEP_PUBLISH',
};

export const UNKNOWN_ERRORS = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  UNKNOWN_PUBLISHING_ERROR: 'UNKNOWN_PUBLISHING_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
};

export const PUBLICATION_TYPE = {
  ONE_STEP: 'ONE_STEP',
  ONE_STEP_ASYNC: 'ONE_STEP_ASYNC',
  TWO_STEP: 'TWO_STEP',
};

export const DOCUMENT_STATUS = {
  UPLOADING: 'UPLOADING',
  CURRENT: 'CURRENT',
  DEPRECATED: 'DEPRECATED',
  RETIRED: 'RETIRED',
  PREPARED_TO_SIGN: 'PREPARED_TO_SIGN',
  NOT_EXIST: 'NOT_EXIST',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  PUBLISHING_INITIATED: 'PUBLISHING_INITIATED',
  SIGNING: 'SIGNING',
  WAITING_FOR_REPUBLISH: 'WAITING_FOR_REPUBLISH',
  // FORGETTING DOCUMENT
  FORGETTING_IN_PROGRESS: 'FORGETTING_IN_PROGRESS',
  FORGETTING_OK: 'FORGETTING_OK',
  FORGETTING_EXCEPTION: 'FORGETTING_EXCEPTION',
};

export const MAP_DOCUMENT_STATUSES = {
  CURRENT: 'Published',
  DEPRECATED: 'Previous Version',
  PREPARED_TO_SIGN: 'Prepared',
  UPLOADING: 'Uploading',
  NOT_EXIST: 'Invalid',
  NOT_AVAILABLE: 'Invalid',
  RETIRED: 'Invalid',
  PUBLISHING_ERROR: 'Publishing error',
  PUBLISHING_INITIATED: 'Uploading',
  WAITING_FOR_REPUBLISH: 'Waiting for republication',
  // FORGETTING DOCUMENT
  FORGETTING_IN_PROGRESS: 'During the forgetting',
  FORGETTING_OK: 'Successfully forgotten',
  FORGETTING_EXCEPTION: 'Forgetting error',
  SIGNING: 'Processing',
  UNKNOWN: 'Unknown',
};

export const documentStatuses = [
  DOCUMENT_STATUS.CURRENT,
  DOCUMENT_STATUS.DEPRECATED,
  DOCUMENT_STATUS.NOT_EXIST,
  DOCUMENT_STATUS.PREPARED_TO_SIGN,
  DOCUMENT_STATUS.UPLOADING,
  DOCUMENT_STATUS.WAITING_FOR_REPUBLISH,
  DOCUMENT_STATUS.SIGNING,
];

export const MAP_SIGNING_STATUSES = {
  CURRENT: 'Approved',
  PUBLISHING_OK: 'Approved',
  WAITING_FOR_RECEIVER: 'Waiting for receiver',
  REJECTED: 'Rejected',
  SIGNING: 'Signing',
};

export const MAP_SIGNING_ERRORS = {
  REJECTED: 'Document rejected by the recipient',
};

export const DOCUMENT_PUBLICATION_STATUS = {
  PREPARED_TO_SIGN: 'PREPARED_TO_SIGN',
  PUBLISHING_ERROR: 'PUBLISHING_ERROR',
  PUBLISHING_INITIATED: 'PUBLISHING_INITIATED',
  PUBLISHING_OK: 'PUBLISHING_OK',
  NOT_EXIST: 'NOT_EXIST',
  WAITING_FOR_REPUBLISH: 'WAITING_FOR_REPUBLISH',
};

export const SIGNING_STATUS = {
  PREPARED_TO_SIGN: 'PREPARED_TO_SIGN',
  PUBLISHING_INITIATED: 'PUBLISHING_INITIATED',
  UPLOADING: 'UPLOADING',
  PUBLISHING_ERROR: 'PUBLISHING_ERROR',
  WAITING_FOR_RECEIVER: 'WAITING_FOR_RECEIVER',
  REJECTED: 'REJECTED',
  PUBLISHING_OK: 'PUBLISHING_OK',
  SIGNING: 'SIGNING',
};

export enum CUSTOMER {
  DEFAULT = 'DEFAULT',
  TAURON = 'TAURON',
  DIPLOMA = 'DIPLOMA',
  TAURON_AGREEMENT = 'TAURON_AGREEMENT', // Do not use. Its used only for document details
  OPEN_PLATFORM = 'OPEN_PLATFORM',
}

export const countryToLanguage = {
  GB: 'en',
  PL: 'pl',
};

export const languageToCountry = {
  en: 'GB',
  pl: 'PL',
};

export const PUBLICATION_MODES = {
  CORRECTED: 'CORRECTED',
  UPDATED: 'UPDATED',
  NEW: 'NEW',
  INCIDENT: 'INCIDENT',
};

export const map_PUBLICATION_MODES = {
  CORRECTED: 'Erratum',
  UPDATED: 'New version',
  NEW: 'New version',
  INCIDENT: 'Incident',
};

export const PUBLICATION_STATUSES = {
  PUBLISHING_OK: 'PUBLISHING_OK',
  PUBLISHING_INITIATED: 'PUBLISHING_INITIATED',
  PREPARED_TO_SIGN: 'PREPARED_TO_SIGN',
  PUBLISHING_ERROR: 'PUBLISHING_ERROR',
};

export const RESEND_NOTIFICATION_MODES = {
  SELECT_TYPE: 'SELECT_TYPE',
  SELECT_GROUP: 'SELECT_GROUP',
  SEARCH_LIST: 'SEARCH_LIST',
  SUMMARY_LIST: 'SUMMARY_LIST',
  EDIT_TYPE_VIEW: 'EDIT_TYPE_VIEW',
  EDIT_EMAIL_NOTIFICATION: 'EDIT_EMAIL_NOTIFICATION',
  EDIT_SMS_NOTIFICATION: 'EDIT_SMS_NOTIFICATION',
  RESEND_PROCESSING: 'RESEND_PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const NOTIFICATION_HISTORY_STATUSES = {
  DELIVERED: 'DELIVERED',
  ERROR: 'ERROR',
  UNDELIVERED: 'UNDELIVERED',
  PREPARED: 'PREPARED',
  IN_PROGRESS: 'IN_PROGRESS',
  UPDATED: 'UPDATED',
  NEW: 'NEW',
};

export const NOTIFICATION_HISTORY_STATUSES_LABELS = {
  DELIVERED: 'Successfully notified',
  ERROR: 'Error2',
  UNDELIVERED: 'Error2',
  PREPARED: 'Prepared',
  IN_PROGRESS: 'In progress',
  UPDATED: 'Updated',
  NEW: 'New',
};

export const NOTIFICATION_HISTORY_FILTERS_OPTIONS = {
  CONTACT_DETAILS: 'CONTACT_DETAILS',
  CLIENT_ID: 'CLIENT_ID',
  STATUS: 'STATUS',
};

export const NOTIFICATION_TYPES = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

export const NOTIFICATION_TEMPLATE_TYPE = {
  PUBLIC_PUBLISHED: 'PUBLIC_PUBLISHED',
  PRIVATE_PUBLISHED: 'PRIVATE_PUBLISHED',
  AGREEMENT_READY_TO_SIGN: 'AGREEMENT_READY_TO_SIGN',
  AGREEMENT_PUBLISHED: 'AGREEMENT_PUBLISHED',
  AGREEMENT_REJECTED: 'AGREEMENT_REJECTED',
};

export const DOCUMENT_RECIPIENTS_GROUPS = {
  ERROR: 'ERROR',
  NEW: 'NEW',
  SUCCESS: 'SUCCESS',
};

export const DOCUMENT_RECIPIENTS_FILTERS_OPTIONS = {
  CONTACT_DETAILS: 'CONTACT_DETAILS',
  RECIPIENT_ID: 'RECIPIENT_ID',
  GROUPS: 'GROUPS',
};

export const DOCUMENT_RECIPIENTS_GROUPS_LABELS = {
  ERROR: 'Incorrect3',
  NEW: 'Status: PENDING',
};

export const USER_STATUSES = {
  SHELL: 'SHELL',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  ACCOUNT_TEMPORARY_BLOCKED: 'ACCOUNT_TEMPORARY_BLOCKED',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
};

export const mapUSER_STATUSES = {
  SHELL: 'Shell',
  PENDING: 'Pending',
  REGISTERED: 'Registered',
  ACCOUNT_TEMPORARY_BLOCKED: 'Blocked',
  PASSWORD_EXPIRED: 'Password-expired',
};

export const USER_STATUS_MESSAGES = {
  SHELL:
    'Your account has been prepared, please initiate the registration process',
  PENDING: 'Waiting for user to set password',
  REGISTERED: 'The user can make full use of the system',
  ACCOUNT_TEMPORARY_BLOCKED:
    'The user has entered the wrong password too many times',
  PASSWORD_EXPIRED: 'User password expired',
};

export const PUBLICATION_ERROR_DETAILS = {
  DOCUMENT_CONTENT_WRONG: 'DOCUMENT_CONTENT_WRONG',
  DOCUMENT_WRONG: 'DOCUMENT_WRONG',
  DOCUMENT_CONTENT_EMPTY: 'DOCUMENT_CONTENT_EMPTY',
  DOCUMENT_DUPLICATED: 'DOCUMENT_DUPLICATED',
  DOCUMENT_EXTENSION_WRONG: 'DOCUMENT_EXTENSION_WRONG',
  DOCUMENT_NOT_PDF: 'DOCUMENT_NOT_PDF',
  DOCUMENT_TOO_LARGE: 'DOCUMENT_TOO_LARGE',
  DOCUMENT_NOT_IDENTICAL: 'DOCUMENT_NOT_IDENTICAL',
  SIGNATURE_DOCUMENT_ALTERED: 'SIGNATURE_DOCUMENT_ALTERED',
  DOCUMENT_NOT_SIGNED: 'DOCUMENT_NOT_SIGNED',
  WRONG_AGREEMENT_TYPE: 'WRONG_AGREEMENT_TYPE',
  WRONG_REGISTRATION_NUMBER: 'WRONG_REGISTRATION_NUMBER',
  WRONG_PAYER_NUMBER: 'WRONG_PAYER_NUMBER',
  EMPTY_CONTACT_DATA: 'EMPTY_CONTACT_DATA',
  WRONG_HTML_FILE: 'WRONG_HTML_FILE',
  CLIENT_CONTACT_DATA_NOT_VALID: 'CLIENT_CONTACT_DATA_NOT_VALID',
  WRONG_FACSIMILE_FILENAME: 'WRONG_FACSIMILE_FILENAME',
  DOCUMENT_PROTECTED: 'DOCUMENT_PROTECTED',
};

export const TAURON_DOCUMENT_TYPE = {
  TARIFF: 'TARIFF',
  AGREEMENT: 'AGREEMENT',
};

export enum DOCUMENT_TYPE {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  REPORT = 'REPORT',
}

export const DOCUMENTS_OPTIONS = {
  ALL: 'ALL',
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  REPORT: 'REPORT',
};

export const MAP_DOCUMENTS_OPTIONS = {
  ALL: 'All documents',
  PRIVATE: 'Private documents',
  PUBLIC: 'Public documents',
  REPORT: 'Delivery Reports',
};

export const CONFIG = {
  publicDocuments: 'publicDocuments',
  privateDocuments: 'privateDocuments',
};

export const MAP_DOCUMENT_TYPE_TO_CONFIG = {
  PUBLIC: CONFIG.publicDocuments,
  PRIVATE: CONFIG.privateDocuments,
};

export const AGREEMENT_STATUS = {
  WAITING_FOR_SENDER: 'WAITING-FOR-SENDER',
  WAITING_FOR_RECEIVER: 'WAITING-FOR-RECEIVER',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  ERROR: 'ERROR',
};

export const FILTERS_MAP = {
  category: 'Category',
  documentBlockchainAddress: 'Document blockchain address',
  indexNumber: 'Index number',
  publicationDate: 'Publication date',
  recipient: 'Recipient',
  recipientId: 'Recipient id',
  requestCreationTime: 'Request Issued on',
  requestJobId: 'Request ID',
  requestStatus: 'Request status',
  requestValidityDateRange: 'Request Valid until',
  statusList: 'Status',
  surname: 'Surname',
  title: 'Document title',
  type: 'Document type',
  validFromTo: 'Valid from to',

  // doc-filters
  categoryId: 'Category',
  blockchainAddress: 'Document blockchain address',
  documentType: 'Document type',
  publicationDateRange: 'Publication date from-to',
  publicationStatusList: 'Publication status',
  publishedBy: 'Published by',
  validUntilDateRange: 'Valid from to',
  validSinceDateRange: 'Valid from to',
};

export const FILTERS_MAP_MVP = {
  title: 'Document title',
  category: 'Category',
  statusList: 'Status',
  recipientId: 'Recipient id',
  type: 'Document type',
  publicationDate: 'Publication date',
  validFromTo: 'Valid from to',
  documentBlockchainAddress: 'Document blockchain address',
};
export const REQUEST_STATUS = {
  INITIATED: 'INITIATED',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
  ACCESS_GRANTED: 'ACCESS-GRANTED',
  EXPIRED: 'EXPIRED',
};

export const RETAIN_OPTIONS = {
  YEARS_1: 'YEARS_1',
  YEARS_6: 'YEARS_6',
  YEARS_10: 'YEARS_10',
  YEARS_20: 'YEARS_20',
  YEARS_50: 'YEARS_50',
};

export const RETAIN_OPTIONS_VALUES = {
  YEARS_1: 1,
  YEARS_6: 6,
  YEARS_10: 10,
  YEARS_20: 20,
  YEARS_50: 50,
};

export const retainOptionsList = [
  RETAIN_OPTIONS.YEARS_1,
  RETAIN_OPTIONS.YEARS_6,
  RETAIN_OPTIONS.YEARS_20,
  RETAIN_OPTIONS.YEARS_50,
];

export const documentPreparationERRORS = {
  // BRUM statusCode
  FORMAT_NOT_SUPPORTED: 'FORMAT_NOT_SUPPORTED',
  DOCUMENT_INFO_MISSING: 'DOCUMENT_INFO_MISSING',
  CATEGORY_NOT_FOUND: 'CATEGORY_NOT_FOUND',
  // BEM statusCode
  CATEGORY_INACTIVE: 'CATEGORY_INACTIVE',
  CATEGORY_WRONG: 'CATEGORY_WRONG',
  CATEGORY_NOT_EXISTING: 'CATEGORY_NOT_EXISTING',
  CATEGORY_PARENT_NOT_ACTIVE: 'CATEGORY_PARENT_NOT_ACTIVE',
  DOCUMENT_CONTENT_EMPTY: 'DOCUMENT_CONTENT_EMPTY',
  DOCUMENT_CONTENT_WRONG: 'DOCUMENT_CONTENT_WRONG',
  DOCUMENT_DUPLICATED: 'DOCUMENT_DUPLICATED',
  DOCUMENT_EXTENSION_WRONG: 'DOCUMENT_EXTENSION_WRONG',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_TOO_LATE:
    'DOCUMENT_LEGAL_VALIDITY_END_DATE_TOO_LATE',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_WRONG:
    'DOCUMENT_LEGAL_VALIDITY_END_DATE_WRONG',
  DOCUMENT_LEGAL_VALIDITY_START_DATE_WRONG:
    'DOCUMENT_LEGAL_VALIDITY_START_DATE_WRONG',
  DOCUMENT_WRONG: 'DOCUMENT_WRONG',
  DOCUMENT_NOT_PDF: 'DOCUMENT_NOT_PDF',
  DOCUMENT_PREVIOUS_VERSION_WRONG: 'DOCUMENT_PREVIOUS_VERSION_WRONG',
  DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING:
    'DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING',
  DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED:
    'DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED',
  DOCUMENT_PROTECTED: 'DOCUMENT_PROTECTED',
  DOCUMENT_PUBLICATION_REASON_WRONG: 'DOCUMENT_PUBLICATION_REASON_WRONG',
  DOCUMENT_RETENTION_DATE_PAST: 'DOCUMENT_RETENTION_DATE_PAST',
  DOCUMENT_RETENTION_DATE_WRONG: 'DOCUMENT_RETENTION_DATE_WRONG',
  DOCUMENT_TITLE_WRONG: 'DOCUMENT_TITLE_WRONG',
  DOCUMENT_TOO_LARGE: 'DOCUMENT_TOO_LARGE',
  DUPLICATED_DOCUMENT: 'DUPLICATED_DOCUMENT',
  INVALID_LINK_TEMPLATE: 'INVALID_LINK_TEMPLATE',
};

export const documentPublicationERRORS = {
  // BEM statusCode
  CATEGORY_INACTIVE: 'CATEGORY_INACTIVE',
  CATEGORY_PARENT_NOT_ACTIVE: 'CATEGORY_PARENT_NOT_ACTIVE',
  DOCUMENT_WRONG: 'DOCUMENT_WRONG',
  DOCUMENT_NOT_IDENTICAL: 'DOCUMENT_NOT_IDENTICAL',
  DOCUMENT_NOT_PDF: 'DOCUMENT_NOT_PDF',
  DOCUMENT_NOT_SIGNED: 'DOCUMENT_NOT_SIGNED',
  DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY:
    'DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY',
  DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED:
    'DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED',
  DOCUMENT_RETENTION_DATE_PAST: 'DOCUMENT_RETENTION_DATE_PAST',
  SYNC_CONFLICT_SOLUTION_WRONG: 'SYNC_CONFLICT_SOLUTION_WRONG',
  SIGNATURE_DOCUMENT_ALTERED: 'SIGNATURE_DOCUMENT_ALTERED',
  DUPLICATED_DOCUMENT: 'DUPLICATED_DOCUMENT',
  INVALID_LINK_TEMPLATE: 'INVALID_LINK_TEMPLATE',
};

export const documentPublicationCkkERRORS = {
  // ckk
  IDENTITY_NOT_FOUND: 'IDENTITY_NOT_FOUND',
  INFRASTRUCTURE_NOT_INITIALIZED: 'INFRASTRUCTURE_NOT_INITIALIZED',
  PUBLISHING_EXCEPTION: 'PUBLISHING_EXCEPTION',
  INVALID_JOB_ID: 'INVALID_JOB_ID',
  CATEGORY_NOT_EXISTING: 'CATEGORY_NOT_EXISTING',
  DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY:
    'DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY',
  DOCUMENT_CONTENT_WRONG: 'DOCUMENT_CONTENT_WRONG',
  DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING:
    'DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING',
  DOCUMENT_TOO_LARGE: 'DOCUMENT_TOO_LARGE',
  PUBLISHING_SUBSYSTEM_NOT_READY: 'PUBLISHING_SUBSYSTEM_NOT_READY',
  PUBLISHING_SUBSYSTEM_USER_NOT_LOGGEDIN:
    'PUBLISHING_SUBSYSTEM_USER_NOT_LOGGEDIN',
  PUBLISHING_SUBSYSTEM_LOW_DISK_SPACE: 'PUBLISHING_SUBSYSTEM_LOW_DISK_SPACE',
  SEARCHING_FOR_CATEGORIES: 'SEARCHING_FOR_CATEGORIES',
  PUBLISHING_MAX_DOCS_LIMIT_REACHED: 'PUBLISHING_MAX_DOCS_LIMIT_REACHED',
  IDENTITY_ALREADY_REGISTERED: 'IDENTITY_ALREADY_REGISTERED',
  RT_SUBSYSTEM_ERROR: 'RT_SUBSYSTEM_ERROR',
  PUBLISHING_NETWORK_PROBLEM: 'PUBLISHING_NETWORK_PROBLEM',
  FORGETTING_INITIATED: 'FORGETTING_INITIATED',
  FORGETTING_IN_PROGRESS: 'FORGETTING_IN_PROGRESS',
  FORGETTING_OK: 'FORGETTING_OK',
  DOCUMENT_ALREADY_FORGOTTEN: 'DOCUMENT_ALREADY_FORGOTTEN',
  ACCESS_IMPOSSIBLE: 'ACCESS_IMPOSSIBLE',
  DUPLICATED_DOCUMENT: 'DUPLICATED_DOCUMENT',
  DOCUMENT_NOT_SIGNED: 'DOCUMENT_NOT_SIGNED',
  INVALID_LINK_TEMPLATE: 'INVALID_LINK_TEMPLATE',
  CATEGORY_INACTIVE: 'CATEGORY_INACTIVE',
  SIGNING_ERROR: 'SIGNING_ERROR',
};

export const NOTIFICATION_HISTORY_ERRORS_STATUSES = {
  // sms status
  REJECTED: 'REJECTED',
  FAILED: 'FAILED',
  UNDELIVERED: 'UNDELIVERED',
  STOP: 'STOP',
  EXPIRED: 'EXPIRED',
  UNKNOWN: 'UNKNOWN',
  SPAM_PROTECTED: 'SPAM_PROTECTED',
  SMS_ERROR: 'SMS_ERROR',
  // email status
  GATEWAY_NOT_AVAILABLE: 'GATEWAY_NOT_AVAILABLE',
};

export const MAP_NOTIFICATION_HISTORY_ERRORS_STATUSES = {
  // sms status
  REJECTED: 'Message not delivered due to e.g. wrong number, roaming error',
  FAILED: 'Sending message failed, please report it to the administrator',
  UNDELIVERED: 'Message not delivered due to e.g. wrong number, roaming error',
  STOP: 'Message sending has been stopped manually',
  EXPIRED: 'Message expired, cannot check status',
  UNKNOWN: 'No report, message status unknown',
  SPAM_PROTECTED: 'Protection against spam',
  SMS_ERROR: 'Error during sms dispatch initiation',
  // email status
  GATEWAY_NOT_AVAILABLE: 'Connection error with email server',
};

export const UNHANDLED_ERRORS = {
  DELETE_DOCUMENT_ERROR: 'DELETE_DOCUMENT_ERROR',
  UNKNOWN_PUBLISHING_ERROR: 'PUBLISHING_ERROR',
  UNKNOWN_RENOTIFY_ERROR: 'UNKNOWN_RENOTIFY_ERROR',
};

export const mapERRORS = {
  INVALID_EMAIL_TEMPLATE: 'Invalid email template',
  FORMAT_NOT_SUPPORTED:
    'Document format not supported, media type should be application/pdf',
  DOCUMENT_INFO_MISSING:
    'Document information not found, no json file with document data passed',
  TENANT_NOT_EXIST: 'The designated Publishing Institution does not exist',
  // categories
  CATEGORY_WRONG: 'The category name must be provided',
  CATEGORY_NOT_EXISTING: 'This category does not exist',
  CATEGORY_PARENT_NAME_NOT_EXISTING: 'Category parent does not exist',
  CATEGORY_PARENT_NOT_ACTIVE:
    'One or more categories lying on the path of the added category are not active',
  CATEGORY_SLASH_FORBIDDEN: 'Category name must not contain "/"',
  CATEGORY_STATUS_WRONG: 'Category status must be transmitted',
  CATEGORY_ALREADY_EXISTS: 'This category already exists',
  CATEGORY_NAME_TOO_SHORT:
    'Category name must not be shorter than {n} characters',
  ADDING_ERROR:
    'Failed to add a category, try again or contact the administrator',
  CATEGORY_NOT_FOUND: 'Category not found',
  CATEGORY_INACTIVE: 'Document category must be active',
  ONE_STEP_PUBLISH_ENABLED: 'One step publication enabled',
  NOT_EXIST:
    'Unexpected error when publishing the document, please contact the administrator',
  NOT_FOUND: 'Document not found',
  DOCUMENT_CONTENT_EMPTY: 'The document must have the following content',
  DOCUMENT_CONTENT_WRONG: 'Document is damaged',
  DOCUMENT_DUPLICATED:
    'An identical document has already been published on the blockchain network',
  DOCUMENT_EXTENSION_WRONG: 'Incorrect file extension',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_TOO_LATE:
    'The end date of the document must be prior to or the same as the retention date of the document',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_WRONG: 'Incorrect document end date format',
  DOCUMENT_LEGAL_VALIDITY_START_DATE_WRONG:
    'Incorrect document start date format',
  DOCUMENT_WRONG: 'Document not provided',
  DOCUMENT_NOT_PDF:
    'Incorrect format of the submitted document. The document must be in pdf format',
  DOCUMENT_PREVIOUS_VERSION_WRONG:
    'The address of the previous version of the document must be communicated',
  DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING:
    'Previous version of document with specified address not found',
  DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED:
    'The address of the previous version of the document is not required',
  DOCUMENT_PROTECTED: 'Document is blocked by another program',
  DOCUMENT_PUBLICATION_REASON_WRONG: 'Incorrect reason for publication',
  DOCUMENT_RETENTION_DATE_PAST: 'The retention date shall not be past',
  DOCUMENT_RETENTION_DATE_WRONG: 'Incorrect retention date format',
  DOCUMENT_TITLE_WRONG: 'The title of the document must be forwarded',
  DOCUMENT_TOO_LARGE: 'File size exceeded (x MB)',
  DOCUMENT_TOO_LARGE_PREPARING_ERROR: 'Permitted document file size exceeded',
  DOCUMENT_NOT_IDENTICAL:
    'The document transmitted is not the same as the document transmitted in the preparation step',
  DOCUMENT_NOT_SIGNED: 'Document not signed',
  DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY:
    'The address of the previous version of the document must point to the most recent version in the history of the document',
  SYNC_CONFLICT_SOLUTION_WRONG: 'Wrong way to resolve a conflict',
  SIGNATURE_DOCUMENT_ALTERED: 'Document edited after signing',
  IDENTITY_NOT_FOUND: 'Identity not found',
  INFRASTRUCTURE_NOT_INITIALIZED:
    'Infrastructure has not been initialised - incorrect status, please contact your administrator',
  PUBLISHING_EXCEPTION:
    'No error status found, please contact your administrator',
  INVALID_JOB_ID: 'Invalid jobId',
  PUBLISHING_SUBSYSTEM_NOT_READY:
    'The publishing system is not ready, please contact the administrator',
  PUBLISHING_SUBSYSTEM_USER_NOT_LOGGEDIN:
    'The user is not logged in to the publishing system, please contact the administrator',
  PUBLISHING_SUBSYSTEM_LOW_DISK_SPACE:
    'No disk space available on the publishing system, please contact your administrator',
  SEARCHING_FOR_CATEGORIES: 'Searching for categories',
  PUBLISHING_MAX_DOCS_LIMIT_REACHED:
    'The limit on the number of publications has been reached, please contact the administrator',
  IDENTITY_ALREADY_REGISTERED: 'Identity already registered',
  RT_SUBSYSTEM_ERROR:
    'Error in Identity Register system, please contact your administrator',
  PUBLISHING_NETWORK_PROBLEM:
    'Publishing network error, please contact your administrator',
  FORGETTING_INITIATED: 'The process of forgetting has begun',
  FORGETTING_IN_PROGRESS: 'The process of forgetting is under way',
  FORGETTING_OK: 'Successful forgetting',
  DOCUMENT_ALREADY_FORGOTTEN: 'The document has already been forgotten',
  ACCESS_IMPOSSIBLE: 'Access not possible, please contact your administrator',
  PUBLISHING_ERROR: 'Publishing error',
  ERROR: 'Error',
  DUPLICATED_DOCUMENT:
    'An identical document has already been published on the blockchain network',
  INVALID_LINK_TEMPLATE:
    'Invalid notification template (no checksum), contact the Administrator',
  // sms
  REJECTED: 'Message not delivered due to e.g. wrong number, roaming error',
  FAILED: 'Sending message failed, please report it to the administrator',
  UNDELIVERED: 'Message not delivered due to e.g. wrong number, roaming error',
  STOP: 'Message sending has been stopped manually',
  EXPIRED: 'Message expired, cannot check status',
  UNKNOWN: 'No report, message status unknown',
  SPAM_PROTECTED: '',
  // else
  INTERNAL_SERVER_ERROR: 'Unexpected error, please contact your administrator',
  UNKNOWN_ERROR: 'Unexpected error, please contact your administrator',
  INVALID_IDENTITY: 'Invalid identity',
  IDENTITY_INACTIVE: 'Identity inactive',
  EMAIL_NOT_FOUND: 'There is no user with the specified email address',
  SIGNING_ERROR: 'Signing error',
  DELETE_DOCUMENT_ERROR:
    'Unable to delete given document, please contact administrator',
  UNDISCARDABLE:
    'Unable to delete given document, please contact administrator',
  UNKNOWN_PUBLISHING_ERROR:
    'Unable to publish document, please contact administrator',
  UNKNOWN_RENOTIFY_ERROR: 'Unable to re-notify, please contact administrator',
  LINK_EXPIRED: 'Contact the Administrator to generate a new link',
  // recipients
  RECIPIENTS_FILE_FORBIDDEN:
    'Unable to add recipients file to given publication',
  RECIPIENTS_FILE_EMPTY: 'No recipients file added',
  RECIPIENTS_FILE_TOO_LARGE: "Recipients' file is too large",
  PREPARING_NOTIFICATION_ERROR: 'Notification sending error',
  IDENTITY_DURING_PUBLISH: 'Identity during publication',
  UNHANDLED: 'unhandled',
  UNHANDLED_STATUS: 'Unhandled status',
  RECIPIENTS_AMOUNT_IN_FILE_EXCEEDED: 'Recipients amount in file exceeded',
};

export const MAP_DIPLOMA_ERRORS = {
  INVALID_IDENTITY: 'Invalid student',
  IDENTITY_INACTIVE: 'Student inactive',
};

export const MAP_CUSTOM_TAURON_ERRORS = {
  TENANT_NOT_EXIST: 'The designated Publisher does not exist',
};

export const MAP_RECOVER_PASSWORD_ERRORS = {
  NOT_FOUND: 'There is no user with the specified email address',
  USER_NOT_REGISTERED: 'User unregistered',
  NOTIFICATION_SENDING_ERROR:
    'Sending a message failed. Try again later or contact your administrator',
};

export const DOCUMENT_ACTIONS = {
  UPDATE: 'UPDATE',
};

export const RECIPIENT_ACTIONS = {
  INSERT_LIST: 'INSERT_LIST',
  UPDATE_LIST: 'UPDATE_LIST',
  EXTERNAL_VALIDATION: 'EXTERNAL_VALIDATION',
};

export const RECIPIENT_STATUSES = {
  SHELL: 'SHELL',
  PUBLISHING: 'PUBLISHING',
  PUBLISHED: 'PUBLISHED',
  SUSPENDED: 'SUSPENDED',
  ERROR: 'ERROR',
  UPDATING: 'UPDATING',
  FORGETTING: 'FORGETTING',
};

export const MAP_RECIPIENT_STATUSES = {
  SHELL: 'Prepared',
  PUBLISHING: 'Adding',
  PUBLISHED: 'Added',
  SUSPENDED: 'Suspended2',
  ERROR: 'Error',
  UPDATING: 'updating-recipient-status',
  FORGETTING: 'forgetting-recipient-status',
};

export const MAP_RECIPIENT_INFOS = {
  SHELL:
    'Identity has been prepared. The recipient will be added to the Distributed Register when the first private document associated with that identity is published.',
  PUBLISHING: 'Adding Recipient to the Distributed Register is in progress',
  PUBLISHED: 'The Recipient has been added to the Distributed Registry',
  SUSPENDED:
    'The Recipient has been suspended. DPI has suspended data processing',
  ERROR: 'Error when setting up identity in Distributed Registry',
  UPDATING: 'Recipient data is being updated',
  FORGETTING: 'Recipient is being forgotten, DPI has executed GDPR',
};

export const RECIPIENT_UPLOAD_ERROR_VALUES = {
  NEEDS_UPDATE: 'NEEDS_UPDATE',
  EMPTY: 'EMPTY',
  DIFFERENT: 'DIFFERENT',
  INVALID: 'INVALID',
  NON_UNIQUE: 'NON_UNIQUE',
  MISSING: 'MISSING',
  DUPLICATED: 'DUPLICATED',
  WHITESPACE_FORBIDDEN: 'WHITESPACE_FORBIDDEN',
};

export const VALIDATION_RECIPIENTS_INTO_FIELDS = {
  EMAIL: 'email',
  ID: 'id',
  PHONE_NUMBER: 'phoneNumber',
};

export const MAP_RECIPIENT_UPLOAD_ERRORS = {
  EMPTY_EMAIL: 'Email address missing, please complete',
  EMPTY_ID: 'ID missing, please complete',
  EMPTY_SYSTEM_SOURCE: 'Source system missing, please complete',
  EMPTY_PPE_NUMBER: 'PPE Number is missing, please complete',
  INVALID_EMAIL: 'Invalid e-mail',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  INVALID_SYSTEM_SOURCE: 'Invalid source system',
  INVALID_DOCUMENT_NUMBER: 'Invalid Document Number',
  WHITESPACE_FORBIDDEN_EMAIL:
    'Please remove white characters from Email adress',
  WHITESPACE_FORBIDDEN_ID: 'Please remove white characters from ID',
  WHITESPACE_FORBIDDEN_SOURCE_SYSTEM:
    'Please remove white characters from Source System',
  NEEDS_UPDATE_PHONE_NUMBER: "Recipient's phone number will be updated",
  DIFFERENT_EMAIL:
    'Recipient with the given ID already exists and has a different email address',
  EMPTY_PHONE_NUMBER: 'Phone Number missing, please complete',
  EMPTY_DOCUMENT_NUMBER: 'Document Number missing, please complete',
  EMPTY_LAST_NAME: 'Last Name missing, please complete',
  EMPTY_FIRST_NAME: 'First Name missing, please complete',
  NON_UNIQUE_DOCUMENT_NUMBER:
    'There is more than one recipient with a given Document Number',
};

export const RECIPIENT_FILE_ERRORS_TYPE = {
  MISSING: 'MISSING',
  DUPLICATED: 'DUPLICATED',
};

export const RECIPIENT_UPLOAD_FILE_ERRORS = {
  MISSING_EMAIL: 'Email column is missing',
  MISSING_ID: 'ID column is missing',
  MISSING_SOURCE_SYSTEM: 'Source System column is missing',
  MISSING_PPE_NUMBER: 'PPE Number column is missing',
  DUPLICATED_EMAIL: "There's more than one Email column",
  DUPLICATED_ID: "There's more than one ID column",
  DUPLICATED_SOURCE_SYSTEM: "There's more than one Source System column",
  DUPLICATED_PHONE_NUMBER: "There's more than one Phone Number column",
  DUPLICATED_PPE_NUMBER: "There's more than one PPE Number column",
  UNKNOWN:
    'Unknown error occured, please verify whether column names are correct',

  // RECIPIENTS
  MISSING_DOCUMENT_TYPE: 'Document Type column is missing',
  MISSING_DOCUMENT_NUMBER: 'Document Number column is missing',
  MISSING_PHONE_NUMBER: 'Phone Number column is missing',
  MISSING_FIRST_NAME: 'First Name column is missing',
  MISSING_LAST_NAME: 'Last Name column is missing',
  DUPLICATED_DOCUMENT_NUMBER: "There's more than one Document Number column",
  DUPLICATED_FIRST_NAME: "There's more than one First Name column",
  DUPLICATED_LAST_NAME: "There's more than one Last Name column",
};

export const RECIPIENT_UPLOAD_FILE_ERRORS_DIPLOMA = {
  MISSING_EMAIL: 'Email column is missing',
  MISSING_ID: 'ID column is missing',
  MISSING_SOURCE_SYSTEM: 'Source System column is missing',
  DUPLICATED_EMAIL: "There's more than one Email column",
  DUPLICATED_ID: "There's more than one ID column",
  DUPLICATED_SOURCE_SYSTEM: "There's more than one Source System column",
  DUPLICATED_PHONE_NUMBER: "There's more than one Phone Number column",
  UNKNOWN:
    'Unknown error occured, please verify whether column names are correct',

  // STUDENTS
  MISSING_DOCUMENT_TYPE: 'Document Type column is missing',
  MISSING_DOCUMENT_NUMBER: 'Index column is missing',
  MISSING_PHONE_NUMBER: 'Phone Number column is missing',
  MISSING_FIRST_NAME: 'First Name column is missing',
  MISSING_LAST_NAME: 'Last Name column is missing',
  DUPLICATED_DOCUMENT_NUMBER: "There's more than one Index column",
  DUPLICATED_FIRST_NAME: "There's more than one First Name column",
  DUPLICATED_LAST_NAME: "There's more than one Last Name column",
};

export const UPDATE_IDENTITY_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const MAP_UPDATE_IDENTITY_STATUSES = {
  IN_PROGRESS: 'Recipient data update has begun',
  SUCCESS: 'Recipient data has been updated',
  ERROR: 'Error while updating recipient data',
};

export const MAP_UPDATE_IDENTITY_STATUSES_VALUES = {
  IN_PROGRESS: 'In progress',
  SUCCESS: 'Updated',
  ERROR: 'Error',
};

export const mapDOCUMENT_ACTIONS_ERROR = {
  UPDATE: 'An error occurred while trying to update document',
  PREPARE: 'An error occurred while trying to prepare document',
  PUBLISH: 'An error occurred while trying to publish document',
  DELETE: 'An error occurred while trying to delete document',
  RENOTIFY: 'An error occurred while trying to re-notify',
};

export const mapDOCUMENT_ACTIONS_SUCCESS = {
  UPDATE: 'Update successful',
};

export const PASSWORD_VALIDATION = {
  PASSWORD_LENGTH: 'PASSWORD_LENGTH',
  PASSWORD_COMPLEXITY_GROUPS: 'PASSWORD_COMPLEXITY_GROUPS',
  UNIQUE_PASSWORDS: 'UNIQUE_PASSWORDS',
  HOURS_TO_CHANGE_PASSWORD: 'HOURS_TO_CHANGE_PASSWORD',
};

export const PASSWORD_VALIDATION_ERRORS = {
  PASSWORDS_VARY: 'PASSWORDS_VARY',
  PASSWORD_IDENTICAL: 'PASSWORD_IDENTICAL',
};

export const MAP_PASSWORD_VALIDATION_ERRORS = {
  PASSWORDS_VARY: 'Passwords vary',
  INCORRECT_PREVIOUS_PASSWORD: 'Password is incorrect',
  PASSWORD_IDENTICAL: 'Password must be different from the current one',
};

export const PASSWORD_VALIDATION_REASONS = {
  PASSWORD_TOO_SHORT: 'PASSWORD_TOO_SHORT',
  PASSWORD_NO_UNIQUE_CHARACTERS: 'PASSWORD_NO_UNIQUE_CHARACTERS',
  PASSWORD_PREVIOUSLY_USED: 'PASSWORD_PREVIOUSLY_USED',
  PASSWORD_CHANGED_TOO_OFTEN: 'PASSWORD_CHANGED_TOO_OFTEN',
  INCORRECT_PREVIOUS_PASSWORD: 'INCORRECT_PREVIOUS_PASSWORD',
};

export const MAP_PASSWORD_VALIDATION_REASONS = {
  PASSWORD_TOO_SHORT: 'PASSWORD_LENGTH',
  PASSWORD_NO_UNIQUE_CHARACTERS: 'PASSWORD_COMPLEXITY_GROUPS',
  PASSWORD_PREVIOUSLY_USED: 'UNIQUE_PASSWORDS',
  PASSWORD_CHANGED_TOO_OFTEN: 'HOURS_TO_CHANGE_PASSWORD',
  INCORRECT_PREVIOUS_PASSWORD: 'INCORRECT_PREVIOUS_PASSWORD',
};

export const MAP_PASSWORD_VALIDATION_TO_REASONS = {
  PASSWORD_LENGTH: 'PASSWORD_TOO_SHORT',
  PASSWORD_COMPLEXITY_GROUPS: 'PASSWORD_NO_UNIQUE_CHARACTERS',
  UNIQUE_PASSWORDS: 'PASSWORD_PREVIOUSLY_USED',
  HOURS_TO_CHANGE_PASSWORD: 'PASSWORD_CHANGED_TOO_OFTEN',
  INCORRECT_PREVIOUS_PASSWORD: 'INCORRECT_PREVIOUS_PASSWORD',
};

export const MAP_PASSWORD_VALIDATION = {
  PASSWORD_LENGTH: 'Password must contain at least {n} characters',
  PASSWORD_COMPLEXITY_GROUPS:
    'Password must contain at least {n} of the following 4 groups: lower case letters, capital letters, digits, special characters',
  UNIQUE_PASSWORDS: 'Password must not be the same as the previous {n}',
  HOURS_TO_CHANGE_PASSWORD: 'Password can only be changed once in {n} hours',
};

export const VALIDATION_ERROR_TYPES = {
  WHITESPACE_FORBIDDEN: 'WHITESPACE_FORBIDDEN',
  CHANGED_TOO_OFTEN: 'CHANGED_TOO_OFTEN',
  PREVIOUSLY_USED: 'PREVIOUSLY_USED',
  TOO_SHORT: 'TOO_SHORT',
  NO_UNIQUE_CHARACTERS: 'NO_UNIQUE_CHARACTERS',
  INCORRECT_PREVIOUS: 'INCORRECT_PREVIOUS',
  IDENTICAL: 'IDENTICAL',
  EMPTY: 'EMPTY',
  VARY: 'VARY',
};

export const MAP_VALIDATION_ERROR_TYPES = {
  WHITESPACE_FORBIDDEN:
    'Password must not contain white characters (e.g. spaces)',
  CHANGED_TOO_OFTEN: 'Password can only be changed once in {n} hours',
  PREVIOUSLY_USED: 'Password must not be the same as the previous {n}',
  TOO_SHORT: 'Password must contain at least {n} characters',
  NO_UNIQUE_CHARACTERS:
    'Password must contain at least {n} of the following 4 groups: lower case letters, capital letters, digits, special characters',
  INCORRECT_PREVIOUS: 'Password is incorrect',
  IDENTICAL: 'Password must be different from the current one',
  EMPTY: 'Ppassword cannot be empty',
  VARY: 'Passwords vary',
};

export const MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS = {
  CHANGED_TOO_OFTEN: 'HOURS_TO_CHANGE_PASSWORD',
  PREVIOUSLY_USED: 'UNIQUE_PASSWORDS',
  TOO_SHORT: 'PASSWORD_LENGTH',
  NO_UNIQUE_CHARACTERS: 'PASSWORD_COMPLEXITY_GROUPS',
};

export const password_role_based_info_list = [
  VALIDATION_ERROR_TYPES.TOO_SHORT,
  VALIDATION_ERROR_TYPES.NO_UNIQUE_CHARACTERS,
  VALIDATION_ERROR_TYPES.PREVIOUSLY_USED,
];

export const password_other_info_list = [
  VALIDATION_ERROR_TYPES.CHANGED_TOO_OFTEN,
];

export const UserRoles = {
  UNLOGGED: 'UNLOGGED',
  VISITOR: 'VISITOR',
  AGENT: 'AGENT',
  BUSINESS_ADMIN: 'BUSINESS_ADMIN',
  ADMIN: 'ADMIN',
  DPI: 'DPI',
};

export const MAP_USER_ROLES = {
  UNLOGGED: 'UNLOGGED',
  VISITOR: 'Role: VISITOR',
  AGENT: 'Role: AGENT',
  BUSINESS_ADMIN: 'Business Administrator',
  ADMIN: 'Application Administrator',
  DPI: 'Role: DPI',
};

export const USER_ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const VALIDATION_USER_ERRORS_NAMES = {
  NAME: 'NAME',
  USERNAME: 'USERNAME',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  EMAIL: 'EMAIL',
  DEPARTMENT: 'DEPARTMENT',
  IS_ACTIVE: 'IS_ACTIVE',
  NOTIFY: 'NOTIFY',
  ROLE: 'ROLE',
};

export const MAP_VALIDATION_USER_ERRORS_NAMES = {
  NAME: 'name',
  USERNAME: 'username',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  DEPARTMENT: 'department',
  IS_ACTIVE: 'isActive',
  NOTIFY: 'notify',
  ROLE: 'role',
  DOCUMENT_NUMBER: 'documentNumber',
};

export enum USER_ORDER_BY {
  createdAt = 'CREATED_AT',
  username = 'USERNAME',
  firstName = 'FIRST_NAME',
  lastName = 'LAST_NAME',
  name = 'NAME',
  email = 'EMAIL',
  role = 'ROLE',
  createdBy = 'CREATED_BY',
  status = 'STATUS',
  phoneNumber = 'PHONE_NUMBER',
  isActive = 'IS_ACTIVE',
  documentNumber = 'DOCUMENT_NUMBER',
}

export enum USER_FILTER_FIELD_NAMES {
  USERNAME = 'username',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ID = 'id',
  CREATED_BY = 'createdBy',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  ROLE = 'role',
  CREATED_AT = 'createdAt',
  IS_ACTIVE = 'isActive',
  STATUS = 'status',
  DEPARTMENT = 'department',
}

export const MAP_USER_FIELD_NAMES = {
  USERNAME: 'Login',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone number',
  NAME: 'Name and surname',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  DEPARTMENT: 'Department',
};

export const MAP_USER_PLACEHOLDERS = {
  USERNAME: 'jan.kowalski',
  EMAIL: 'jan.kowalski@adres.com',
  NAME: 'Jan Kowalski',
  FIRST_NAME: 'Jan',
  LAST_NAME: 'Kowalski',
  DEPARTMENT: 'Accounting',
};

export enum PASSWORD_FORM_FIELD_NAMES {
  OLD_PASSWORD = 'oldPassword',
  PASSWORD = 'password',
  REPEAT_PASSWORD = 'repeatPassword',
}

export const PASSWORD_FORM_PLACEHOLDERS = {
  OLD_PASSWORD: 'Old password',
  PASSWORD: 'Password',
  REPEAT_PASSWORD: 'Repeat password',
};

export const mapVALIDATION_USER_FIELDS_INTO_ERRORS = {
  name: VALIDATION_USER_ERRORS_NAMES.NAME,
  username: VALIDATION_USER_ERRORS_NAMES.USERNAME,
  firstName: VALIDATION_USER_ERRORS_NAMES.FIRST_NAME,
  lastName: VALIDATION_USER_ERRORS_NAMES.LAST_NAME,
  phoneNumber: VALIDATION_USER_ERRORS_NAMES.PHONE_NUMBER,
  email: VALIDATION_USER_ERRORS_NAMES.EMAIL,
  department: VALIDATION_USER_ERRORS_NAMES.DEPARTMENT,
  isActive: VALIDATION_USER_ERRORS_NAMES.IS_ACTIVE,
  notify: VALIDATION_USER_ERRORS_NAMES.NOTIFY,
  role: VALIDATION_USER_ERRORS_NAMES.ROLE,
};

export const MAP_VALIDATION_USER_ERRORS = {
  NON_UNIQUE_USERNAME: 'Username already used',
  NON_UNIQUE_PHONE_NUMBER: 'Phone number already used',
  NON_UNIQUE_EMAIL: 'Email address already used',
  NON_UNIQUE_DOCUMENT_NUMBER: 'Document number already used',

  WHITESPACE_FORBIDDEN_USERNAME: 'Please remove white characters (e.g. spaces)',
  WHITESPACE_FORBIDDEN_FIRST_NAME:
    'Please remove white characters (e.g. spaces)',
  WHITESPACE_FORBIDDEN_LAST_NAME:
    'Please remove white characters (e.g. spaces)',
  WHITESPACE_FORBIDDEN_EMAIL: 'Please remove white characters (e.g. spaces)',
  WHITESPACE_FORBIDDEN_PHONE_NUMBER:
    'Please remove white characters (e.g. spaces)',
  WHITESPACE_FORBIDDEN_DOCUMENT_NUMBER:
    'Please remove white characters (e.g. spaces)',

  EMPTY_USERNAME: 'Please enter username',
  EMPTY_NAME: 'Please enter name and surname',
  EMPTY_FIRST_NAME: 'Please enter first name',
  EMPTY_LAST_NAME: 'Please enter last name',
  EMPTY_PHONE_NUMBER: 'Please enter phone number',
  EMPTY_EMAIL: 'Please enter e-mail',
  EMPTY_DOCUMENT_NUMBER: 'Please enter document number',
  EMPTY_IS_ACTIVE: 'Please choose activity',
  EMPTY_NOTIFY: 'Incorrect configuration, contact Customer Support',

  INVALID_DOCUMENT_NUMBER: 'Invalid document number',
  INVALID_EMAIL: 'Invalid e-mail',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  INVALID_PESEL: 'Invalid PESEL',
  INVALID_INDEX: 'Invalid index',
  INVALID_NATIONAL_ID: 'Invalid National ID',
  INVALID_RECIPIENT_ID: 'Invalid Recipient ID',

  NOT_FOUND_ID: 'Specified user does not exist',

  TOO_LONG_DOCUMENT_NUMBER: 'Maximum length exceeded2',
};

export const PASSWORD_FORM_MODES = {
  REGISTER: 'REGISTER',
  PASSWORD_SET: 'PASSWORD_SET',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  ADMINISTRATIVE_PASSWORD_CHANGE: 'ADMINISTRATIVE_PASSWORD_CHANGE',
};

export const SMS_ERROR_CODES = {
  NOT_GENERATED: 'NOT_GENERATED',
  EXPIRED: 'EXPIRED',
  EMPTY: 'EMPTY',
  INVALID: 'INVALID',
};

export const MAP_SMS_ERROR_CODES = {
  NOT_GENERATED: 'Error occurred, please try again later',
  EXPIRED: 'Code has expired',
  EMPTY: 'Code not provided, try again',
  INVALID: 'Invalid code, retry',
};

export enum ORDER_METHOD {
  ASC = 'ASCENDING',
  DESC = 'DESCENDING',
}

export enum DOCUMENT_ORDER_BY {
  publicationDate = 'PUBLICATION_DATE',
  validUntil = 'LEGAL_VALIDITY_FINISH_DATE',
  validSince = 'LEGAL_VALIDITY_START_DATE',
  category = 'CATEGORY',
  title = 'TITLE',
  documentPublicationStatus = 'PUBLICATION_STATUS',
  status = 'STATUS',
  publishedBy = 'PUBLISHED_BY',
  identity_documentNumber = 'DOCUMENT_NUMBER',
  identity_surname = 'SURNAME',
  identity_name = 'SURNAME',
  activeDelivery = 'ACTIVE_DELIVERY_TYPE',
}

export enum CATEGORY_STATUSES {
  SUCCESS = 'SUCCESS',
  ADDING_ERROR = 'ADDING_ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const VALIDATION_LOGIN_ERRORS = {
  FORBIDDEN: 'FORBIDDEN',
  ACCOUNT_INACTIVE: 'ACCOUNT_INACTIVE',
  ACCOUNT_TEMPORARY_BLOCKED: 'ACCOUNT_TEMPORARY_BLOCKED',
};

export const MAP_VALIDATION_LOGIN_ERRORS = {
  USER_NOT_ACTIVATED: 'Your account is not activated',
  FORBIDDEN: 'Incorrect login or password, please try again',
  ACCOUNT_INACTIVE:
    'Your account is inactive, please contact the administrator',
  ACCOUNT_TEMPORARY_BLOCKED: 'Account will be unlocked in {n} {unit}.',
};

export enum PUBLICATION_FIELD_NAMES {
  PUBLISHED_BY = 'publishedBy',
  TITLE = 'title',
  CATEGORY = 'category',
  VALID_SINCE = 'validSince',
  VALID_UNTIL = 'validUntil',
  PUBLIC_DOCUMENT_TYPE = 'publicDocumentType',
  IDENTITY = 'identity',
  FILE = 'file',
  DOCUMENT_FILE = 'documentFile',
  VERSION_NAME = 'versionName',
  RETAIN_UNTIL = 'retainUntil',
  SIGNING_ORDER = 'signingOrder',
  SIGNING_TYPE = 'signingType',
  ACTIVE_DELIVERY_TYPE = 'activeDeliveryType',
}

export enum DOCUMENT_FILTER_FIELD_NAMES {
  TITLE = 'title',
  CATEGORY = 'category',
  CATEGORY_ID = 'categoryId',
  PUBLICATION_DATE_RANGE = 'publicationDateRange',
  PUBLISHED_BY = 'publishedBy',
  VALID_SINCE_DATE_RANGE = 'validSinceDateRange',
  VALID_UNTIL_DATE_RANGE = 'validUntilDateRange',
  BLOCKCHAIN_ADDRESS = 'blockchainAddress',
  SIGNING_STATUS = 'signingStatus',
  DOCUMENT_STATUS_LIST = 'documentStatusList',
  TENANT = 'tenant',
  ACTIVE_DELIVERY_TYPE = 'activeDeliveryType',
  IDENTITY = 'identity',
  DOCUMENT_TYPE_LIST = 'documentTypeList',
}

export enum RECIPIENT_FILTER_FIELD_NAMES {
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT_NUMBER = 'documentNumber',
  CREATED_BY = 'createdBy',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  STATUS = 'status',
  CREATED_AT_DATE_RANGE = 'createdAtDateRange',
  IS_GDPR_SUSPENDED = 'isGdprSuspended',
  SHARED_SECRET = 'sharedSecret',
  IS_ACTIVE = 'isActive',
  PAYER_NUMBERS = 'payerNumbers',
  PPE_NUMBERS = 'ppeNumbers',
  PAYER_NUMBER = 'payerNumber',
  PPE_NUMBER = 'ppeNumber',
}

export const MAP_RECIPIENT_FIELD_NAMES = {
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone number',
  NAME: 'Name and surname',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  IS_ACTIVE: 'Is active?2',
  SHARED_SECRET_TYPE: 'Recipient Verification',
  PAYER_NUMBERS: 'Payer Number',
  PPE_NUMBERS: 'PPE Number',
};

export enum PUBLICATION_MODES_TITLE {
  CORRECTED = 'Publish erratum',
  UPDATED = 'Publish new version of document',
  NEW = 'Publish document',
  INCIDENT = 'Publish incident',
}

export const TAURON_VERIFICATION_CODE_STATUSES = {
  FOREIGN_NUMBERS_BLOCKED: 'Block shipping to international numbers',
  BLACKLISTED_PHONE_NUMBER: 'Blacklisted phone number',
  IN_PROCESS: 'Could not get message status for unspecified reason',
  NOT_PROCESSED:
    'Message with specified id already exists, has not been processed',
  PHONE_NUMBER_TOO_LONG: 'Phone number is too long',
  PHONE_NUMBER_TOO_SHORT: 'Phone number is too short',
  WRONG_OPERATOR_PREFIX: 'Operator prefix is invalid',
  WRONG_COUNTRY_PREFIX: 'Country prefix is invalid',
  WRONG_PHONE_NUMBER: 'There are invalid characters in the phone number',
  CONTENT_TOO_LONG: 'The content of the message is longer than 160 characters',
  FORBIDDEN_CHARACTERS: 'Message contains non-ASCII characters',
  IN_PROGRESS: 'Message has not been processed for an unspecified reason',
  SENDING_STARTED_SUCCESSFULLY:
    "Message waiting to be sent to operator's SMSC (>5min)",
  DELIVERED_TO_OPERATOR:
    "Message was delivered to operator's SMSC - confirmation of SMS receipt by operator has arrived",
  NOT_DELIVERED_TO_OPERATOR:
    "Message has not been delivered to the operator's SMSC",
  WAITING_TO_DELIVER:
    "Message is waiting in the operator's SMSC for delivery to the phone",
  DELIVERED: 'Message was delivered to the phone',
  NOT_DELIVERED: 'Message has not been delivered by the operator to the phone',
  EXPIRED:
    'Message has not been delivered to the operator due to timeout, e.g. because the phone was out of range for too long',
};

export const NOTIFICATION_TYPE = {
  SET_PASSWORD: 'SET_PASSWORD',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED ',
};

export const MANAGE_RECIPIENTS_MODES = {
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const DATA_PROCESSING_OPERATIONS = {
  RESUME: 'RESUME',
  SUSPEND: 'SUSPEND',
};

export const REPORT_TYPE = {
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

export const MAP_REPORT_TYPE = {
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
};

export const SIGNING_ORDER = {
  NO_SIGNATURE: 'NO_SIGNATURE',
  PUBLISHER_ONLY: 'PUBLISHER_ONLY',
  RECEIVER_ONLY: 'RECEIVER_ONLY',
  SENDER_FIRST: 'SENDER_FIRST',
};

export const MAP_SIGNING_ORDER = {
  NO_SIGNATURE: "Document doesn't require signing",
  PUBLISHER_ONLY: 'Document for signature - unilateral - sender only',
  RECEIVER_ONLY: 'Document for signature - unilateral - recipient only',
  SENDER_FIRST:
    'Document for signature - both parties - 1st - sender, 2nd - recipient',
};

export const SIGNING_TYPE = {
  STANDARD: 'STANDARD',
  ADVANCED: 'ADVANCED',
  QUALIFIED: 'QUALIFIED',
};

export const MAP_SIGNING_TYPE = {
  STANDARD: 'Simple Electronic Signature (SES)',
  ADVANCED: 'Advanced Signature (AES)',
  QUALIFIED: 'Qualified Signature (QES)',
};

export const ACTIVE_DELIVERY = {
  NONE: 'NONE',
  CONFIRMATION_OF_DELIVERY: 'CONFIRMATION_OF_DELIVERY',
  CONFIRMATION_OF_DELIVERY_EMAIL: 'CONFIRMATION_OF_DELIVERY_EMAIL',
  CONFIRMATION_OF_DELIVERY_SMS: 'CONFIRMATION_OF_DELIVERY_SMS',
  CONFIRMATION_OF_RECEIPT: 'CONFIRMATION_OF_RECEIPT',
  CONFIRMATION_OF_ACKNOWLEDGMENT: 'CONFIRMATION_OF_ACKNOWLEDGMENT',
};

export const MAP_ACTIVE_DELIVERY = {
  NONE: 'without confirmation',
  CONFIRMATION_OF_DELIVERY: 'confirmation of sending SMS and e-mail',
  CONFIRMATION_OF_DELIVERY_SMS: 'confirmation of sending SMS',
  CONFIRMATION_OF_DELIVERY_EMAIL: 'confirmation of sending e-mail',
  CONFIRMATION_OF_RECEIPT: 'confirmation of receipt',
  CONFIRMATION_OF_ACKNOWLEDGMENT: 'confirmation of acknowledgment',
};

export const NOTIFICATION_TEMPLATES = {
  PRIVATE_PUBLISHED: 'PRIVATE_PUBLISHED',
  AGREEMENT_READY_TO_SIGN: 'AGREEMENT_READY_TO_SIGN',
  AGREEMENT_PUBLISHED: 'AGREEMENT_PUBLISHED',
  AGREEMENT_REJECTED: 'AGREEMENT_REJECTED',
  PUBLIC_PUBLISHED: 'PUBLIC_PUBLISHED',
  MVP_PRIVATE_PUBLISHED: 'MVP_PRIVATE_PUBLISHED',
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const MAP_NOTIFICATION_TEMPLATES = {
  PRIVATE_PUBLISHED: 'Document without signature on the part of the Recipient',
  AGREEMENT_READY_TO_SIGN: 'Sent over to the Recipient for signing',
  AGREEMENT_PUBLISHED: 'Document signed by the Recipient',
  AGREEMENT_REJECTED: 'Document rejected by the Recipient',
  PUBLIC_PUBLISHED: 'Public document',
  MVP_PRIVATE_PUBLISHED: 'Private document',
  PRIVATE: 'Private document',
  PUBLIC: 'Public document',
};

export const IDENTITY_DOCUMENT_TYPE = {
  PESEL: 'PESEL',
  INDEX_NUMBER: 'INDEX_NUMBER',
  OTHER: 'OTHER',
  UNASSIGNED: 'UNASSIGNED',
  ID: 'ID',
  NIP: 'NIP',
  TEMPORARY_ID: 'TEMPORARY_ID',
  FOREIGN_ID: 'FOREIGN_ID',
};

export const MAP_IDENTITY_DOCUMENT_TYPE = {
  PESEL: 'PESEL',
  INDEX_NUMBER: 'Index number',
  OTHER: 'National ID',
  UNASSIGNED: 'Recipient ID',
  ID: 'ID',
  NIP: 'NIP',
  TEMPORARY_ID: 'Temporary ID',
  FOREIGN_ID: 'Foreign ID',
};

export const MAP_TAURON_IDENTITY_DOCUMENT_TYPE = {
  OTHER: 'Other',
};

export const MAP_TAURON_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL = {
  OTHER: 'Document type - other',
};

export const FEEDBACK_ACTIONS = {
  LINK_EXPIRED: 'LINK_EXPIRED',
};

export const REPORT_ROLE = {
  NO_ACCESS: 'NO_ACCESS',
  AGENT: 'AGENT',
  ADMIN: 'ADMIN',
};

export const MAP_REPORT_ROLE = {
  NO_ACCESS: 'Access denied',
  AGENT: 'Default',
  ADMIN: 'Administrator',
};

export const SHARED_SECRET_TYPE = {
  NONE: 'NONE',
  PESEL: 'PESEL',
  NIP: 'NIP',
  POSTAL_CODE: 'POSTAL_CODE',
};

export const SHARED_SECRET_FIELD_NAMES = {
  SHARED_SECRET_TYPE: 'sharedSecretType',
  SHARED_SECRET_VALUE: 'sharedSecretValue',
};

export const MAP_SHARED_SECRET_TYPE = {
  PESEL: 'PESEL',
  NIP: 'NIP',
  POSTAL_CODE: 'Postal Code',
};

export const MAP_RECIPIENT_PLACEHOLDERS = {
  PESEL: '88903332983',
  EMAIL: 'jan.kowalski@adres.com',
  NAME: 'Jan Kowalski',
  FIRST_NAME: 'Jan',
  LAST_NAME: 'Kowalski',
  PAYER_NUMBERS: '360623917670',
  PPE_NUMBERS: '247412365',
};

export const MAP_SHARED_SECRET_TYPE_PLACEHOLDER = {
  PESEL: '88903332983',
  NIP: '5879528373',
  POSTAL_CODE: '01-123',
};

export const MAP_SHARED_SECRET_TYPE_OPTIONS = {
  NONE: 'using only authorization code',
  PESEL: 'using the PESEL number and authorization code',
  NIP: 'using the NIP (taxpayer identification number) and authorization code',
  POSTAL_CODE: 'using postal code and authorization code',
};

export const MAP_SHARED_SECRET_VALIDATION_ERRORS = {
  PESEL: 'Invalid PESEL',
  NIP: 'Invalid NIP',
  POSTAL_CODE: 'Invalid Postal Code',
};

export const NOTIFICATION_MODULE_MENU_LABEL = {
  ARCHIVE: 'Archive',
  TEMPLATES: 'Available notification templates',
  CATEGORIES: 'Configure notifications for categories',
  EMAIL: 'Email notification',
  SMS: 'SMS notification',
  TEMPLATES_NONE: 'None notification template is yet defined.',
  TEMPLATES_FIRST: 'Create the first one!',
  TEMPLATE_ERROR:
    'Error while loading notification template, please contact your administrator',
  TEMPLATE_CONTENT_SUBJECT: 'Subject',
  TEMPLATE_CONTENT_PREVIEW: 'Current notification content',
  TEMPLATE_CREATE_BUTTON: 'Add new',
  TEMPLATE_CREATE_MODAL: 'New notification template',
  TEMPLATE_CREATE_NAME_FIELD: 'Enter template name',
  TEMPLATE_CREATE_NOTIFICATION_TYPE_FIELD: 'Enter notification type',
  TEMPLATE_CREATE_EMAIL_CONTENT_FIELD: 'Enter e-mail notification content',
  TEMPLATE_CREATE_SMS_CONTENT_FIELD: 'Enter SMS notification content',
  TEMPLATE_CREATE_SUCCESS: 'Notification template has been added!',
  TEMPLATE_CREATE_ERROR: 'Notification template adding error',
  TEMPLATE_NOTIFICATION_MANAGE: 'Manage',
  TEMPLATE_NOTIFICATION_HISTORY: 'Notification content history',
  TEMPLATE_NOTIFICATION_HISTORY_EMAIL: 'Email notification content history',
  TEMPLATE_NOTIFICATION_HISTORY_SMS: 'SMS notification content history',
  TEMPLATE_NOTIFICATION_HISTORY_ARCHIVE:
    'Archive - notification content history',
  CONTENTS_CONFIGURATION: 'Configure notification contents',
  CONTENT: 'Content',
  CONTENT_VALID_SINCE: 'Content valid since',
  CONTENT_VALID_UNTIL: 'Content valid until',
  CONTENT_RESTORE_VERSION: 'Restore version',
  CONTENT_MODIFY: 'Modify',
  TEMPLATE_ARCHIVE: 'Do archive',
  TEMPLATE_ARCHIVE_MODAL: 'Notification template archiving',
  TEMPLATE_ARCHIVE_SUCCESS: 'Notification template has been archived!',
  TEMPLATE_ARCHIVE_ERROR: 'Notification template archiving error',
  CONTENT_NEW: 'Set up new content',
  CONTENT_NEW_MODAL: 'Set up new notification content',
  CONTENT_NEW_SUCCESS: 'Notification content has been added!',
  CONTENT_NEW_ERROR: 'Notification content adding error',
  CONTENT_EDIT_MODAL: 'Modify notification content',
  CONTENT_EDIT_SUCCESS: 'Notification content has been modified!',
  CONTENT_EDIT_ERROR: 'Notification content modifing error',
  CONTENT_RESTORE_MODAL: 'Restore notification content',
  CONTENT_RESTORE_SUCCESS: 'Notification content has been restored!',
  CONTENT_RESTORE_ERROR: 'Notification content restoring error',
  TEMPLATE_CONTENTS_LOADING: 'Contents are loading...',
  TEMPLATES_LOADING: 'Templates are loading...',
};

export const NOTIFICATION_TEMPLATE_FIELD_NAMES = {
  NAME: 'name',
  NOTIFICATION_TYPE: 'notificationType',
  CONTENT_EMAIL_TEMPLATE: 'emailTemplate',
  CONTENT_SMS_TEMPLATE: 'smsTemplate',
  CONTENT_VALID_SINCE: 'validSince',
  TEMPLATE_ARCHIVED_SINCE: 'archivedSince',
};

export const NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS = {
  NAME: 'Annexes',
  CONTENT_EMAIL_TEMPLATE: 'Entering content...',
  CONTENT_SMS_TEMPLATE: 'Entering content...',
  CONTENT_VALID_SINCE: 'validSince',
  TEMPLATE_ARCHIVED_SINCE: 'archivedSince',
};

export const NOTIFICATION_TEMPLATE_TYPES = {
  PRIVATE_PUBLISHED: 'PRIVATE_PUBLISHED',
  MINIMAL_PRIVATE_PUBLISHED: 'MINIMAL_PRIVATE_PUBLISHED',
};

export const MAP_NOTIFICATION_TEMPLATE_TYPE_OPTIONS = {
  PRIVATE_PUBLISHED: 'default',
  MINIMAL_PRIVATE_PUBLISHED: 'customized (predefined footer only)',
};

export const NOTIFICATION_PREVIEW_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  SUBJECT: 'SUBJECT',
};

export const NOTIFICATION_CATEGORY_EDIT_TYPES = {
  EMAIL_TITLE: 'EMAIL_TITLE',
  GROUP_NAME: 'GROUP_NAME',
};

export const NOTIFICATION_FIELD_ERRORS = {
  FORBIDDEN_TAG: 'Forbidden tag: {TAG}',
  REQUIRED_TAG: '*Required tag: {TAG}',
  NOT_UNIQUE: 'The template name must be unique',
  NAME_EMPTY: 'The template name must contain alphanumeric characters',
  TITLE_EMPTY: 'The title must contain alphanumeric characters',
  SMS_EMPTY:
    'The SMS notification content must contain alphanumeric characters',
  TITLE_REQUIRED: 'Title required',
};

export const NOTIFICATION_CONTENT_TAGS = {
  LINK: '{{LINK}}',
  BLC_ADD: '{{BLC_ADD}}',
  PPE_NUMBER: '{{PPE_NUMBER}}',
  USER_INPUT: '{{USER_INPUT}}',
};

export const PUBLIC_DOCUMENT_TYPES = {
  TARIFF: 'TARIFF',
  PUBLIC: 'PUBLIC',
};

export const MAP_PUBLIC_DOCUMENT_TYPES = {
  TARIFF: 'Tariff',
  PUBLIC: 'Default document',
};

export const PUBLICATION_NOTIFICATION_FIELD_NAMES = {
  EMAIL_SUBJECT_INPUT: 'emailSubjectInput',
  EMAIL_CONTENT_INPUT: 'emailContentInput',
  SMS_CONTENT_INPUT: 'smsContentInput',
  EMAIL_SUBJECT_USER_INPUT_TAG_FIELD: 'emailSubjectUserInputTagField',
  EMAIL_CONTENT_USER_INPUT_TAG_FIELD: 'emailContenttUserInputTagField',
  SMS_CONTENT_USER_INPUT_TAG_FIELD: 'smsContenttUserInputTagField',
};

export const BLUR_IDENTITY_MOCK_VALUES = {
  FIRST_NAME: 'Blur',
  LAST_NAME: 'Kowalski',
  NAME: 'Blur Kowalski',
  DOCUMENT_NUMBER: '0000000000',
  PHONE_NUMBER: '+00 000000000',
  EMAIL: 'blur.kowalski@billongroup.com',
};
