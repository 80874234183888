import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { UserRoles, DOCUMENT_TYPE } from '../../../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../../../Config/hooks/useConfig';

// helpers
import { getSigningFlags } from '../../../../../../helpers/getSigningFlags';

// Components
import { ProtectedComponent } from '../../../../../../../Auth/components';
import { BtnCol } from '../../../../../../../../components/LoadingActionButton/styled';
import CustomToolTip from '../../../../../../../../ui/CustomToolTip';
import { LoadingActionButton } from '../../../../../../../../components';

const ResendNotification = ({
  handleResendNotificationClick,
  resendAuthorizationCode,
  documentType,
  isResendingAuthorizationCode,
  suspensionMessageId,
  signingStatus,
  signingOrder,
  status,
  inActive,
}) => {
  const { enableSigning, isTauron, isOpenPlatform } = useConfigContext();

  const { isResendNotificationEnabled } = getSigningFlags(
    enableSigning,
    signingStatus,
    signingOrder,
    status,
  );
  const isDocumentTypePublic = documentType === DOCUMENT_TYPE.PUBLIC;
  const enableResendAuthorizationCode = isDocumentTypePublic && isTauron;
  const enableResendNotification =
    !isOpenPlatform &&
    ((!enableSigning && documentType === DOCUMENT_TYPE.PRIVATE) ||
      (!isTauron && isResendNotificationEnabled));

  const handleResendAuthorizationCode = () => {
    resendAuthorizationCode({ data: { userInput: {} } });
  };

  const handleClick = () => {
    if (!inActive) {
      if (enableResendAuthorizationCode) {
        handleResendAuthorizationCode();
      } else {
        handleResendNotificationClick();
      }
    }
  };

  const Action = () => (
    <LoadingActionButton
      outline
      isLoading={isResendingAuthorizationCode}
      inActive={inActive}
      onClick={handleClick}
    >
      <FormattedMessage
        id="Resend notification"
        defaultMessage="Resend notification"
      />
    </LoadingActionButton>
  );

  const ResendNotificationTooltip = () => (
    <CustomToolTip
      id="resend-notification-Tooltip"
      trigger={<Action />}
      delay={false}
      placement="bottom-start"
    >
      <h4>
        <FormattedMessage
          id="Resend notification"
          defaultMessage="Resend notification"
        />
      </h4>
      <FormattedMessage
        id={suspensionMessageId}
        defaultMessage={suspensionMessageId}
      />
    </CustomToolTip>
  );

  if (enableResendNotification)
    return (
      <ProtectedComponent
        roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
        render={false}
      >
        <BtnCol oneThirdSplit>
          {inActive ? <ResendNotificationTooltip /> : <Action />}
        </BtnCol>
      </ProtectedComponent>
    );

  return null;
};

export default ResendNotification;
