import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

// constants
import {
  NOTIFICATION_MODULE_MENU_LABEL,
  NOTIFICATION_TEMPLATE_FIELD_NAMES,
} from '../../../../../../../../constraints';

// hooks
import { useArchivePrivateNotificationTemplateGroup } from '../../../../../../hooks/privateNotifications/useArchivePrivateNotificationTemplateGroup';
import { useSchema } from './useSchema';

// helpers
import { parseArchiveTemplateData } from '../../../../../../helpers/parseArchiveTemplateData';

// Components

import { FormFooter } from '../../../../../../../../components/FormFooter';
import { ControlledCalendarField } from '../../../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import FieldTitle from '../../../CreateTemplateForm/components/FieldTitle';
import { Form as FormModule, Grid } from 'billon-ui';

const { FormGroup } = FormModule;
const { Row, Col } = Grid;

const CreateContentForm = ({
  submitButtonText,
  handleSuccess,
  handleError,
  groupId,
}) => {
  const schema = useSchema();

  const minDate = new Date(Date.now() + 1 * 60000).setSeconds(0, 0);
  const startDate = new Date(Date.now() + 10 * 60000).setSeconds(0, 0);

  const formMethods = useForm({
    defaultValues: {
      [NOTIFICATION_TEMPLATE_FIELD_NAMES.TEMPLATE_ARCHIVED_SINCE]: startDate,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, resetField } = formMethods;

  const { mutate: archive, isLoading } =
    useArchivePrivateNotificationTemplateGroup(groupId, {
      onSuccess: () => {
        handleSuccess();
      },
      onError: ({ response }) => {
        const { statusCode } = response?.data;
        handleError(statusCode);
      },
    });

  const handleFormSubmit = (values) => {
    archive({
      data: parseArchiveTemplateData(values),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row
          xl={12}
          style={{ justifyContent: 'center' }}
          padding={'0 16px 0 16px'}
        >
          <Col xl={6}>
            <FormGroup>
              <FieldTitle as="h2">
                <FormattedMessage
                  id={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_UNTIL}
                  defaultMessage={
                    NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_UNTIL
                  }
                />
              </FieldTitle>
              <ControlledCalendarField
                name={NOTIFICATION_TEMPLATE_FIELD_NAMES.TEMPLATE_ARCHIVED_SINCE}
                enableTime={true}
                minDate={minDate}
                displayTime={true}
                showMonths={2}
                onClear={(name) => resetField(name)}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormFooter
          backBtnDisabled
          isLoading={isLoading}
          submitButtonText={submitButtonText}
        />
      </form>
    </FormProvider>
  );
};

export default CreateContentForm;
