import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { merge } from 'lodash';

// constants
import {
  DOCUMENT_TYPE,
  DOCUMENT_FILTER_FIELD_NAMES,
} from '../../../../constraints';

// helpers
import { parseFilters } from '../../../../modules/Documents/helpers';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';

// Hooks
import useFilters from '../../../../hooks/useFilters';
import useDocumentType from '../../hooks/useDocumentType';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useTenantsContext } from '../../../Tenants/hooks/useTenants';
import { useDocumentFormDefaultValues } from './hooks/useDocumentFormDefaultValues';

// Components
import { SelectActiveDeliveryField } from '../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';
import {
  CalendarPublicationField,
  CalendarValidSinceField,
  CalendarValidUntilField,
  SelectTenantField,
  SelectIdentityField,
  SelectIdentityNameField,
  SelectStatusField,
  SelectCategoryField,
  TextTitleField,
  TextPublishedByField,
  TextBlockchainAddressField,
} from './components';
import {
  FormHeader,
  FormActions,
  FilterCard,
  FlexStartRow,
} from '../../../../components/Form';

import { Grid } from 'billon-ui';

const { Col } = Grid;

export const FilterForm = ({ closeFilters, defaultFilters }) => {
  const { documentType } = useDocumentType();
  const { isTauron, isDiploma, publicationFormConfig, enableActiveDelivery } =
    useConfigContext();
  const { areTenantsAvailable } = useTenantsContext();
  const { navigateWithNewSearchQuery, onClear, onSingleFilterClear } =
    useFilters();
  const isDocumentPrivate = documentType === DOCUMENT_TYPE.PRIVATE;
  const { formInitialValues, computedDefaultValues, asyncFieldsValues } =
    useDocumentFormDefaultValues(defaultFilters);
  const formMethods = useForm({
    defaultValues: computedDefaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    resetField,
  } = formMethods;

  const onClearFilters = () => {
    reset(formInitialValues);
    onClear({ filters: defaultFilters?.filters });
  };
  const onSingleFieldClear = (name) => {
    resetField(name);
    onSingleFilterClear(name, defaultFilters?.filters);
  };
  const onFieldClear = (name) => onSingleFieldClear(name);
  const onSubmit = (values) => {
    navigateWithNewSearchQuery({
      filters: merge(
        defaultFilters.filters,
        removeUndefinedValues(parseFilters(values)),
      ),
      pagination: {
        page: 1,
      },
    });
  };

  useEffect(() => {
    reset();
  }, [documentType, reset]);

  return (
    <FilterCard>
      <FormHeader closeFilters={closeFilters} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexStartRow>
            <Col lg={6} xl={3}>
              <TextTitleField name={DOCUMENT_FILTER_FIELD_NAMES.TITLE} />
            </Col>
            {publicationFormConfig?.isCategoryFieldEnabled && (
              <Col lg={6} xl={3}>
                <SelectCategoryField
                  name={DOCUMENT_FILTER_FIELD_NAMES.CATEGORY}
                  isClearable
                  onClear={onFieldClear}
                  categoryId={asyncFieldsValues?.categoryId}
                />
              </Col>
            )}
            <Col lg={6} xl={3}>
              <CalendarPublicationField
                name={DOCUMENT_FILTER_FIELD_NAMES.PUBLICATION_DATE_RANGE}
                onClear={onFieldClear}
              />
            </Col>
            {publicationFormConfig?.isPublishedByFieldEnabled && (
              <Col lg={6} xl={3}>
                <TextPublishedByField
                  name={DOCUMENT_FILTER_FIELD_NAMES.PUBLISHED_BY}
                />
              </Col>
            )}
            <Col lg={6} xl={3}>
              <CalendarValidSinceField
                name={DOCUMENT_FILTER_FIELD_NAMES.VALID_SINCE_DATE_RANGE}
                onClear={onFieldClear}
              />
            </Col>
            {!isDiploma && (
              <Col lg={6} xl={3}>
                <CalendarValidUntilField
                  name={DOCUMENT_FILTER_FIELD_NAMES.VALID_UNTIL_DATE_RANGE}
                  onClear={onFieldClear}
                />
              </Col>
            )}
            <Col lg={6} xl={3}>
              <TextBlockchainAddressField
                name={DOCUMENT_FILTER_FIELD_NAMES.BLOCKCHAIN_ADDRESS}
              />
            </Col>
            <Col lg={6} xl={3}>
              <SelectStatusField isClearable onClear={onFieldClear} />
            </Col>
            {isDocumentPrivate && (
              <Col lg={6} xl={3}>
                <SelectIdentityField
                  name={DOCUMENT_FILTER_FIELD_NAMES.IDENTITY}
                  isClearable
                  onClear={onFieldClear}
                  identityDocumentNumber={
                    asyncFieldsValues?.identityDocumentNumber
                  }
                />
              </Col>
            )}
            {isTauron && isDocumentPrivate && (
              <Col lg={6} xl={3}>
                <SelectIdentityNameField
                  name={DOCUMENT_FILTER_FIELD_NAMES.IDENTITY}
                  isClearable
                  onClear={onFieldClear}
                  identityName={asyncFieldsValues?.identityName}
                />
              </Col>
            )}
            {areTenantsAvailable && (
              <Col lg={6} xl={3}>
                <SelectTenantField
                  name={DOCUMENT_FILTER_FIELD_NAMES.TENANT}
                  isClearable
                  onClear={onFieldClear}
                  tenantId={asyncFieldsValues?.tenantId}
                />
              </Col>
            )}
            {enableActiveDelivery && (
              <Col lg={6} xl={3}>
                <SelectActiveDeliveryField
                  name={DOCUMENT_FILTER_FIELD_NAMES.ACTIVE_DELIVERY_TYPE}
                  isClearable
                  onClear={onFieldClear}
                />
              </Col>
            )}
          </FlexStartRow>
          <FormActions
            onClearFilters={onClearFilters}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </FilterCard>
  );
};
