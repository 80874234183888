// hooks
import { useConfigContext } from '../../../../Config/hooks/useConfig';
import { useSettingsContext } from '../../../../Settings/hooks/useSettings';
import { useTokenParamContext } from '../../../../Auth/hooks/useTokenParam';
import useMeRequest from '../../../../Settings/hooks/useMeRequest';

// constants
import { REPORT_ROLE } from '../../../../../constraints';
import { UserRoles } from '../../../../../userRoles';

export const useFormUserRoleInfo = ({ deliveredRole }) => {
  const { token } = useTokenParamContext();
  const { isOpenPlatform } = useConfigContext();
  const { role: contextRole } = useSettingsContext();
  const { data: me, isLoading } = useMeRequest(
    { enabled: !!token, staleTime: Infinity },
    token,
  );
  const { reportRole } = me || {};

  const filteredDeliveredRole = isOpenPlatform ? 'USER' : deliveredRole;
  const role = filteredDeliveredRole ? filteredDeliveredRole : contextRole;

  const roleKeyPrefix =
    [UserRoles.ADMIN].includes(role) || [REPORT_ROLE.ADMIN].includes(reportRole)
      ? UserRoles.ADMIN
      : 'USER';

  return { roleKeyPrefix, role, reportRole, isLoading };
};
