import React from 'react';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import styled from 'styled-components';

import { Form as FormModule } from 'billon-ui';
const { FormControl } = FormModule;

const Wrapper = styled.div`
  .intl-tel-input {
    display: block;

    .country-list {
      z-index: 6;
    }
  }
`;

const PhoneNumberField = ({
  input,
  preferredCountries,
  disabled,
  autoFocus,
  autoHideDialCode,
  meta: { touched, active, dirty, error, warning },
  ...rest
}) => (
  <FormControl
    active={active}
    touched={touched}
    dirty={dirty || !!input.value}
    error={error}
    warning={warning}
    {...rest}
  >
    <Wrapper>
      <IntlTelInput
        value={input.value}
        onPhoneNumberChange={(status, v) => input.onChange(v)}
        onPhoneNumberBlur={(status, v) => input.onBlur(v)}
        preferredCountries={preferredCountries}
        inputClassName="form-control"
        disabled={disabled}
        autoFocus={autoFocus}
        nationalMode={false}
        autoHideDialCode={autoHideDialCode}
      />
    </Wrapper>
  </FormControl>
);

PhoneNumberField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  preferredCountries: PropTypes.array,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

PhoneNumberField.defaultProps = {
  preferredCountries: ['pl'],
  autoFocus: false,
};

export default PhoneNumberField;
