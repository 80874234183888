import React from 'react';
import { Utils } from 'billon-ui';
import { useHistory } from 'react-router-dom';

import { useDownloadDailyReport } from '../../hooks';

import appRoutes from '../../../../app-routes';

import * as Styled from './styled';

const { Icon, Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const PreviewDailyReportButton = ({ record, isPreview = false }) => {
  const history = useHistory();

  const { mutate: downloadFile, isLoading } = useDownloadDailyReport(
    record.jobId,
    record.title,
    isPreview,
  );

  const handleDownloadFileClick = (e) => {
    e.stopPropagation();
    if (isPreview) {
      history.push(
        appRoutes.DOCUMENT_PREVIEW.replace(
          ':id',
          record?.jobId || record?.documentBlockchainAddress,
        ),
      );
    } else {
      downloadFile();
    }
  };

  if (isLoading) {
    return (
      <Styled.LoadingButton color="link" className="ignore-row-click">
        <Loader width={36} />
      </Styled.LoadingButton>
    );
  }

  return (
    <Styled.PrevButton
      color="link"
      className="ignore-row-click"
      onClick={handleDownloadFileClick}
    >
      <Icon billon={!isPreview} name={isPreview ? 'eye' : 'pdf'} />
    </Styled.PrevButton>
  );
};

export default PreviewDailyReportButton;
