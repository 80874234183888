export const parseRegisterData = ({
  username,
  email,
  firstName,
  lastName,
  phoneNumber,
  password,
  repeatPassword,
  isActive,
  department,
}) => ({
  username: username?.trim(),
  email: email?.trim(),
  firstName: firstName.trim(),
  lastName: lastName.trim(),
  phoneNumber,
  password,
  repeatPassword,
  isActive,
  department,
});
