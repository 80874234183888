import React, { useMemo, useState } from 'react';
import { useCompileNotificationMessages } from '../../hooks/useCompileNotificationMessages';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// constants
import { NOTIFICATION_CONTENT_TAGS } from '../../../../constraints';

// helpers
import { getNotificationTemplateType } from '../../helpers/getNotificationTemplateType';

// Components
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { RenderedNotificationContainer } from '../RenderedNotificationContainer/RenderedNotificationContainer';
import { Utils, Modal as ModalModule } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { IconButton } = ButtonModule;
const { Modal } = ModalModule;

export const SummaryNotifications = ({
  emailSubjectInput = '',
  smsContentInput = '',
  emailContentInput = '',
  emailSubjectUserInputTagField = '',
  emailContenttUserInputTagField = '',
  smsContenttUserInputTagField = '',
  documentType,
  tenantId,
  signingOrder,
  publicDocumentType,
  categoryBasedNotificationTemplateType,
}) => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);

  const { enableSigning, notificationsConfig } = useConfigContext();
  const { enableSms } = notificationsConfig;

  const notificationTemplateType = useMemo(() => {
    return getNotificationTemplateType(
      documentType,
      enableSigning,
      signingOrder,
      categoryBasedNotificationTemplateType,
    );
  }, [signingOrder, enableSigning, documentType]);

  const {
    emailSubjectPreviewValue,
    emailPreviewValue,
    smsContentPreviewValue,
    isLoading,
    error,
  } = useCompileNotificationMessages({
    emailSubjectInput: !!emailSubjectInput
      ? emailSubjectInput?.replace(
          NOTIFICATION_CONTENT_TAGS.USER_INPUT,
          emailSubjectUserInputTagField,
        )
      : emailSubjectUserInputTagField,
    emailContentInput: !!emailContentInput
      ? emailContentInput?.replace(
          NOTIFICATION_CONTENT_TAGS.USER_INPUT,
          emailContenttUserInputTagField,
        )
      : emailContenttUserInputTagField,
    smsContentInput: smsContentInput
      ? smsContentInput?.replace(
          NOTIFICATION_CONTENT_TAGS.USER_INPUT,
          smsContenttUserInputTagField,
        )
      : smsContenttUserInputTagField,
    documentType: notificationTemplateType,
    tenantId,
  });

  const handleToggleEmailModal = () => {
    setIsEmailModalOpen(!isEmailModalOpen);
  };

  const handleToggleSmsModal = () => {
    setIsSmsModalOpen(!isSmsModalOpen);
  };

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <Styled.SummaryContainer>
        <Styled.IconButtonWrapper>
          <IconButton
            icon="envelope"
            iconSize="2x"
            onClick={handleToggleEmailModal}
          />
        </Styled.IconButtonWrapper>
        {enableSms && (
          <Styled.IconButtonWrapper>
            <IconButton
              icon="comment-alt"
              iconSize="2x"
              onClick={handleToggleSmsModal}
            />
          </Styled.IconButtonWrapper>
        )}
      </Styled.SummaryContainer>
      <Modal
        toggle={handleToggleEmailModal}
        isOpen={isEmailModalOpen}
        title={
          <FormattedMessage
            id="Email notification"
            defaultMessage="Email notification"
          />
        }
      >
        {!!emailSubjectPreviewValue && (
          <Styled.EmailSubject>
            <Styled.EmailSubjectTag>
              <FormattedMessage id="Subject" defaultMessage="Subject" />
            </Styled.EmailSubjectTag>
            {`: ${emailSubjectPreviewValue}`}
          </Styled.EmailSubject>
        )}
        <RenderedNotificationContainer
          styles={{ minHeight: '10rem' }}
          dangerouslySetInnerHTML={{
            __html: emailPreviewValue,
          }}
        />
      </Modal>
      {enableSms && (
        <Modal
          toggle={handleToggleSmsModal}
          isOpen={isSmsModalOpen}
          title={
            <FormattedMessage
              id="SMS notification"
              defaultMessage="SMS notification"
            />
          }
        >
          <RenderedNotificationContainer>
            {smsContentPreviewValue}
          </RenderedNotificationContainer>
        </Modal>
      )}
    </>
  );
};
