import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import ContentContainerGrey from '../../../../ui/ContentContainerGrey';
import { Content } from '../../../../components/Content/Content';
import * as Styled from './styled';

export const PublicationFormWrapper = ({ pageHeaderId, children }) => {
  return (
    <ContentContainerGrey fluid>
      <Content fluid>
        <Styled.Card>
          <Styled.PageTitle>
            <FormattedMessage id={pageHeaderId} defaultMessage={pageHeaderId} />
          </Styled.PageTitle>
          {children}
        </Styled.Card>
      </Content>
    </ContentContainerGrey>
  );
};
