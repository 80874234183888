import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// constants
import appRoutes from '../../../../app-routes';
import { SETTINGS_CONSTANTS, MAP_USER_ROLES } from '../../../../constraints';

// helpers
import { getExpirationDays } from '../../heplers';

// hooks
import { useSettingsContext } from '../../../Settings/hooks/useSettings';

// Components
import * as Styled from './styled';
import MeDataTab from './MeDataTab';
import WithValueMessage from '../../../../helpers/WithValueMessage';
import { Utils } from 'billon-ui';

const { Text } = Utils;

const MeDetails = ({ me, publisherName }) => {
  const intl = useIntl();
  const { getSettingValueByKey } = useSettingsContext();

  const passwordExpirationTimeInDays = getSettingValueByKey(
    SETTINGS_CONSTANTS.PASSWORD_EXPIRATION_TIME,
  );

  const daysToExpiration = getExpirationDays(
    me.passwordUpdatedAt,
    passwordExpirationTimeInDays,
  );

  const { control } = useForm({
    defaultValues: {
      ...me,
      role: me?.role && intl.formatMessage({ id: MAP_USER_ROLES[me.role] }),
      publisherName,
    },
  });

  return (
    <Styled.Card>
      <Text
        fontSize={'100px'}
        textAlign={'center'}
        margin={'0 0 -15px 0'}
        padding={0}
      >
        <Styled.ImageIcon name="user-circle" />
      </Text>

      <Text fontSize={'12px'} textAlign={'center'} margin={'0 0 28px 0'}>
        <WithValueMessage
          messageId={'Password expires in {n} days'}
          name="n"
          value={daysToExpiration}
        />
      </Text>

      <MeDataTab control={control} />

      <Text fontSize={'12px'} textAlign={'center'} margin={'28px 0 28px 0'}>
        <FormattedMessage id="Got a problem?" defaultMessage="Got a problem?" />{' '}
        <Link to={appRoutes.SUPPORT}>
          <FormattedMessage
            id="Contact the support team"
            defaultMessage="Contact the support team"
          />
        </Link>
      </Text>
    </Styled.Card>
  );
};

MeDetails.propTypes = {
  me: PropTypes.object,
};

export default MeDetails;
