import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  NOTIFICATION_MODULE_MENU_LABEL,
  DOCUMENT_TYPE,
} from '../../../../../../constraints';
import useDocumentType from '../../../../hooks/useDocumentType';

// Components
import CardHeader from '../../../CardHeader';
import CreateTemplateButton from '../CreateTemplateForm/components/CreateTemplateButton';
import * as Styled from './styled';
import { Grid } from 'billon-ui';

const { Col } = Grid;

const HeaderWithGroupCreation = ({ primaryUrl, archiveUrl }) => {
  const { documentType } = useDocumentType();
  return (
    <Col md={12}>
      <Styled.SpacedBetweenCenteredVericallyRow md={12}>
        <Col md={8}>
          <CardHeader primaryUrl={primaryUrl} archiveUrl={archiveUrl}>
            <FormattedMessage
              id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES}
              defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES}
            />
          </CardHeader>
        </Col>
        {documentType === DOCUMENT_TYPE.PRIVATE && <CreateTemplateButton />}
      </Styled.SpacedBetweenCenteredVericallyRow>
    </Col>
  );
};

export default HeaderWithGroupCreation;
