import React, { createContext, useContext } from 'react';

import config from '../../../config';
import { CUSTOMER } from '../../../constraints';
import { notificationTheme } from '../constants/notificationTheme';

const ConfigContext = createContext(false);

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigContextProvider = ({ children }) => {
  const {
    customer,
    enablePublic,
    enablePrivate,
    enableSigning,
    enableActiveDelivery,
    enablePublicationDelay,
    enableReports,
    enableSharedSecret,
    enableNotificationManager,
    isSharedSecretOptional,
    mainPanelUrl,
    readingPanelUrl,
    enableTdLogo,
  } = config;

  const isDiploma = customer === CUSTOMER.DIPLOMA;
  const isTauron = customer === CUSTOMER.TAURON;
  const isDefault = customer === CUSTOMER.DEFAULT;
  const isOpenPlatform = customer === CUSTOMER.OPEN_PLATFORM;

  const publicationFormConfig = {
    enableRetention: !isTauron && !isDiploma,
    isValidUntilRequired: isDefault,
    displayTime: !isTauron && !isDiploma,
    enableTenants: isTauron,
    isCategoryFieldEnabled: !isOpenPlatform,
    isPublishedByFieldEnabled: !isOpenPlatform,
    isNotificationEnabled: !isOpenPlatform,
    enableDocumentAdvancedFeatures: !isOpenPlatform,
  };

  const usersConfig = {
    enableAdministrativePasswordChange: isTauron,
    enableTwoStepPassword: !isTauron && !isOpenPlatform,
    isPasswordInfoNested: !isDiploma,
  };

  const recipientsConfig = {
    enableIdentityDocumentTypeSelection: !isDiploma,
    enableSharedSecret: enableSharedSecret,
    isSharedSecretOptional: enableSharedSecret && isSharedSecretOptional,
    isSharedSecretRequired: enableSharedSecret && !isSharedSecretOptional,
    isFullName: isTauron,
    enableDocumentNumberBlur: isDefault,
    enablePpeNumbers: isTauron,
    isPpeNumberOptional: isTauron,
    enablePayerNumbers: isTauron,
    isPayerNumberOptional: isTauron,
    enableSourceSystemListDownload: !isOpenPlatform,
  };

  const notificationsConfig = {
    enablePrivateTemplateDefault: isTauron || isDiploma,
    enablePublicDocumentType: isTauron,
    enableSms: !isOpenPlatform,
  };

  const panelsNavigation = {
    mainPanelUrl,
    readingPanelUrl,
  };

  const customerFeatures = {
    enableTdLogo,
  };

  return (
    <ConfigContext.Provider
      value={{
        customer,
        enablePublic,
        enablePrivate,
        enableSigning,
        enableActiveDelivery,
        enablePublicationDelay,
        enableReports,
        enableNotificationManager,
        config,
        isDiploma,
        isTauron,
        isDefault,
        isOpenPlatform,
        publicationFormConfig,
        usersConfig,
        notificationTheme,
        recipientsConfig,
        notificationsConfig,
        panelsNavigation,
        customerFeatures,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
