import React from 'react';
import { FormattedMessage } from 'react-intl';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// constants
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  MAP_RECIPIENT_PLACEHOLDERS,
  MAP_RECIPIENT_FIELD_NAMES,
} from '../../../../../../constraints';

// components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { ControlledPhoneNumberField } from '../../../../../../components/ControlledPhoneNumberField';
import {
  SelectDocumentNumberField,
  TextNamesField,
  SelectSharedSecretField,
  PayerNumbersField,
  PpeNumbersField,
  SwitchIsActiveField,
} from '..';

const DataTab = () => {
  const { recipientsConfig } = useConfigContext();
  const { enableSharedSecret, enablePpeNumbers, enablePayerNumbers } =
    recipientsConfig || {};
  return (
    <>
      <ControlledTextField
        name={RECIPIENT_FILTER_FIELD_NAMES.EMAIL}
        label={
          <FormattedMessage
            id={MAP_RECIPIENT_FIELD_NAMES.EMAIL}
            defaultMessage={MAP_RECIPIENT_FIELD_NAMES.EMAIL}
          />
        }
        placeholder={MAP_RECIPIENT_PLACEHOLDERS.EMAIL}
      />
      <ControlledPhoneNumberField
        name={RECIPIENT_FILTER_FIELD_NAMES.PHONE_NUMBER}
        label={
          <FormattedMessage
            id={MAP_RECIPIENT_FIELD_NAMES.PHONE_NUMBER}
            defaultMessage={MAP_RECIPIENT_FIELD_NAMES.PHONE_NUMBER}
          />
        }
        autoHideDialCode={false}
      />
      <SelectDocumentNumberField />
      <TextNamesField />
      {enableSharedSecret && <SelectSharedSecretField />}
      {enablePayerNumbers && <PayerNumbersField />}
      {enablePpeNumbers && <PpeNumbersField />}
      <SwitchIsActiveField />
    </>
  );
};

export default DataTab;
