import React, { useState } from 'react';

// hooks
import { useUpdateDocument } from '../../hooks/useUpdateDocument';

// constants
import {
  mapDOCUMENT_ACTIONS_ERROR,
  mapDOCUMENT_ACTIONS_SUCCESS,
  UNKNOWN_ERRORS,
} from '../../../../constraints';

// helpers
import { parsePublishRequestData } from '../../helpers';
import { decalculateRetainUntil } from '../../helpers';

// utils
import { signingOrderOptions } from '../../../../components/SelectSigningOrderField/SelectSigningOrderField';
import { activeDeliveryOptions } from '../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';
import { publicDocumentTypeOptions } from '../../../../components/SelectPublicDocumentTypeField/SelectPublicDocumentTypeField';

// components
import { DocumentAtrributesForm } from '../PublishDocumentModal/components';
import { NotificationContentForm } from '../PublishDocumentModal/components/NotificationContentForm/NotificationContentForm';
import { DocumentPublicationSummary } from '../DocumentPublicationSummary';

const UpdateForm = ({
  initialValues,
  mode,
  onSuccess: handleSuccess,
  onError: handleError,
}) => {
  const [step, setStep] = useState(1);

  const {
    tenant,
    documentType,
    documentBlockchainAddress,
    signingOrder: rawSigningOrder,
    retainUntil: rawRetainUntil,
    publicationStartDate,
    activeDelivery: rawActiveDelivery,
    publicDocumentType: rawPublicDocumentType,
    notificationType,
    ...restDocInfo
  } = initialValues || {};
  const tenantId = tenant?.id;

  const signingOrder =
    rawSigningOrder &&
    signingOrderOptions?.find(({ value }) => rawSigningOrder === value);

  const retainUntil = decalculateRetainUntil(
    publicationStartDate,
    rawRetainUntil,
  );

  const activeDeliveryType =
    rawActiveDelivery?.type &&
    activeDeliveryOptions?.find(
      ({ value }) => rawActiveDelivery?.type === value,
    );

  const publicDocumentType =
    rawPublicDocumentType &&
    publicDocumentTypeOptions?.find(
      ({ value }) => rawPublicDocumentType === value,
    );

  const [formData, setFormData] = useState({
    documentType,
    documentBlockchainAddress,
    tenantId,
    signingOrder,
    publicationStartDate,
    retainUntil,
    activeDeliveryType,
    publicDocumentType,
    ...restDocInfo,
  });

  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const { mutate: updateDocument, isLoading: isSaving } = useUpdateDocument(
    documentType,
    {
      onSuccess: () => {
        handleSuccess(mapDOCUMENT_ACTIONS_SUCCESS.UPDATE);
      },
      onError: ({ response }) => {
        const { statusCode } = response?.data;
        handleError(
          mapDOCUMENT_ACTIONS_ERROR.UPDATE,
          statusCode ? statusCode : UNKNOWN_ERRORS.UNKNOWN_ERROR,
        );
      },
    },
  );

  const handleFormSubmit = (values) => {
    updateDocument({
      data: parsePublishRequestData(
        values,
        mode,
        documentBlockchainAddress,
        rawRetainUntil,
      ),
    });
  };

  const handleSubmitStep = (stepData) => {
    handleAppendFormData(stepData);

    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <>
      {step === 1 && (
        <DocumentAtrributesForm
          step={2} // first step allows to add identity and category
          document={formData}
          publicationMode={mode}
          onSubmit={handleSubmitStep}
          submitButtonText="Next"
          isLoading={isSaving}
        />
      )}
      {step === 2 && (
        <NotificationContentForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          disabledTitle={true}
          documentBlockchainAddress={documentBlockchainAddress}
          documentType={documentType}
          notificationType={notificationType}
        />
      )}
      {step === 3 && (
        <DocumentPublicationSummary
          mode={mode}
          onSubmit={handleFormSubmit}
          onBack={handleBack}
          isLoading={isSaving}
          defaultValues={formData}
          submitButtonText="Confirm2"
          publicationMode={mode}
          documentType={documentType}
        />
      )}
    </>
  );
};

export default UpdateForm;
