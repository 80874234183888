import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { PASSWORD_FORM_MODES, UserRoles } from '../../../../constraints';

// Components
import { Title } from '../RegisterForm/styled';
import RegisterPasswordForm from '../PasswordForm/RegisterPasswordForm';

const RegisterPassword = ({
  defaultValues,
  profileInfo,
  onSuccess,
  onFailure,
  onBack,
  deliveredErrors,
}) => {
  const formConfig = {
    mode: PASSWORD_FORM_MODES.REGISTER,
    handleSuccess: onSuccess,
    passwordLabelId: 'Password',
    repeatPasswordLabelId: 'Repeat password',
    submitMessageId: 'Sign up',
  };

  return (
    <>
      <Title>
        <FormattedMessage
          id="Enter Your password"
          defaultMessage="Enter Your password"
        />
      </Title>
      <br />
      <br />
      <RegisterPasswordForm
        profileInfo={profileInfo}
        deliveredRole={UserRoles.VISITOR}
        formConfig={formConfig}
        defaultValues={defaultValues}
        onSuccess={onSuccess}
        onFailure={onFailure}
        onBack={onBack}
        deliveredErrors={deliveredErrors}
      />
    </>
  );
};

export default RegisterPassword;
