import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const LoginButton = styled(Button)`
  margin-top: 4rem;
  ${down('sm')} {
    margin-top: 2rem;
  }
`;
