import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DefaultContentCol } from '../UniCardContent/UniCardContent';
import * as Styled from './styled';

const SingleCustomCard = ({
  titleId,
  disableBodyPaddingTop,
  variant,
  children,
}) => {
  const Content = () => (
    <Styled.Card>
      {!!titleId && (
        <Styled.Title>
          <FormattedMessage id={titleId} defaultMessage={titleId} />
        </Styled.Title>
      )}
      <Styled.CardBodyWrapper disableBodyPaddingTop={disableBodyPaddingTop}>
        {children}
      </Styled.CardBodyWrapper>
    </Styled.Card>
  );

  if (variant === 'full-width') {
    return <Content />;
  }

  return (
    <DefaultContentCol>
      <Content />
    </DefaultContentCol>
  );
};

export default SingleCustomCard;
