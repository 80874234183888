import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, matchPath, useHistory } from 'react-router-dom';

import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../constraints';

// Components
import NavButton from '../NavButton';
import * as Styled from './styled';
import { Grid } from 'billon-ui';

const { Row, Col } = Grid;

const CardHeader = ({ primaryUrl, archiveUrl, children }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const isPrimaryPage = matchPath(pathname, {
    path: primaryUrl,
  });
  const isArchivePage = matchPath(pathname, {
    path: archiveUrl,
  });

  const handlePrimaryClick = () => {
    history.push(primaryUrl);
  };

  const handleArchiveClick = () => {
    history.push(archiveUrl);
  };

  if (!archiveUrl) {
    return <Styled.CardHeader>{children}</Styled.CardHeader>;
  }

  return (
    <>
      <Styled.CardHeader>
        <Styled.NavTextButton
          styles={{ color: 'black !important' }}
          isSelected={isPrimaryPage}
          onClick={() => handlePrimaryClick()}
        >
          {children}
        </Styled.NavTextButton>
        {!!archiveUrl && (
          <Styled.CardHeaderNeighbour>
            <NavButton
              isSelected={isArchivePage}
              onClick={() => handleArchiveClick()}
            >
              <FormattedMessage
                id={NOTIFICATION_MODULE_MENU_LABEL.ARCHIVE}
                defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.ARCHIVE}
              />
            </NavButton>
          </Styled.CardHeaderNeighbour>
        )}
      </Styled.CardHeader>
    </>
  );
};

export default CardHeader;
