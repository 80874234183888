import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Utils } from 'billon-ui';
const { Text } = Utils;

export const Title = styled(Text)`
  font-size: 2.5rem;
  line-height: 48px;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin-bottom: 0px;
  ${down('lg')} {
    font-size: 1.9rem;
  }
  ${down('sm')} {
    font-size: 1.75rem;
    line-height: 36px;
  }
`;

export const SubTitle = styled(Text)`
  font-size: 1.25rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin-bottom: 2rem;
  ${down('sm')} {
    margin-bottom: 1rem;
  }
`;

export const Description = styled(Text)`
  font-size: 1rem;
  font-weight: ${(props) => props.theme.fontWeightMedium};
  margin-bottom: 2rem;
`;

export const SupportLabel = styled(Text)`
  text-align: left;
  margin-top: 10px;
`;

export const LineHeaderRowLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${(props) => props.theme.palette.grayLighter};
`;

export const LineHeaderRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
`;
