import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import {
  LoginForm,
  LoginCard,
  BlockedModal,
  BlockedMessage,
} from '../../components';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { BillonLogo } = Utils;

const HomePage = () => {
  const [accountBlockedModalOpen, setAccountBlockedModalOpen] = useState(false);
  const [accountBlockedUnblockDate, setAccountBlockedUnblockDate] =
    useState(null);

  const handleCloseAccountBlockedModal = () => {
    setAccountBlockedModalOpen(false);
  };

  const handleAccountBlocked = (value) => {
    setAccountBlockedUnblockDate(value);
    setAccountBlockedModalOpen(true);
  };

  return (
    <Styled.LoginContent>
      <Styled.BannerCol
        sm={{ size: 12, offset: 0 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 4, offset: 1 }}
      >
        <Styled.Description>
          <Styled.Title>
            <FormattedMessage
              id="Unified Blockchain Platform"
              defaultMessage="Unified Blockchain Platform"
            />
          </Styled.Title>
          <Styled.Small>
            <FormattedMessage id="POWERED BY" defaultMessage="POWERED BY" />
          </Styled.Small>
          <BillonLogo black />
        </Styled.Description>
      </Styled.BannerCol>
      <Styled.CardWrapperCol
        sm={{ size: 12, offset: 0 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 5, offset: 1 }}
      >
        <LoginCard>
          <LoginForm onBlocked={handleAccountBlocked} />
        </LoginCard>
      </Styled.CardWrapperCol>
      <BlockedModal
        isOpen={accountBlockedModalOpen}
        toggle={handleCloseAccountBlockedModal}
        onClose={handleCloseAccountBlockedModal}
        closeIconEnabled={false}
      >
        <BlockedMessage rawDate={accountBlockedUnblockDate} />
      </BlockedModal>
    </Styled.LoginContent>
  );
};

export default HomePage;
