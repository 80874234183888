import React, { useState } from 'react';
import { merge } from 'lodash';
import { FormattedMessage } from 'react-intl';

// constants
import { USER_ORDER_BY, ORDER_METHOD } from '../../../../constraints';

// formatters
import { momentFormatterWithoutTime } from '../../../../formatters';
import { blurMultiFormatter } from '../../../../formatters';
import { createdByFormatter } from '../../../Users/formatters';
import { recipientStatusWithToolTipFormatter } from '../../../Recipients/formatters';
import { boundLengthFormatter } from '../../../../formatters';

// hooks
import { useIdentityDocumentType } from '../../../Recipients/hooks/useIdentityDocumentType';
import { useIdentityList } from '../../../Recipients/hooks/useIdentityList';
import useFilters from '../../../../hooks/useFilters';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// components
import RecipientTableDetails from '../RecipientTableDetails';
import { FilterForm } from '../../../Recipients/components/FilterForm';
import { RecipientSearchBar } from './components';
import * as Styled from './styled';
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;

const RecipientTable = () => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
      sortBy: USER_ORDER_BY.createdAt,
    },
  };

  const { isDiploma, isTauron, recipientsConfig } = useConfigContext();
  const { enableDocumentNumberBlur } = recipientsConfig || {};
  const { getExternalIdText } = useIdentityDocumentType();

  const [areFiltersOpen, setAreFiltersOpen] = useState();
  const toggleFiltersForm = () => setAreFiltersOpen(!areFiltersOpen);

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge(defaultFilters, filters);

  const { data, isLoading } = useIdentityList(fullFilters);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === column) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    } else {
      return true;
    }
  };

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: USER_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  const titleText = isDiploma ? 'Students' : 'Recipients';
  const externalIdText = getExternalIdText();
  const numberOfPages = Math.ceil(data?.count / fullFilters.pagination.limit);

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <Styled.AfterWrapper>
                <RecipientSearchBar placeholderMessageId={externalIdText} />
              </Styled.AfterWrapper>
            }
          >
            <FormattedMessage id={titleText} defaultMessage={titleText} />
            <Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={toggleFiltersForm}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
            </Button>
          </PageHeader>
        )}
      </FormattedMessage>

      {areFiltersOpen && (
        <FilterForm initialValues={filters} closeFilters={toggleFiltersForm} />
      )}

      <Styled.TableCard>
        <Table
          tableData={data.rows}
          sortMethod={onSort}
          responsive
          detailsComponent={(record) => (
            <RecipientTableDetails record={record} />
          )}
        >
          <TableColumn
            fieldName="createdAt"
            formatter={momentFormatterWithoutTime}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.createdAt)}
          >
            <FormattedMessage id="Created at" defaultMessage="Created at" />
          </TableColumn>

          <TableColumn
            fieldName="documentNumber"
            formatter={(value, record) =>
              blurMultiFormatter(
                boundLengthFormatter,
                enableDocumentNumberBlur,
              )(value, record)
            }
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.documentNumber)}
          >
            <FormattedMessage
              id={externalIdText}
              defaultMessage={externalIdText}
            />
          </TableColumn>
          <TableColumn
            fieldName="phoneNumber"
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.phoneNumber)}
          >
            <FormattedMessage id="Phone number" defaultMessage="Phone number" />
          </TableColumn>
          {isTauron && (
            <TableColumn
              fieldName="name"
              sortable
              asc={isAscendingOrder(USER_ORDER_BY.name)}
            >
              <FormattedMessage
                id="Name and surname"
                defaultMessage="Name and surname"
              />
            </TableColumn>
          )}
          {!isTauron && (
            <TableColumn
              fieldName="firstName"
              sortable
              asc={isAscendingOrder(USER_ORDER_BY.firstName)}
            >
              <FormattedMessage id="First name" defaultMessage="First name" />
            </TableColumn>
          )}
          {!isTauron && (
            <TableColumn
              fieldName="lastName"
              sortable
              asc={isAscendingOrder(USER_ORDER_BY.lastName)}
            >
              <FormattedMessage id="Last name" defaultMessage="Last name" />
            </TableColumn>
          )}
          <TableColumn
            fieldName="email"
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.email)}
          >
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>
          <TableColumn fieldName="createdBy" formatter={createdByFormatter}>
            <FormattedMessage id="Created by" defaultMessage="Created by" />
          </TableColumn>
          <TableColumn
            fieldName="status"
            formatter={recipientStatusWithToolTipFormatter}
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </Styled.TableCard>
    </Content>
  );
};

export default RecipientTable;
