import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Card } = Utils;

const RecoverPasswordCard = ({ children }) => {
  return (
    <Card>
      <Styled.Title>
        <FormattedMessage
          id="Recover password"
          defailtMessage="Recover password"
        />
      </Styled.Title>
      <Styled.Description>
        <FormattedMessage
          id="Enter the email address to which a link to change your password will be sent"
          defailtMessage="Enter the email address to which a link to change your password will be sent"
        />
      </Styled.Description>
      {children}
    </Card>
  );
};

export default RecoverPasswordCard;
