import { useMemo } from 'react';

// hooks
import useValidNotificationGroupContent from './useValidNotificationGroupContent';
import { usePrivateSingleNotificationGroupContent } from './usePrivateSingleNotificationGroupContent';
import { usePrivateSingleNotificationGroupTemplate } from './usePrivateSingleNotificationGroupTemplate';
import { usePrivateSingleNotificationTemplateGroup } from './usePrivateSingleNotificationTemplateGroup';

export const useTemplatesByCategoryData = ({
  categoryNotificationTemplate,
  categoryId,
  enabled,
  groupContentId,
}) => {
  const { areGroupContentsLoading, groupContentsError, validGroupContent } =
    useValidNotificationGroupContent({
      enabled: enabled && !!categoryNotificationTemplate && !groupContentId,
      activeNotificationGroupId: categoryNotificationTemplate?.groupId,
    });

  const {
    data: categoryIdTemplate,
    isFetching: isCategoryIdTemplateLoading,
    isSuccess: isCategoryIdTemplateSuccess,
    error: categoryIdTemplateError,
  } = usePrivateSingleNotificationGroupTemplate(categoryId, {
    enabled: enabled && !!categoryId && !categoryNotificationTemplate,
  });

  const {
    data: templateDetails,
    isFetching: isTemplateDetailsLoading,
    isSuccess: isTemplateDetailsSuccess,
    error: templateDetailsError,
  } = usePrivateSingleNotificationTemplateGroup(
    categoryNotificationTemplate?.groupId,
    { enabled: !!categoryNotificationTemplate?.groupId },
  );

  const resultGroupContentId = useMemo(() => {
    if (!!groupContentId) {
      return groupContentId;
    }
    if (!!validGroupContent) {
      return validGroupContent?.id;
    }
    if (isCategoryIdTemplateSuccess && !!categoryIdTemplate) {
      return categoryIdTemplate?.currentGroupContentId;
    }
  }, [
    validGroupContent,
    categoryIdTemplate,
    isCategoryIdTemplateSuccess,
    groupContentId,
  ]);

  const {
    data: groupContentData,
    isFetching: isGroupContentLoading,
    error: contentError,
  } = usePrivateSingleNotificationGroupContent(resultGroupContentId, {
    enabled: !!resultGroupContentId,
  });

  const isLoading = useMemo(
    () =>
      areGroupContentsLoading ||
      isGroupContentLoading ||
      isCategoryIdTemplateLoading ||
      isTemplateDetailsLoading,
    [
      areGroupContentsLoading,
      isGroupContentLoading,
      isCategoryIdTemplateLoading,
      isTemplateDetailsLoading,
    ],
  );

  const data = useMemo(() => {
    if (!!groupContentData) {
      const { emailTemplate, smsTemplate } = groupContentData || {};
      return {
        emailInput: emailTemplate,
        smsInput: smsTemplate,
        subjectInput: categoryNotificationTemplate?.emailTitle,
      };
    }
    return;
  }, [groupContentData]);

  const error = useMemo(() => {
    if (!!groupContentsError) {
      return groupContentsError;
    }
    if (!!categoryIdTemplateError) {
      return categoryIdTemplateError;
    }
    if (!!contentError) {
      return contentError;
    }
    if (!!templateDetailsError) {
      return templateDetailsError;
    }
    return;
  }, [
    groupContentsError,
    categoryIdTemplateError,
    contentError,
    templateDetailsError,
  ]);

  return {
    notificationType: templateDetails?.notificationType,
    data,
    error,
    isLoading,
  };
};
