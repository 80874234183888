import { useIntl } from 'react-intl';

import {
  NOTIFICATION_TEMPLATE_TYPES,
  MAP_NOTIFICATION_TEMPLATE_TYPE_OPTIONS,
} from '../../../constraints';
import { useConfigContext } from '../../Config/hooks/useConfig';

export const useNotificationTemplateTypeOptions = () => {
  const { formatMessage } = useIntl();
  const { notificationsConfig } = useConfigContext();

  const notificationTemplateTypeKeys = [
    NOTIFICATION_TEMPLATE_TYPES.PRIVATE_PUBLISHED,
    NOTIFICATION_TEMPLATE_TYPES.MINIMAL_PRIVATE_PUBLISHED,
  ];

  const notificationTemplateTypeOptions = notificationTemplateTypeKeys.map(
    (key) => {
      const msgId = MAP_NOTIFICATION_TEMPLATE_TYPE_OPTIONS[key];
      return {
        label: formatMessage({
          id: msgId,
          defaultMessage: msgId,
        }),
        value: key,
      };
    },
  );

  const defaultNotificationTemplateTypeKey =
    NOTIFICATION_TEMPLATE_TYPES.PRIVATE_PUBLISHED;
  const defaultNotificationTemplateTypeOption = {
    label: formatMessage({
      id: MAP_NOTIFICATION_TEMPLATE_TYPE_OPTIONS[
        defaultNotificationTemplateTypeKey
      ],
      defaultMessage:
        MAP_NOTIFICATION_TEMPLATE_TYPE_OPTIONS[
          defaultNotificationTemplateTypeKey
        ],
    }),
    value: defaultNotificationTemplateTypeKey,
  };

  return {
    notificationTemplateTypeOptions,
    defaultNotificationTemplateTypeOption,
  };
};
