import styled from 'styled-components';

const neutralGrey = '#C1C9D5';

export const Anchor = styled('a')`
  color: ${neutralGrey};
  &:hover,
  &:focus {
    color: ${neutralGrey};
  }
`;
export const Badge = styled('a')`
  margin-left: 16px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeightSemiBold};
  border: 3px solid;
  border-color: ${({ theme }) => theme.palette.billonGreen};
  border-radius: 24px;
  padding: 6px 12px;
  min-width: 150px;
  text-align: center;
  background-color: ${(props) =>
    props?.active ? props.theme.palette.billonGreen : 'none'};
  color: ${(props) => (props?.active ? 'white' : 'black')};
  &:hover {
    background-color: ${(props) => props.theme.palette.billonGreen};
    color: white !important;
  }
  cursor: pointer;

  @media (max-width: 1199px) {
    margin-bottom: ${(props) => (props?.enableIfMobile ? '0.5rem' : 0)};
    margin-right: ${(props) => (props?.enableIfMobile ? '16px' : 0)};
  }
`;
