import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

// constants
import { QueryKeys } from '../../../../../../queryKeys';

// hooks
import { useGdprSuspend } from '../../../../hooks/useGdprSuspend';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// components
import * as Styled from '../styled';

import SuspendedBadge from '../SuspendedBadge';
import { LoadingActionButton } from '../../../../../../components';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';
import ConfirmActionModal from '../ConfirmActionModal';
import HeaderWithToolTip from '../../../../../../components/HeaderWithToolTip';
import { SuccessModal } from '../../../../../../components/SuccessModal';
import { ErrorModal } from '../../../../../../components/ErrorModal';

import { Layout as LayoutModule } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { ErrorContent } = ContentModule;

const RecipientActions = ({ recipient }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [currentSuspensionActionState, setCurrentSuspensionActionState] =
    useState(null);

  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { isTauron } = useConfigContext();

  const handleSuccess = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_IDENTITY, recipient.id]);
    setIsConfirmModalOpen(false);
    setIsSuccessModalOpen(true);
  };

  const handleError = () => {
    setIsConfirmModalOpen(false);
    setIsErrorModalOpen(true);
  };

  const { mutate: suspendGdpr, isLoading } = useGdprSuspend({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const handleResumeDataProcessing = () => {
    setIsConfirmModalOpen(true);
    setCurrentSuspensionActionState(false);
  };
  const handleSuspendDataProcessing = () => {
    setIsConfirmModalOpen(true);
    setCurrentSuspensionActionState(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  const handleConfirm = () => {
    suspendGdpr({
      id: recipient.id,
      data: { isGdprSuspended: currentSuspensionActionState },
    });
  };

  const successMessageId = currentSuspensionActionState
    ? 'Data processing suspended'
    : 'Data processing resumed';
  const errorMessageId = currentSuspensionActionState
    ? 'Data processing suspension error'
    : 'Data processing resumption error';
  const recipientName = isTauron
    ? recipient.name
    : `${recipient.firstName} ${recipient.lastName}`;
  const suspensionTitle = formatMessage({ id: 'Data processing suspension' });

  return (
    <>
      <Styled.SectionColumn>
        <Styled.Row>
          <Styled.Label>
            <HeaderWithToolTip
              title={suspensionTitle}
              tooltipId="suspension-header"
            >
              <FormattedMessage
                id="suspension-definition"
                defaultMessage="suspension-definition"
              />
            </HeaderWithToolTip>
            {recipient.isGdprSuspended && <SuspendedBadge />}
          </Styled.Label>
        </Styled.Row>
        {recipient.isGdprSuspended ? (
          <Styled.Row>
            <Styled.SuspensionCol>
              <LoadingActionButton
                onClick={handleResumeDataProcessing}
                outline
                isLoading={isLoading}
              >
                <FormattedMessage
                  id="Resume data processing"
                  defaultMessage="Resume data processing"
                />
              </LoadingActionButton>
            </Styled.SuspensionCol>
          </Styled.Row>
        ) : (
          <Styled.Row padding={0}>
            <BtnCol padding={0}>
              <LoadingActionButton
                onClick={handleSuspendDataProcessing}
                outline
                isLoading={isLoading}
              >
                <FormattedMessage
                  id="Suspend data processing"
                  defaultMessage="Suspend data processing"
                />
              </LoadingActionButton>
            </BtnCol>
          </Styled.Row>
        )}
      </Styled.SectionColumn>
      <ConfirmActionModal
        isOpen={isConfirmModalOpen}
        onSubmit={handleConfirm}
        isSubmitting={isLoading}
        onClose={handleConfirmModalClose}
        currentSuspensionActionState={currentSuspensionActionState}
        recipientName={recipientName}
        recipientDocumentNumber={recipient.documentNumber}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        toggle={handleSuccessModalClose}
        onClose={handleSuccessModalClose}
        closeIconEnabled={true}
      >
        <FormattedMessage
          id={successMessageId}
          defaultMessage={successMessageId}
        />
      </SuccessModal>
      <ErrorModal
        isOpen={isErrorModalOpen}
        toggle={handleErrorModalClose}
        onClose={handleErrorModalClose}
        closeIconEnabled={true}
      >
        <ErrorContent>
          <FormattedMessage
            id={errorMessageId}
            defaultMessage={errorMessageId}
          />
        </ErrorContent>
      </ErrorModal>
    </>
  );
};

export default RecipientActions;
