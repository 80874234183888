import React from 'react';
import { FormattedMessage } from 'react-intl';

import { momentInTimezoneFormatter } from '../../../../../../../../formatters';

import useFilters from '../../../../../../../../hooks/useFilters';

import { Grid } from 'billon-ui';

import { Label } from './styled';

const { Col } = Grid;

const PlannedArchivization = ({ archivedSince }) => {
  const { filters } = useFilters();

  return (
    <Col>
      <Label>
        {filters?.filters?.archived ? (
          <FormattedMessage id="Archived" defaultMessage="Archived" />
        ) : (
          <FormattedMessage
            id="Planned archivization"
            defaultMessage={'Planned archivization'}
          />
        )}
        : {momentInTimezoneFormatter(archivedSince)}
      </Label>
    </Col>
  );
};

export default PlannedArchivization;
