import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useDownloadSingleDocument } from '../../hooks';

import appRoutes from '../../../../app-routes';

import { WebViewer } from '../../../../components/WebViewer';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;

const { PageLoader } = LoaderModule;

const DocumentPreviewPage = () => {
  const { params } = useRouteMatch(appRoutes.DOCUMENT_PREVIEW);
  const id = params?.id;

  const { mutate: downloadFile } = useDownloadSingleDocument(id, id, true);

  useEffect(() => downloadFile(), []);

  return (
    <>
      <PageLoader />
      <WebViewer />
    </>
  );
};

export default DocumentPreviewPage;
