import styled from 'styled-components';
import { Grid, Utils } from 'billon-ui';

const { Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const ButtonCol = styled(Col)`
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    margin-top: -50px;
  }
  margin-bottom: 16px;
`;

export const FlexEndButton = styled(Button)`
  width: 100%;
  align-self: flex-end;
  @media (min-width: 1400px) {
    padding: 0.5rem 3.5rem;
  }
`;
