import React from 'react';
import { FormattedMessage } from 'react-intl';

// hooks
import useFilters from '../../../../../../hooks/useFilters';
import { usePrivateNotificationGroupContents } from '../../../../hooks/privateNotifications/usePrivateNotificationGroupContents';
import useActiveNotificationGroupId from '../../../../hooks/privateNotifications/useActiveNotificationGroupId';

// constants
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../../../constraints';

// components
import { PlannedArchivization } from '../GroupContent/components';
import {
  ContentHistoryHeader,
  ContentHistoryRow,
  SetNewContentButton,
  ArchiveButton,
} from './components';
import * as Styled from './styled';
import { Utils, Grid } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Row, Col } = Grid;

const NotificationManager = ({ notificationType, groupId }) => {
  const { filters } = useFilters();
  const isArchived = filters?.filters?.archived;

  const { activeNotificationGroupArchivedSince } =
    useActiveNotificationGroupId();

  const { data: groupContents, isFetching: areGroupContentsLoading } =
    usePrivateNotificationGroupContents(null, groupId, {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  if (areGroupContentsLoading) {
    return (
      <PageLoader
        message={
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENTS_LOADING}
            defaultMessage={
              NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENTS_LOADING
            }
          />
        }
      />
    );
  }

  return (
    <>
      <Styled.ActionsRow>
        {!isArchived && (
          <ArchiveButton disabled={!!activeNotificationGroupArchivedSince} />
        )}
        {!isArchived && (
          <SetNewContentButton
            disabled={!!activeNotificationGroupArchivedSince}
          />
        )}
      </Styled.ActionsRow>
      {!!activeNotificationGroupArchivedSince && (
        <Row>
          <PlannedArchivization
            archivedSince={activeNotificationGroupArchivedSince}
          />
        </Row>
      )}
      <ContentHistoryHeader />
      {groupContents?.count > 0 &&
        groupContents?.rows.map(({ id }) => (
          <ContentHistoryRow
            notificationType={notificationType}
            contentId={id}
          />
        ))}
    </>
  );
};

export default NotificationManager;
