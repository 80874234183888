import React from 'react';

import { FEEDBACK_ACTIONS } from '../../../../constraints';
import { useSettingsContext } from '../../../Settings/hooks/useSettings';

// Components
import TwoStepPasswordSet from '../../components/TwoStepPasswordSet';
import { ExportableErrorContent } from '../../../../components/ExportableErrorContent';
import SingleCustomCard from '../../../../components/SingleCustomCard';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const ForgottenPasswordPage = () => {
  const { isLoading: isTokenLoading, isTokenExpired } = useSettingsContext();

  if (isTokenExpired) {
    return (
      <SingleCustomCard>
        <ExportableErrorContent
          exportDisabled
          headerTitleId={'Your password reset link has expired'}
          statusCode={FEEDBACK_ACTIONS.LINK_EXPIRED}
        />
      </SingleCustomCard>
    );
  }

  if (isTokenLoading) {
    return (
      <SingleCustomCard titleId={'Resetting password'}>
        <PageLoader />
      </SingleCustomCard>
    );
  }

  return <TwoStepPasswordSet />;
};

export default ForgottenPasswordPage;
