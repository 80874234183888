import React from 'react';

// constants
import { RECIPIENT_ERROR_CODES } from '../../../../../../../constants';

// hooks
import { useConfigContext } from '../../../../../../Config/hooks/useConfig';

// components
import { FormattedMessage } from 'react-intl';
import * as Styled from '../styled';
import ReasonsForFailuresColumn from './ReasonsForFailuresColumn';
import { Grid, Utils } from 'billon-ui';

const { Col } = Grid;
const { Text, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const FileSuccessSummary = ({
  successData,
  mode,
  handleResetMutation,
  handleSuccess,
  handleExit,
  cancelMessageId = 'Cancel',
}) => {
  const { total } = successData;
  const { isDiploma, isOpenPlatform } = useConfigContext();

  const backMessageId = 'Back';

  return (
    <div>
      <Styled.FormHeader>
        <FormattedMessage id="Result" defaultMessage="Result" />
      </Styled.FormHeader>

      {total === 0 && (
        <ReasonsForFailuresColumn
          mode={mode}
          isNeighbourDisplayed={false}
          statusCode={RECIPIENT_ERROR_CODES.RECIPIENTS_EMPTY}
        />
      )}

      {total > 0 && (
        <Text>
          <FormattedMessage
            id="Correctly uploaded2"
            defaultMessage="Correctly uploaded2"
          />{' '}
          <>
            {total} <FormattedMessage id="of" defaultMessage="of" /> {total}
          </>{' '}
          {isDiploma ? (
            <FormattedMessage id="students" defaultMessage="students" />
          ) : (
            <FormattedMessage id="recipients" defaultMessage="recipients" />
          )}
          .
        </Text>
      )}

      <Styled.CenterRow>
        {handleResetMutation && (
          <Col md={4} sm={4} xs={4}>
            <Button block type="button" outline onClick={handleResetMutation}>
              <FormattedMessage
                id={backMessageId}
                defaultMessage={backMessageId}
              />
            </Button>
          </Col>
        )}
        {total > 0 && (
          <Col md={4} sm={4} xs={4}>
            <Button block type="button" onClick={handleSuccess}>
              <FormattedMessage id="Confirm2" defaultMessage="Confirm2" />
            </Button>
          </Col>
        )}
        {handleExit && total === 0 && (
          <Col md={4} sm={4} xs={4}>
            <Button block type="button" onClick={handleExit}>
              <FormattedMessage
                id={cancelMessageId}
                defaultMessage={cancelMessageId}
              />
            </Button>
          </Col>
        )}
      </Styled.CenterRow>
    </div>
  );
};

export default FileSuccessSummary;
