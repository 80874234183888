import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const Label = styled(Text)`
  font-size: ${(props) => props.theme.fontSizeMd};
  margin-bottom: 0;
  color: ${({ theme }) => theme.linkHoverColor};
  margin-top: -0.5rem;
`;
