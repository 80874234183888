import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../js/ui/Modal';
import { Modal as ModalModule, Layout, Grid, Utils } from 'billon-ui';

const { ModalFooter } = ModalModule;
const { Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Content: ContentModule } = Layout;
const { SuccessContent } = ContentModule;

const SuccessModal = (props) => {
  return (
    <Modal {...props} closeIconEnabled={props?.closeIconEnabled || false}>
      <SuccessContent height="20rem">{props.children}</SuccessContent>
      <ModalFooter>
        <Col md={8}>
          <Button size="lg" block onClick={props.onClose}>
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default SuccessModal;
