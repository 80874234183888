import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';

import { useConfigContext } from '../../../Config/hooks/useConfig';
import useFilters from '../../../../hooks/useFilters';

import { momentInTimezoneFormatter } from '../../../../formatters';
import * as formatters from '../../formatters';

import * as Styled from './styled';
import { Table as TableModule, Utils, Layout as LayoutModule } from 'billon-ui';

const { Pagination } = Utils;
const { Table, TableColumn } = TableModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const UserHistoryTable = ({ historyRecords }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
  };

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge(defaultFilters, filters);
  const { customer, isOpenPlatform } = useConfigContext();

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const numberOfPages = Math.ceil(
    historyRecords.count / fullFilters.pagination.limit,
  );

  return (
    <Content>
      <Styled.TableCard>
        <Table tableData={historyRecords} sortMethod={() => {}} responsive>
          <TableColumn
            fieldName="updatedAt"
            formatter={momentInTimezoneFormatter}
          >
            <FormattedMessage id="Edit date" defaultMessage="Edit date" />
          </TableColumn>
          <TableColumn fieldName="firstName">
            <FormattedMessage id="First name" defaultMessage="First name" />
          </TableColumn>
          <TableColumn fieldName="lastName">
            <FormattedMessage id="Last name" defaultMessage="Last name" />
          </TableColumn>
          <TableColumn fieldName="email">
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>

          <TableColumn fieldName="phoneNumber">
            <FormattedMessage id="Phone number" defaultMessage="Phone number" />
          </TableColumn>

          <TableColumn fieldName="department">
            <FormattedMessage id="Department" defaultMessage="Department" />
          </TableColumn>

          <TableColumn fieldName="role" formatter={formatters.roleFormatter}>
            <FormattedMessage id="Account type" defaultMessage="Account type" />
          </TableColumn>
          <TableColumn
            fieldName="updatedBy"
            formatter={formatters.updatedByFormatter}
          >
            <FormattedMessage id="Edited by" defaultMessage="Edited by" />
          </TableColumn>

          <TableColumn
            className="fixed-width"
            fieldName="isActive"
            formatter={formatters.switchFormatter}
          >
            <FormattedMessage id="Active2" defaultMessage="Active" />
          </TableColumn>

          <TableColumn
            fieldName="status"
            formatter={formatters.userStatusWithToolTipFormatter}
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </Styled.TableCard>
    </Content>
  );
};

UserHistoryTable.propTypes = {
  historyRecords: PropTypes.object.isRequired,
};

export default UserHistoryTable;
