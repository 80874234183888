import React, { createContext, useContext, useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { useAuthenticatedContext } from './useAuthenticated';

import appRoutes from '../../../app-routes';

const TokenParamContext = createContext(false);

export const useTokenParamContext = () => useContext(TokenParamContext);

export const TokenParamProvider = ({ children }) => {
  const { isAuthenticated } = useAuthenticatedContext();

  const tokenParamMatches = [
    appRoutes.RECOVER_PASSWORD,
    appRoutes.RECOVER_PASSWORD_SUCCESS,
    appRoutes.FORGOTTEN_PASSWORD,
    appRoutes.PASSWORD_SET,
    appRoutes.ACTIVATE_ACCOUNT,
  ];

  const { pathname } = useLocation();

  const token = useMemo(() => {
    if (isAuthenticated) {
      return undefined;
    }

    const matchPathFirstResult = tokenParamMatches
      .map((match) => matchPath(pathname, { path: match }))
      .filter(Boolean)[0];

    const tokenParam = matchPathFirstResult?.params?.token;

    if (tokenParam) {
      return `Bearer ${tokenParam}`;
    }

    return tokenParam;
  }, [isAuthenticated, pathname, tokenParamMatches]);

  const contextValue = useMemo(
    () => ({
      token,
    }),
    [token],
  );

  return (
    <TokenParamContext.Provider value={contextValue}>
      {children}
    </TokenParamContext.Provider>
  );
};
