import { patchRequest } from '../../../../helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../../api-routes';

export const useArchivePrivateNotificationTemplateGroup = (groupId, options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: archiveData } = await patchRequest({
      url: apiRoutes.PRIVATE_NOTIFICATION_GROUP_CONTENT_ARCHIVE.replace(
        '{groupId}',
        groupId,
      ),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return archiveData;
  }, options);
