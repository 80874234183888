import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { SETTINGS_CONSTANTS } from '../../constraints';

import { useConfigContext } from '../../modules/Config/hooks/useConfig';
import { useSettingsContext } from '../../modules/Settings/hooks/useSettings';

import NewTabAnchor from '../../components/NewTabAnchor';
import * as Styled from './styled';
import CustomToolTip from '../CustomToolTip';
import { Grid } from 'billon-ui';

const { Row } = Grid;

const PoweredBy = ({ fixed, className, theme }) => {
  const { isOpenPlatform } = useConfigContext();
  const { getSettingValueByKey } = useSettingsContext();
  const publisherName = getSettingValueByKey(SETTINGS_CONSTANTS.PUBLISHER_NAME);

  const urls = {
    company: 'https://www.billongroup.com',
    youTube: 'https://youtu.be/uGc0ef_QcKE',
    article: 'https://billongroup.com/about-us/press-kit',
  };

  return (
    <Styled.PB
      className={classNames({
        fixed,
        [className]: !!className,
      })}
    >
      <Row>
        <Styled.VersionSpan disabled>
          v. {process.env.REACT_APP_VERSION}
        </Styled.VersionSpan>
        Powered by <Styled.BillonLogoGrey />
        <Styled.VersionSpan disabled={isOpenPlatform}>
          v. {process.env.REACT_APP_VERSION}
        </Styled.VersionSpan>
      </Row>
      <Row>
        <NewTabAnchor href={urls.company}>www.billongroup.com</NewTabAnchor>
      </Row>
      <Row style={{ gap: '8px' }}>
        <CustomToolTip
          id="yt-link"
          trigger={
            <NewTabAnchor href={urls.youTube}>
              <Styled.YouTubeIcon />
            </NewTabAnchor>
          }
          maxHeight="14px"
          minWidth="20px"
          placement="top"
        >
          <FormattedMessage
            id="Watch our TDM Demonstration Video"
            defaultMessage="Watch our TDM Demonstration Video"
          />
        </CustomToolTip>
        <CustomToolTip
          id="articleLink-link"
          trigger={
            <NewTabAnchor href={urls.article}>
              <Styled.ArticleIcon />
            </NewTabAnchor>
          }
          maxHeight="14px"
          minWidth="20px"
          placement="top"
        >
          <FormattedMessage
            id="Read more about Billon solutions"
            defaultMessage="Read more about Billon solutions"
          />
        </CustomToolTip>
      </Row>
    </Styled.PB>
  );
};

PoweredBy.propTypes = {
  fixed: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.object,
};

PoweredBy.defaultProps = {
  fixed: false,
};

export default withTheme(PoweredBy);
