import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Styled from './styled';
import { Layout, Grid, Utils } from 'billon-ui';

const { Col } = Grid;
const { Card, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Content: ContentModule } = Layout;
const { SuccessContent } = ContentModule;

const RecoverPasswordSuccess = ({ onClose, children }) => {
  return (
    <Card>
      <Styled.Title>
        <FormattedMessage
          id="Message has been sent"
          defailtMessage="Message has been sent"
        />
      </Styled.Title>
      <SuccessContent height="12rem">{children}</SuccessContent>
      <Col>
        <Button size="lg" block onClick={onClose}>
          <FormattedMessage id="Close" defaultMessage="Close" />
        </Button>
      </Col>
    </Card>
  );
};

export default RecoverPasswordSuccess;
