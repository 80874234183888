import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text, Card: BillonCard } = Utils;

export const PageTitle = styled(Text)`
  font-weight: 800;
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
  margin-top: -0.5rem;
`;

export const Card = styled(BillonCard)`
  margin: 0;
  @media (max-width: 600px) {
    padding: 0;
    padding-right: 0.5rem;
    margin-top: 1rem;
    box-shadow: none;
    background-color: transparent !important;
  }
`;
