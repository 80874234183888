import styled from 'styled-components';

export const ValidationIndicatorRow = styled.div`
  margin-top: -15px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 80%;
  margin-bottom: 15px;
`;

export const ValidationIndicatorLine = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 10px;
  height: 4px;
  background-color: ${(props) =>
    props?.check
      ? props.theme.palette.primary
      : props.theme.palette.grayLighter};
`;
