import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// constants
import appRoutes from '../../../../app-routes';

// Components
import { RecoverPasswordCard, RecoverPasswordForm } from '../../components';
import { DefaultContentCol } from '../../../../components/UniCardContent/UniCardContent';

const RecoverPasswordPage = () => {
  const history = useHistory();

  const [reasons, setReasons] = useState([]);

  const handleOnBack = () => {
    history.goBack();
  };

  const onRecoverPasswordSuccess = () => {
    history.push(appRoutes.RECOVER_PASSWORD_SUCCESS);
  };

  return (
    <DefaultContentCol>
      <RecoverPasswordCard>
        <RecoverPasswordForm
          onBack={handleOnBack}
          onSuccess={onRecoverPasswordSuccess}
          deliveredReasons={reasons}
          changeEvent={setReasons}
        />
      </RecoverPasswordCard>
    </DefaultContentCol>
  );
};

export default RecoverPasswordPage;
