import React from 'react';

import * as formatters from '../../../../formatters';
import { userInfoFormatter } from '../../../../../../formatters';
import DataDetailsSection from '../../../../../../components/DataDetailsSection';
import { CUSTOMER } from '../../../../../../constraints';
import { useConfigContext } from '../../../../../../modules/Config/hooks/useConfig';

const DetailsSection = ({ user }) => {
  const { customer, isOpenPlatform } = useConfigContext();

  const enabledDetailsCol1 = [
    {
      title: 'Login',
      data: user.username,
      enabled: true,
    },
    {
      title: 'First name',
      data: user.firstName,
      enabled: true,
    },
    {
      title: 'Last name',
      data: user.lastName,
      enabled: true,
    },
    {
      title: 'Status',
      data: formatters.userStatusFormatter(user.status),
      enabled: true,
    },
  ];

  const enabledDetailsCol2 = [
    {
      title: 'Email',
      data: user.email,
      enabled: true,
    },
    {
      title: 'Phone number',
      data: user.phoneNumber,
      enabled: true,
    },
    {
      title: isOpenPlatform ? 'Company' : 'Department',
      data: user.department,
      enabled: true,
    },
  ];

  const enabledDetailsCol3 = [
    {
      title: 'Account type',
      data: formatters.roleFormatter('', user),
      enabled: true,
    },
    {
      title: 'Report Panel Accessibility',
      data: formatters.reportRoleFormatter(user.reportRole),
      enabled: customer === CUSTOMER.TAURON,
    },
    {
      title: 'Created at',
      data: formatters.createdAtFormatter('', user),
      enabled: true,
    },
    {
      title: 'Created by',
      data: user?.createdBy ? userInfoFormatter('', user.createdBy) : '',
      enabled: true,
    },
  ];

  return (
    <DataDetailsSection
      headerMessageId={'Details'}
      activityTagEnabled
      isActive={user.isActive}
      columns={[enabledDetailsCol1, enabledDetailsCol2, enabledDetailsCol3]}
    />
  );
};

export default DetailsSection;
