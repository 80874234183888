import styled from 'styled-components';

import { ReactComponent as YTIcon } from '../../ui/Icons/youtube-brands-solid.svg';
import { ReactComponent as ArtIcon } from '../../ui/Icons/article.svg';
import { ReactComponent as Billon } from '../../ui/Icons/billon.svg';

const neutralGrey = '#C1C9D5';

export const PB = styled.div`
  color: rgb(193, 201, 213);
  font-size: 0.7rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-weight: ${(props) => props.theme.fontWeightLight};

  gap: 0;

  &.fixed {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    right: 0;
    left: 0;
    height: 65px;
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 1rem 0;
    z-index: 2;
  }
`;

export const VersionSpan = styled.span`
  margin-left: 8px;
  opacity: ${(props) => (props.disabled ? '0.05' : 1)};
  color: ${(props) =>
    props?.disabled
      ? props.theme.palette.grayLightest
      : props.theme.palette.grayLighter};
`;

export const YouTubeIcon = styled(YTIcon)`
  fill: ${neutralGrey};
  height: 15px;
  width: 15px;
  cursor: 'pointer';
`;

export const ArticleIcon = styled(ArtIcon)`
  fill: ${neutralGrey};
  height: 15px;
  width: 15px;
  cursor: 'pointer';
`;

export const BillonLogoGrey = styled(Billon)`
  margin-left: 6px;
  fill: ${neutralGrey};
`;
