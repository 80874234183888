import { useMemo } from 'react';

import { usePrivateNotificationTemplateGroups } from './usePrivateNotificationTemplateGroups';
import useFilters from '../../../../hooks/useFilters';

const useActiveNotificationGroupId = () => {
  const { filters } = useFilters();
  const {
    data: notificationGroups,
    isSuccess,
    isFetching: areNotificationGroupsLoading,
  } = usePrivateNotificationTemplateGroups({
    filters: { archived: filters?.filters?.archived },
  });

  const activeNotificationGroupId = useMemo(() => {
    const fitlerGroupId = filters?.filters?.groupId;
    if (!!fitlerGroupId) {
      return fitlerGroupId;
    }
    if (notificationGroups?.rows?.length === 0) {
      return;
    }
    const firstNotificationGroupId = notificationGroups?.rows[0]?.id;
    if (!fitlerGroupId && !!firstNotificationGroupId) {
      return firstNotificationGroupId;
    }
  }, [filters, notificationGroups, isSuccess]);

  const { activeNotificationType, activeNotificationGroupArchivedSince } =
    useMemo(() => {
      if (!!activeNotificationGroupId && notificationGroups?.rows?.length > 0) {
        const activeNotificationGroup = notificationGroups?.rows?.find(
          ({ id }) => id === activeNotificationGroupId,
        );
        return {
          activeNotificationType: activeNotificationGroup?.notificationType,
          activeNotificationGroupArchivedSince:
            activeNotificationGroup?.archivedSince,
        };
      }
      return {
        activeNotificationType: null,
        activeNotificationGroupArchivedSince: null,
      };
    }, [notificationGroups, activeNotificationGroupId]);

  return {
    activeNotificationGroupId,
    notificationGroups,
    areNotificationGroupsLoading,
    activeNotificationType,
    activeNotificationGroupArchivedSince,
  };
};

export default useActiveNotificationGroupId;
