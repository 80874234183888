import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Utils } from 'billon-ui';

const { Card: BillonCard, Icon } = Utils;

export const Card = styled(BillonCard)`
  margin-top: 30px;
  padding-top: 15px;
  padding-bottom: 5px;
  @media (max-width: 768px) {
    margin-bottom: 75px;
  }
`;

export const UnderlineLink = styled(Link)`
  text-decoration: underline;
`;

export const ImageIcon = styled(Icon)`
  color: #cad5dd;
`;
