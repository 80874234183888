import React from 'react';

import { NOTIFICATION_TEMPLATE_FIELD_NAMES } from '../../../../../../../../constraints';

import { useNotificationTemplateTypeOptions } from '../../../../../../hooks/useNotificationTemplateTypeOptions';

import { ControlledSelectField } from '../../../../../../../../components/ControlledSelectField/ControlledSelectField';

const SelectNotificationTemplateType = () => {
  const { notificationTemplateTypeOptions } =
    useNotificationTemplateTypeOptions();

  return (
    <ControlledSelectField
      name={NOTIFICATION_TEMPLATE_FIELD_NAMES.NOTIFICATION_TYPE}
      options={notificationTemplateTypeOptions}
    />
  );
};

export default SelectNotificationTemplateType;
