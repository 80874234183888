import config from './config';

export default {
  ROOT_URL: config.backendUrl,

  // Categories
  CATEGORY: `${config.backendUrl}/categories`,
  SINGLE_CATEGORY: `${config.backendUrl}/categories/{id}`,
  TOGGLE_CATEGORY: `${config.backendUrl}/category/toggle/`,

  //Documents
  DOCUMENT_PUBLIC: `${config.backendUrl}/documents/public`,
  DOCUMENT_PRIVATE: `${config.backendUrl}/documents/private`,
  SIGNED_DOCUMENT_PUBLIC_PUBLISH: `${config.backendUrl}/documents/{id}/public/publish`,
  SIGNED_DOCUMENT_PRIVATE_PUBLISH: `${config.backendUrl}/documents/{id}/private/publish`,

  DOCUMENT: `${config.backendUrl}/documents`,
  SINGLE_DOCUMENT: `${config.backendUrl}/documents/{id}`,
  DOCUMENT_PUBLISH: `${config.backendUrl}/documents/private`,
  DOCUMENT_PUBLIC_PUBLISH: `${config.backendUrl}/documents/public`,
  DOCUMENT_PUBLIC_PUBLISH_ASYNC: `${config.backendUrl}/documents/public/async`,
  DOCUMENT_PRIVATE_PUBLISH_ASYNC: `${config.backendUrl}/documents/private/async`,
  DOCUMENT_PUBLISH_ASYNC_STATUS: `${config.backendUrl}/documents/async/{id}`,
  DOCUMENT_UPDATE_RECIPIENTS: `${config.backendUrl}/documents/{id}/recipientsFile`,
  SINGLE_DOCUMENT_DISCARD: `${config.backendUrl}/document/discard/{id}`,
  PUBLISH_DOCUMENT: `${config.backendUrl}/documents/publish`,
  SINGLE_DOCUMENT_DOWNLOAD: `${config.backendUrl}/documents/{id}/file`,
  SINGLE_DOCUMENT_FORGET: `${config.backendUrl}/documents/{id}/forget`,
  SINGLE_DOCUMENT_DOWNLOAD_RECIPIENTS_LIST: `${config.backendUrl}/identities`,
  DOCUMENT_DOWNLOAD_RECIPIENTS_LIST: `${config.backendUrl}/documents/{id}/recipientsFile`,
  PREPARE_DOCUMENT: `${config.backendUrl}/document/prepare`,
  DOCUMENT_NOTIFICATION_HISTORY: `${config.backendUrl}/documents/{id}/notificationHistory`,
  REPORTS: `${config.backendUrl}/reports`,
  DOCUMENT_RECIPIENTS: `${config.backendUrl}/documents/{id}/recipients`,
  RESEND_DOCUMENT_NOTIFICATION: `${config.backendUrl}/documents/{id}/{documentType}/notification`,
  NOTIFICATION_USER_INPUTS: `${config.backendUrl}/documents/{id}/notificationUserInput`,

  // Reports
  YEARLY_REPORT: `${config.backendUrl}/reports/yearly`,
  DOWNLOAD_STATISTICS_EXCEL_REPORT: `${config.backendUrl}/reports/statisticsFile`,
  DOWNLOAD_EXCEL_REPORT: `${config.backendUrl}/reports/downloadExcelReport`,
  FAIR_USAGE: `${config.backendUrl}/reports/fairUsage`,
  CUSTOM_REPORT: `${config.backendUrl}/reports/custom`,
  DOCUMENTS_REPORT_FILE_DOWNLOAD: `${config.backendUrl}/documents/documentsReportFIle`,

  // Users
  USER: `${config.backendUrl}/users`,
  SINGLE_USER: `${config.backendUrl}/users/{id}`,
  MODIFY_PASSWORD: `${config.backendUrl}/users/password`,
  ADMINISTRATIVE_MODIFY_PASSWORD: `${config.backendUrl}/users/{id}/password`,
  SET_PASSWORD: `${config.backendUrl}/users/{id}/password`,
  PASSWORD_EXPIRED: `${config.backendUrl}/users/{id}/passwordExpired`,
  NOTIFY_USER: `${config.backendUrl}/users/{id}/notification`,
  RESET_PASSWORD: `${config.backendUrl}/users/resetPassword`,
  ACTIVATE_USER: `${config.backendUrl}/users/{id}/activateUser`,

  //Utility
  CHECK_CONNECTION: `${config.backendUrl}/utils/hello`,
  LOGIN: `${config.backendUrl}/utils/login`,
  REFRESH_TOKEN: `${config.backendUrl}/utils/refreshToken`,
  SETTINGS: `${config.backendUrl}/utils/settings`,
  SMS_CODE: `${config.backendUrl}/utils/smsCode`,
  LOGOUT: `${config.backendUrl}/utils/logout`,
  ME: `${config.backendUrl}/utils/me`,
  VALIDATE_PASSWORD: `${config.backendUrl}/utils/validatePassword`,

  // Notifications
  NOTIFICATION_TEMPLATES: `${config.backendUrl}/notifications/templates`,
  NOTIFICATION_CONTENT: `${config.backendUrl}/notifications/{id}/content`,
  // Private Notifications
  PRIVATE_NOTIFICATION_TEMPLATE_GROUPS: `${config.backendUrl}/privateDocumentNotificationTemplateGroups`,
  PRIVATE_SINGLE_NOTIFICATION_TEMPLATE_GROUP: `${config.backendUrl}/privateDocumentNotificationTemplateGroups/{id}`,
  PRIVATE_NOTIFICATION_GROUP_TEMPLATES: `${config.backendUrl}/privateDocumentNotificationTemplateGroups/{groupId}/templates`,
  PRIVATE_SINGLE_NOTIFICATION_GROUP_TEMPLATE: `${config.backendUrl}/privateDocumentNotificationTemplateGroups/templates/{templateId}`,
  PRIVATE_NOTIFICATION_GROUP_CONTENTS: `${config.backendUrl}/privateDocumentNotificationTemplateGroups/{groupId}/contents`,
  PRIVATE_NOTIFICATION_GROUP_CONTENT_ARCHIVE: `${config.backendUrl}/privateDocumentNotificationTemplateGroups/{groupId}/archive`,
  PRIVATE_SINGLE_NOTIFICATION_GROUP_CONTENT: `${config.backendUrl}/privateDocumentNotificationTemplateGroups/contents/{groupContentId}`,
  // Public Notifications
  PUBLIC_NOTIFICATION_TEMPLATES: `${config.backendUrl}/publicDocumentNotificationTemplates`,
  PUBLIC_NOTIFICATION_TEMPLATE: `${config.backendUrl}/publicDocumentNotificationTemplates/{templateId}`,

  // Recipients
  RECIPIENTS_FILE_VALIDATE: `${config.backendUrl}/recipients/validity`,

  // AGREEMENTS
  AGREEMENT: `${config.backendUrl}/agreement`,
  PREPARE_AGREEMENT: `${config.backendUrl}/agreement/prepare`,
  PUBLISH_AGREEMENT: `${config.backendUrl}/agreement/publish`,
  SINGLE_AGREEMENT: `${config.backendUrl}/agreement/byId/{id}`,
  SINGLE_AGREEMENT_DOWNLOAD: `${config.backendUrl}/agreement/download`,
  SINGLE_AGREEMENT_RESEND_AUTHORIZATION_CODES: `${config.backendUrl}/agreement/resendAuthorizationCodes/{id}`,
  SINGLE_AGREEMENT_FORGET: `${config.backendUrl}/agreement/forget/{id}`,
  SINGLE_AGREEMENT_DISCARD: `${config.backendUrl}/agreement/discard/{id}`,
  SINGLE_AGREEMENT_DOWNLOAD_RECIPIENTS_LIST: `${config.backendUrl}/agreement/downloadRecipientsList/{id}`,
  AGREEMENT_HISTORY: `${config.backendUrl}/agreement/history/{id}`,

  // IDENTITIES
  RECIPIENT: `${config.backendUrl}/identities`,
  LOAD_RECIPIENT_FILE: `${config.backendUrl}/identities/file`,
  DOWNLOAD_RECIPIENT_LIST: `${config.backendUrl}/identities/file`,
  RECIPIENT_FILE: `${config.backendUrl}/identities/file`,
  SINGLE_RECIPIENT: `${config.backendUrl}/identities/{id}`,
  SINGLE_RECIPIENT_UPDATE_STATUS: `${config.backendUrl}/identities/update/{id}`,

  REQUEST: `${config.backendUrl}/observer/request`,
  SINGLE_REQUEST: `${config.backendUrl}/observer/request/byId/{id}`,
  SEND_REQUEST_DECISION: `${config.backendUrl}/observer/request/sendRequestDecision`,

  RECIPIENT_GDPR_SUSPEND: `${config.backendUrl}/identities/{id}/gdprSuspended`,
  RECIPIENT_GDPR_FORGET: `${config.backendUrl}/identities/{id}/forget`,

  // Tenants
  TENANT: `${config.backendUrl}/tenants`,
};
