import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';

// constants
import appRoutes from '../../../../app-routes';
import {
  NOTIFICATION_MODULE_MENU_LABEL,
  NOTIFICATION_PREVIEW_TYPES,
} from '../../../../constraints';

// hooks
import useFilters from '../../../../hooks/useFilters';

// Components
import Modal from '../../components/Modal';
import { FormFooter } from '../../../../components/FormFooter';
import { NotificationManager } from '../../components/Templates/components';

const ManageNotificationPage = () => {
  const { formatMessage } = useIntl();
  const { filters, navigateWithSearchQuery } = useFilters();
  const isArchived = filters?.filters?.archived;

  const [notificationHistoryModalOpened, setNotificationHistoryModalOpened] =
    useState(true);

  const handleCreateModalClose = () => {
    setNotificationHistoryModalOpened(false);
    navigateWithSearchQuery(
      null,
      isArchived
        ? appRoutes.NOTIFICATION_ARCHIVE
        : appRoutes.NOTIFICATION_TEMPLATES,
    );
  };

  const notificationType = useMemo(() => {
    return (
      filters?.filters?.notificationType ?? NOTIFICATION_PREVIEW_TYPES.EMAIL
    );
  }, [filters]);

  const titleMessageId = isArchived
    ? NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_NOTIFICATION_HISTORY_ARCHIVE
    : NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_NOTIFICATION_HISTORY;

  return (
    <>
      <Modal
        isOpen={notificationHistoryModalOpened}
        toggle={handleCreateModalClose}
        onClose={handleCreateModalClose}
        title={formatMessage({
          id: titleMessageId,
          defaultMessage: titleMessageId,
        })}
      >
        <NotificationManager
          onClose={handleCreateModalClose}
          notificationType={notificationType}
          groupId={filters?.filters?.groupId}
        />
        <FormFooter
          backBtnDisabled
          submitButtonText={'Close'}
          onNext={handleCreateModalClose}
        />
      </Modal>
    </>
  );
};

export default ManageNotificationPage;
