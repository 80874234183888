import React from 'react';
import { FormattedMessage } from 'react-intl';

import SuspendedBadge from './SuspendedBadge';
import HeaderWithToolTip from '../../../../../../components/HeaderWithToolTip';
import { SuspendedBadgeWrapper } from './styled';

export const SuspendedBadgeWithTooltip = ({ isSuspended }) => {
  if (isSuspended) {
    return (
      <SuspendedBadgeWrapper>
        <SuspendedBadge>
          {'\u00A0'}
          <HeaderWithToolTip
            title=""
            tooltipId="suspension-header"
            iconColor="white"
          >
            <FormattedMessage
              id="Data processing suspended"
              defaultMessage="Data processing suspended"
            />
          </HeaderWithToolTip>
        </SuspendedBadge>
      </SuspendedBadgeWrapper>
    );
  }
  return null;
};
