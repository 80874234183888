import React from 'react';
import { Redirect } from 'react-router-dom';

import { useConfigContext } from '../../../Config/hooks/useConfig';

import { CUSTOMER } from '../../../../constraints';
import appRoutes from '../../../../app-routes';

const RootRedirectPage = () => {
  const { customer, enablePublic, enablePrivate } = useConfigContext();

  const getDefaultRedirect = () => {
    if (enablePrivate && enablePublic) {
      return <Redirect to={appRoutes.SELECT_DOCUMENT_TYPE} />;
    }

    return (
      <Redirect
        to={`/documents?filters.documentTypeList=${
          enablePrivate ? 'PRIVATE' : 'PUBLIC'
        }`}
      />
    );
  };

  return {
    [CUSTOMER.DIPLOMA]: (
      <Redirect to="/documents?filters.documentTypeList=PRIVATE" />
    ),
    [CUSTOMER.DEFAULT]: getDefaultRedirect(),
    [CUSTOMER.OPEN_PLATFORM]: getDefaultRedirect(),
    [CUSTOMER.TAURON]: (
      <Redirect to="/documents?filters.documentTypeList=PUBLIC" />
    ),
  }[customer];
};

export default RootRedirectPage;
