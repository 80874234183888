import { parseNotificationType } from './parseNotificationType';

export const parseCreateTemplateData = ({
  name,
  notificationType,
  emailTemplate,
  smsTemplate,
}) => ({
  name,
  notificationType: parseNotificationType(notificationType),
  content: {
    emailTemplate,
    smsTemplate,
  },
});
