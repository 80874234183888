import * as yup from 'yup';
import { useIntl } from 'react-intl';

import {
  NOTIFICATION_TEMPLATE_FIELD_NAMES,
  VALIDATION_ERRORS,
} from '../../../../../../../../constraints';

export const useSchema = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    [NOTIFICATION_TEMPLATE_FIELD_NAMES.TEMPLATE_ARCHIVED_SINCE]: yup
      .string()
      .required(
        formatMessage({
          id: VALIDATION_ERRORS.REQUIRED,
          defaultMessage: VALIDATION_ERRORS.REQUIRED,
        }),
      ),
  });
};
