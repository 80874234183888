import React from 'react';
import styled from 'styled-components';

import { Grid } from 'billon-ui';

export const FlexEndRow = styled(Grid.Row)`
  justify-content: flex-end;
`;

export const MobileSensitiveCol = styled(Grid.Col)`
  @media (max-width: 768px) {
    margin-bottom: 16px !important;
  }
`;
