import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

// hooks
import { useSettingsContext } from '../../../Settings/hooks/useSettings';
import { useTokenParamContext } from '../../../Auth/hooks/useTokenParam';
import useMeRequest from '../../../Settings/hooks/useMeRequest';
import { useActivateUser } from '../../hooks/useActivateUser';

// Constants
import { FEEDBACK_ACTIONS } from '../../../../constraints';

// Components
import { ExportableErrorContent } from '../../../../components/ExportableErrorContent';
import SingleCustomCard from '../../../../components/SingleCustomCard';

const { Loader: LoaderModule, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content, SuccessContent } = ContentModule;

const ActivateLinkPage = () => {
  const history = useHistory();

  const [error, setError] = useState();

  const { token } = useTokenParamContext();
  const { isLoading: isTokenLoading, isTokenExpired } = useSettingsContext();
  const { data: me, isLoading: isMeLoading, isError } = useMeRequest({}, token);
  const {
    mutate: activateUser,
    isLoading: isActivating,
    isError: isActivationError,
    isSuccess: isActivationSuccess,
  } = useActivateUser(
    {
      onError: ({ response }) => {
        setError(response?.data);
      },
    },
    token,
  );

  useEffect(() => {
    const { id, status } = me || {};

    if (!!id) {
      activateUser({ id });
    }
  }, [me]);

  const handleOnCloseSuccess = () => {
    history.push('/');
  };

  if (isTokenExpired || isError) {
    return (
      <SingleCustomCard>
        <ExportableErrorContent
          exportDisabled
          headerTitleId={'Your account activation link has expired'}
          statusCode={FEEDBACK_ACTIONS.LINK_EXPIRED}
        />
      </SingleCustomCard>
    );
  }

  if (error?.statusCode === 'USER_ALREADY_ACTIVATED') {
    return (
      <SingleCustomCard>
        <SuccessContent height="20rem">
          <FormattedMessage
            id={'Account has already been activated'}
            defaultMessage={'Account has already been activated'}
          />
        </SuccessContent>
        <Button size="lg" block onClick={handleOnCloseSuccess}>
          <FormattedMessage id="Log in" defaultMessage="Log in" />
        </Button>
      </SingleCustomCard>
    );
  }

  if (isActivationError) {
    return (
      <SingleCustomCard>
        <ExportableErrorContent
          exportDisabled
          headerTitleId={'Account activation error'}
          statusCode={FEEDBACK_ACTIONS.LINK_EXPIRED}
        />
      </SingleCustomCard>
    );
  }

  if (isTokenLoading || isMeLoading || isActivating) {
    return (
      <SingleCustomCard titleId={'Account activation'}>
        <Content fluid>
          <PageLoader />
        </Content>
      </SingleCustomCard>
    );
  }

  if (isActivationSuccess) {
    return (
      <>
        <SingleCustomCard titleId={'Account activation'}>
          <SuccessContent height="20rem">
            <FormattedMessage
              id={'The account has been activated!'}
              defaultMessage={'The account has been activated!'}
            />
          </SuccessContent>
          <Button size="lg" block onClick={handleOnCloseSuccess}>
            <FormattedMessage id="Log in" defaultMessage="Log in" />
          </Button>
        </SingleCustomCard>
      </>
    );
  }

  return (
    <>
      <SingleCustomCard titleId={'Account activation'}></SingleCustomCard>
    </>
  );
};

export default ActivateLinkPage;
