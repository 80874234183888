import React, { useEffect } from 'react';

// hooks
import useDocumentType from '../../hooks/useDocumentType';
import useFilters from '../../../../hooks/useFilters';

// Components
import { PageLayout, FeatureCard, Templates } from '../../components';
import { Grid } from 'billon-ui';

const { Col } = Grid;

const NotificationArchivePage = () => {
  const { documentType } = useDocumentType();
  const { filters, navigateWithNewSearchQuery } = useFilters();

  useEffect(() => {
    if (!filters?.filters?.documentTypeList && !!documentType) {
      navigateWithNewSearchQuery({
        filters: { documentTypeList: documentType, archived: true },
      });
    }
  }, [filters, documentType]);

  return (
    <PageLayout>
      <Col md={12}>
        <FeatureCard>
          <Templates />
        </FeatureCard>
      </Col>
    </PageLayout>
  );
};

export default NotificationArchivePage;
