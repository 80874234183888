import React from 'react';

// constants
import {
  CUSTOMER,
  IDENTITY_DOCUMENT_TYPE,
  BLUR_IDENTITY_MOCK_VALUES,
} from '../../constraints';

// formatters
import { blurSingleFormatter } from '../../formatters';

// Components
import UserInfo from '../../ui/UserInfo';
import { SuspendedBadgeWithTooltip } from '../GDPR/components/RecipientDetails/components/SuspendedBadge/SuspendedBadgeWithTooltip';
import { BlurContent } from '../../components/BlurContent/BlurContent';

import { Grid } from 'billon-ui';
const { Col } = Grid;

export const formatIdentity = (identity, customer) => {
  const {
    documentNumber,
    firstName,
    lastName,
    phoneNumber,
    email,
    name,
    documentType,
    isGdprSuspended,
  } = identity || {};

  const displayedName =
    customer === CUSTOMER.TAURON ? name : `${firstName} ${lastName}`;

  const displayedDocumentNumber = blurSingleFormatter(
    documentNumber,
    customer === CUSTOMER.DEFAULT &&
      documentType === IDENTITY_DOCUMENT_TYPE.PESEL,
  );

  const isBlurActive = customer === CUSTOMER.TAURON && isGdprSuspended;

  const DataDetails = () => (
    <>
      <div>{displayedName}</div>
      <div>{displayedDocumentNumber}</div>
      <div>{phoneNumber}</div>
      <div>{email}</div>
    </>
  );

  const BluredDataDetails = () => (
    <BlurContent>
      <div>{BLUR_IDENTITY_MOCK_VALUES.NAME}</div>
      <div>{BLUR_IDENTITY_MOCK_VALUES.DOCUMENT_NUMBER}</div>
      <div>{BLUR_IDENTITY_MOCK_VALUES.PHONE_NUMBER}</div>
      <div>{BLUR_IDENTITY_MOCK_VALUES.EMAIL}</div>
    </BlurContent>
  );

  return (
    <Col margin={0} padding={0}>
      <SuspendedBadgeWithTooltip isSuspended={isBlurActive} />
      {isBlurActive ? <BluredDataDetails /> : <DataDetails />}
    </Col>
  );
};

export const publishedByFormatter = (value) => {
  if (value) {
    const { firstName, lastName, username } = value;
    return (
      <UserInfo
        scale="small"
        name={`${firstName ? firstName : '---'} ${lastName ? lastName : '---'}`}
      >
        {username}
      </UserInfo>
    );
  }
};
