import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { BtnCol } from '../../../../../../../../components/LoadingActionButton/styled';
import CustomToolTip from '../../../../../../../../ui/CustomToolTip';
import { LoadingActionButton } from '../../../../../../../../components';

const NotificationHistory = ({
  handleNotificationHistoryModalOpen,
  suspensionMessageId,
  inActive,
}) => {
  const Action = () => (
    <LoadingActionButton
      outline
      inActive={inActive}
      onClick={inActive ? () => {} : handleNotificationHistoryModalOpen}
    >
      <FormattedMessage
        id="Notification history"
        defaultMessage="Notification history"
      />
    </LoadingActionButton>
  );

  const ResendNotificationTooltip = () => (
    <CustomToolTip
      id="notification-history-suspension-Tooltip"
      trigger={<Action />}
      delay={false}
      placement="bottom-start"
    >
      <h4>
        <FormattedMessage
          id="Notification history"
          defaultMessage="Notification history"
        />
      </h4>
      <FormattedMessage
        id={suspensionMessageId}
        defaultMessage={suspensionMessageId}
      />
    </CustomToolTip>
  );

  return (
    <BtnCol oneThirdSplit>
      {inActive ? <ResendNotificationTooltip /> : <Action />}
    </BtnCol>
  );
};

export default NotificationHistory;
