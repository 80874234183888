import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

// helpers
import { validateEmptyPhoneNumber } from '../../../../helpers/validateEmptyPhoneNumber';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';

// constants
import {
  MAP_VALIDATION_USER_ERRORS_NAMES,
  MAP_VALIDATION_USER_ERRORS,
  USER_ACTIONS,
  CUSTOMER,
  UserRoles,
  REPORT_ROLE,
} from '../../../../constraints';

// hooks
import { useSchema } from './useSchema';
import { useCreateUser } from '../../hooks/useCreateUser';
import { useUpdateUser } from '../../hooks/useUpdateUser';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// Components
import * as Styled from './styled';
import DataTab from './DataTab';
import PermissionsTab from './PermissionsTab';
import { Form as FormModule, Utils, Grid } from 'billon-ui';
import ReportPanelAccessibility from './ReportPanelAccessibility';

const { Col } = Grid;
const { FormGroup } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const CreateForm = ({
  mode,
  initialValues,
  onSuccess,
  onFailure,
  submitMessageId,
}) => {
  const { customer, enablePrivate } = useConfigContext();
  const isPhoneNumberRequired = [CUSTOMER.DEFAULT, CUSTOMER.DIPLOMA].includes(
    customer,
  );
  const isPermissionTabEnabled = customer !== CUSTOMER.OPEN_PLATFORM;
  const isReportPanelAccessibilityEnabled = customer === CUSTOMER.TAURON;
  const submitButtonMessageId = submitMessageId ?? 'Save';

  const schema = useSchema({ isPhoneNumberRequired });

  const { id } = initialValues || {};

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      isActive: true,
      isRobot: false,
      notify: customer === CUSTOMER.TAURON ? undefined : true,
      role: UserRoles.VISITOR,
      reportRole:
        customer === CUSTOMER.TAURON ? REPORT_ROLE.NO_ACCESS : undefined,
      ...initialValues,
    },
    resolver: yupResolver(schema),
  });

  const handleError = ({ response }) => {
    clearErrors();
    const errorInfo = response?.data?.errorInfo;
    errorInfo &&
      Object.entries(errorInfo).map(([key, value]) => {
        setError(MAP_VALIDATION_USER_ERRORS_NAMES[key], {
          type: 'custom',
          message: MAP_VALIDATION_USER_ERRORS[`${value}_${key}`],
        });
      });
    onFailure();
  };

  const handleSuccess = (data) => {
    onSuccess(data);
  };

  const { mutate: createUser, isLoading: isCreating } = useCreateUser({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: updateUser, isLoading: isUpdating } = useUpdateUser({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const handleFormSubmit = (data) => {
    let parsedData = { ...data };
    if (parsedData?.email) {
      parsedData.email = parsedData.email.trim();
    }

    if (parsedData?.firstName) {
      parsedData.firstName = parsedData.firstName.trim();
    }

    if (parsedData?.lastName) {
      parsedData.lastName = parsedData.lastName.trim();
    }

    if (!isPhoneNumberRequired && validateEmptyPhoneNumber(data.phoneNumber)) {
      parsedData = { ...parsedData, phoneNumber: undefined };
    }
    if (mode === USER_ACTIONS.CREATE) {
      createUser({ data: parsedData });
    } else {
      parsedData = { ...parsedData, notify: undefined, isRobot: undefined };
      updateUser({ id, data: removeUndefinedValues(parsedData) });
    }
  };

  const isActiveWatch = watch('isActive');

  return (
    <>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        <DataTab
          control={control}
          initialValues={initialValues}
          enablePrivate={enablePrivate}
          customer={customer}
          errors={errors}
          isActiveWatch={isActiveWatch}
          setValue={setValue}
        />
        {isPermissionTabEnabled && (
          <PermissionsTab
            control={control}
            initialValues={initialValues}
            enablePrivate={enablePrivate}
            customer={customer}
            errors={errors}
          />
        )}
        {isReportPanelAccessibilityEnabled && (
          <ReportPanelAccessibility
            control={control}
            initialValues={initialValues}
            customer={customer}
            name="reportRole"
          />
        )}{' '}
        <FormGroup>
          <Col md={{ size: 8, offset: 2 }}>
            {isSubmitting || isCreating || isUpdating ? (
              <ButtonLoader block size="lg" />
            ) : (
              <Button type="submit" size="lg" block>
                <FormattedMessage
                  id={submitButtonMessageId}
                  defaultMessage={submitButtonMessageId}
                />
              </Button>
            )}
          </Col>
        </FormGroup>
      </Styled.Form>
    </>
  );
};

CreateForm.propTypes = {
  mode: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  initialValues: PropTypes.object,
  enablePrivate: PropTypes.bool,
  customer: PropTypes.string,
  submitMessageId: PropTypes.string,
};

export default CreateForm;
