import { Grid, Layout as LayoutModule } from 'billon-ui';
import styled from 'styled-components';

const { Content: ContentModule } = LayoutModule;
const { ContentContainer } = ContentModule;
const { Row, Col } = Grid;

export const ContentContainerGrey = styled(ContentContainer)`
  background: rgb(250, 250, 250);
  height: calc(100vh - 71px);
  padding-top: 40px;
`;

export const ContentContainerCentered = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  height: calc(100vh - 71px);
  @media (max-width: 768px) {
    height: auto;
    margin-top: 16px;
    margin-bottom: 75px;
  }
`;

export const ContentRow = styled(Row)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
`;

export const ContentCol = styled(Col)`
  align-self: center;
  width: 100%;
`;
