import styled from 'styled-components';

import { Form as FormModule, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { BackButton: BillonBackButton } = ButtonModule;

export const Permissions = styled.div`
  margin-bottom: 2rem;
`;

export const FieldFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`;

export const FC = styled.div`
  margin-right: 17px;
  align-self: center;
`;

export const FC2 = styled.div`
  padding-top: 2px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
`;

export const Form = styled(FormModule.Form)`
  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;

export const BackButton = styled(BillonBackButton)`
  padding: 0;
  margin-top: 22px;
`;

export const Title = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;
