import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';

// Utils
import {
  boundLengthFormatterLong,
  momentFormatterWithoutTime,
  momentInTimezoneFormatter,
  blurSingleFormatter,
} from '../../../../formatters';
import { publishedByFormatter } from '../../formatters';
import { documentStatusFormatter } from '../../formatters/documentStatusFormatter';
import {
  documentListIdentityFormatter,
  documentListIdentityNameFormatter,
} from '../../formatters/documentListIdentityFormatter';
import { tenantFormatter } from '../../../Tenants/formatters/tenantFormatter';
import { activeDeliveryFormatter } from '../../formatters/activeDeliveryFormatter';
import { boundLengthFormatter } from '../../../../formatters';

// constants
import {
  DOCUMENT_ORDER_BY,
  ORDER_METHOD,
  DOCUMENT_TYPE,
  UserRoles,
  CUSTOMER,
  IDENTITY_DOCUMENT_TYPE,
  documentStatuses,
  BLUR_IDENTITY_MOCK_VALUES,
} from '../../../../constraints';
import appRoutes from '../../../../app-routes';
import { QueryKeys } from '../../../../queryKeys';

// helpers
import { getIdentityPlaceHolderId } from '../../../Recipients/helpers/getIdentityPlaceHolderId';

// Hooks
import { useDocumentList } from '../../hooks';
import useFilters from '../../../../hooks/useFilters';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useTenantsContext } from '../../../Tenants/hooks/useTenants';
import useDocumentType from '../../hooks/useDocumentType';
import { useRefresh } from '../../../../hooks/useRefresh';

// Components
import * as Styled from './styled';
import EmptyDocumentList from './components/EmptyDocumentList';
import { PreviewDownloadButton } from '../PreviewDownloadButton';
import { DocumentTableDetails } from '../DocumentTableDetails';
import { FilterForm } from '../FilterForm';
import { ProtectedComponent } from '../../../../modules/Auth/components';
import { RefreshButton } from '../../../../components/RefreshButton/RefreshButton';
import { NotificationTemplatesModal } from '../NotificationTemplatesModal/NotificationTemplatesModal';
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule, Icon } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;

export const DocumentTable = ({ handleCreate, handleDelete }) => {
  const {
    isDiploma,
    isTauron,
    customer,
    enableSigning,
    enableActiveDelivery,
    recipientsConfig,
    publicationFormConfig,
  } = useConfigContext();

  const { enableDocumentNumberBlur } = recipientsConfig || {};
  const { areTenantsAvailable, tenantHeaderMessageId } = useTenantsContext();
  const { documentType } = useDocumentType();

  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    filters: {
      documentTypeList: documentType,
      documentStatusList:
        enableSigning && documentType === DOCUMENT_TYPE.PRIVATE
          ? documentStatuses
          : undefined,
    },
  };

  const [areFiltersOpen, setAreFiltersOpen] = useState();

  const toggleFiltersForm = () => setAreFiltersOpen(!areFiltersOpen);

  const { filters, navigateWithSearchQuery, navigateWithNewSearchQuery } =
    useFilters();

  const fullFilters = merge({}, defaultFilters, filters);

  const {
    data,
    isFetching: isDocumentDataFetching,
    isSuccess: isDocumentSuccess,
  } = useDocumentList(fullFilters);

  const getRowIndex = useCallback(
    (jId, bc) =>
      !isDocumentDataFetching &&
      data?.rows?.length > 0 &&
      data?.rows?.findIndex(
        ({ jobId, documentBlockchainAddress }) =>
          jId === jobId && bc === documentBlockchainAddress,
      ),
    [data, isDocumentDataFetching],
  );

  const rowIndexFormatter = (v, record = {}) => {
    const { jobId, documentBlockchainAddress } = record;
    const { limit, page } = fullFilters.pagination || {};
    return (
      limit * (page - 1) + getRowIndex(jobId, documentBlockchainAddress) + 1
    );
  };

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const isPublic = [
    fullFilters?.documentTypeList,
    filters?.filters?.documentTypeList,
    documentType,
  ].includes(DOCUMENT_TYPE.PUBLIC);

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: DOCUMENT_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === column) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    } else {
      return true;
    }
  };

  const handleCreateClick = () => {
    const { documentTypeList } = fullFilters.filters;
    navigateWithNewSearchQuery(
      { documentTypeList },
      appRoutes.PUBLISH_DOCUMENT,
    );
  };

  const { isRefreshDisabled, handleRefresh } = useRefresh(
    QueryKeys.DOCUMENT_LIST,
  );

  const documentIdentityText = getIdentityPlaceHolderId(isDiploma, customer);

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <ProtectedComponent
                roles={[
                  UserRoles.AGENT,
                  UserRoles.BUSINESS_ADMIN,
                  UserRoles.ADMIN,
                ]}
                render={false}
              >
                <Button
                  onClick={handleCreateClick}
                  fontWeight={500}
                  padding="0.5rem 3.5rem;"
                >
                  {isDiploma && (
                    <FormattedMessage
                      id="Publish diploma"
                      defaultMessage="Publish diploma"
                    />
                  )}
                  {!isDiploma &&
                    (isPublic ? (
                      <FormattedMessage
                        id="Publish public document"
                        defaultMessage="Publish public document"
                      />
                    ) : (
                      <FormattedMessage
                        id="Publish private document"
                        defaultMessage="Publish private document"
                      />
                    ))}
                </Button>
              </ProtectedComponent>
            }
          >
            {isDiploma && (
              <FormattedMessage
                id="Diploma list"
                defaultMessage="Diploma list"
              />
            )}
            {!isDiploma &&
              (isPublic ? (
                <FormattedMessage
                  id="Public documents"
                  defaultMessage="Public documents"
                />
              ) : (
                <FormattedMessage
                  id="Private documents"
                  defaultMessage="Private documents"
                />
              ))}

            <RefreshButton
              handleRefresh={handleRefresh}
              isRefreshDisabled={isRefreshDisabled}
            />

            <Styled.Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={toggleFiltersForm}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
              {Object.keys(filters?.filters ?? {}).length > 0 && (
                <Icon name="filter" />
              )}
            </Styled.Button>
          </PageHeader>
        )}
      </FormattedMessage>

      {areFiltersOpen && (
        <FilterForm
          defaultFilters={defaultFilters}
          closeFilters={toggleFiltersForm}
        />
      )}

      {publicationFormConfig?.isNotificationEnabled && (
        <ProtectedComponent roles={[UserRoles.DPI]} reverse render={false}>
          <Styled.ToEndRow padding="8px 16px 8px 16px">
            <NotificationTemplatesModal />
          </Styled.ToEndRow>
        </ProtectedComponent>
      )}

      <Styled.TableCard>
        {isDocumentDataFetching ? (
          <PageLoader />
        ) : (
          <>
            <Table
              tableData={data.rows}
              sortMethod={onSort}
              responsive
              detailsComponent={(record) => (
                <DocumentTableDetails
                  record={record}
                  handleCreate={(initialValues, isPreparedToSign = false) =>
                    handleCreate(initialValues, isPreparedToSign)
                  }
                  handleDelete={(id) => handleDelete(id)}
                />
              )}
            >
              {isTauron && (
                <TableColumn fieldName="index" formatter={rowIndexFormatter} />
              )}
              <TableColumn
                fieldName="publicationDate"
                formatter={momentInTimezoneFormatter}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.publicationDate)}
              >
                <FormattedMessage
                  id="Publication date"
                  defaultMessage="Publication date"
                />
              </TableColumn>
              <TableColumn
                fieldName="title"
                sortable
                formatter={boundLengthFormatterLong}
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.title)}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma number' : 'Title'}
                  defaultMessage={isDiploma ? 'Diploma number' : 'Title'}
                />
              </TableColumn>
              <TableColumn
                fieldName="validSince"
                formatter={
                  [CUSTOMER.TAURON, CUSTOMER.DIPLOMA].includes(customer)
                    ? momentFormatterWithoutTime
                    : momentInTimezoneFormatter
                }
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.validSince)}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma issuance date' : 'Valid since'}
                  defaultMessage={
                    isDiploma ? 'Diploma issuance date' : 'Valid since'
                  }
                />
              </TableColumn>
              {!isDiploma && (
                <TableColumn
                  fieldName="validUntil"
                  formatter={
                    isTauron
                      ? momentFormatterWithoutTime
                      : momentInTimezoneFormatter
                  }
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.validUntil)}
                >
                  <FormattedMessage
                    id="Valid until"
                    defaultMessage="Valid until"
                  />
                </TableColumn>
              )}
              {publicationFormConfig?.isCategoryFieldEnabled && (
                <TableColumn
                  fieldName="category"
                  formatter={(value) => value?.name}
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.category)}
                >
                  <FormattedMessage id="Category" defaultMessage="Category" />
                </TableColumn>
              )}
              {publicationFormConfig?.isPublishedByFieldEnabled && (
                <TableColumn
                  fieldName="publishedBy"
                  formatter={publishedByFormatter}
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.publishedBy)}
                >
                  <FormattedMessage
                    id="Published by"
                    defaultMessage="Published by"
                  />
                </TableColumn>
              )}
              {areTenantsAvailable && (
                <TableColumn
                  fieldName="tenant"
                  formatter={tenantFormatter(customer)}
                >
                  <FormattedMessage
                    id={tenantHeaderMessageId}
                    defaultMessage={tenantHeaderMessageId}
                  />
                </TableColumn>
              )}

              {!isPublic &&
                (!isTauron ? (
                  <TableColumn
                    fieldName="identity_surname"
                    formatter={documentListIdentityFormatter}
                    sortable
                    asc={isAscendingOrder(DOCUMENT_ORDER_BY.identity_surname)}
                  >
                    <FormattedMessage
                      id="Published for"
                      defaultMessage="Published for"
                    />
                  </TableColumn>
                ) : (
                  <TableColumn
                    fieldName="identity_name"
                    formatter={(value, record) =>
                      blurSingleFormatter(
                        documentListIdentityNameFormatter(value, record),
                        record?.identity?.isGdprSuspended,
                        BLUR_IDENTITY_MOCK_VALUES.NAME,
                      )
                    }
                    sortable
                    asc={isAscendingOrder(DOCUMENT_ORDER_BY.identity_name)}
                  >
                    <FormattedMessage
                      id="Published for"
                      defaultMessage="Published for"
                    />
                  </TableColumn>
                ))}

              {!isPublic && (
                <TableColumn
                  sortable
                  asc={isAscendingOrder(
                    DOCUMENT_ORDER_BY.identity_documentNumber,
                  )}
                  fieldName="identity_documentNumber"
                  formatter={(value, record) =>
                    blurSingleFormatter(
                      boundLengthFormatter(
                        record?.identity?.documentNumber,
                        20,
                      ),
                      (enableDocumentNumberBlur &&
                        record?.identity?.documentType ===
                          IDENTITY_DOCUMENT_TYPE.PESEL) ||
                        record?.identity?.isGdprSuspended,
                      BLUR_IDENTITY_MOCK_VALUES.DOCUMENT_NUMBER,
                    )
                  }
                >
                  <FormattedMessage
                    id={documentIdentityText}
                    defaultMessage={documentIdentityText}
                  />
                </TableColumn>
              )}

              {enableActiveDelivery && !isPublic && (
                <TableColumn
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.activeDelivery)}
                  fieldName="activeDelivery"
                  formatter={activeDeliveryFormatter}
                >
                  <FormattedMessage
                    id="e-Delivery"
                    defaultMessage="e-Delivery"
                  />
                </TableColumn>
              )}

              {enableSigning && !isPublic ? (
                <TableColumn
                  fieldName="signingStatus"
                  formatter={documentStatusFormatter(enableSigning)}
                >
                  <FormattedMessage id="Status" defaultMessage="Status" />
                </TableColumn>
              ) : (
                <TableColumn
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.status)}
                  fieldName="status"
                  formatter={documentStatusFormatter(enableSigning)}
                >
                  <FormattedMessage id="Status" defaultMessage="Status" />
                </TableColumn>
              )}

              <TableColumn
                className="text-center fixed-width"
                fieldName="Preview"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} isPreview />
                )}
              >
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </TableColumn>
              <TableColumn
                className="text-center fixed-width"
                fieldName="Download"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} />
                )}
              >
                <FormattedMessage id="Download" defaultMessage="Download" />
              </TableColumn>
            </Table>
            <Pagination
              onClick={handlePageChange}
              numberOfPages={Math.ceil(
                data.count / fullFilters.pagination.limit,
              )}
              currentPage={Number(fullFilters.pagination.page)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
            />
          </>
        )}
        {isDocumentSuccess && !data?.rows?.count && <EmptyDocumentList />}
      </Styled.TableCard>
      <br />
      <br />
    </Content>
  );
};
