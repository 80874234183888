import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { UserRoles, DOCUMENT_TYPE } from '../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// Components
import { ProtectedComponent } from '../../../../../../modules/Auth/components';
import { LoadingActionButton } from '../../../../../../components';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';
import { UpdateRecipientListButton } from '../UpdateRecipientListButton/UpdateRecipientListButton';
import { ResendNotification, NotificationHistory } from './components';

import { Utils, Grid } from 'billon-ui';

const { Text } = Utils;
const { Row } = Grid;

const ManageSection = ({
  documentType,
  handleResendNotificationClick,
  handleNotificationHistoryModalOpen,
  handleDownloadRecipientsList,
  resendAuthorizationCode,
  isResendingAuthorizationCode,
  isDownloadingRecipientsList,
  isDownloading,
  onDownload,
  document,
}) => {
  const { isTauron, isDiploma, isOpenPlatform } = useConfigContext();

  const isGdprSuspended = document?.identity?.isGdprSuspended;

  const isDocumentTypePublic = documentType === DOCUMENT_TYPE.PUBLIC;
  const enableNotificationHistory = !isDiploma;
  const enablePublicResendNotification = isDocumentTypePublic && isTauron;
  const enableDownloadRecipientList = isDocumentTypePublic;
  const enableUpdateRecipients = isDocumentTypePublic && !isOpenPlatform;

  const suspensionMessageId =
    'Data processing suspended. In order to proceed the action, please contact with DPI to resume data processing';

  return (
    <>
      <Text fontSize={'18px'} margin={'0 0 11px 0'} fontWeight={700}>
        <FormattedMessage
          id="Manage document"
          defaultMessage="Manage document"
        />
      </Text>
      <Row>
        <BtnCol oneThirdSplit>
          <LoadingActionButton isLoading={isDownloading} onClick={onDownload}>
            <FormattedMessage
              id="Download document"
              defaultMessage="Download document"
            />
          </LoadingActionButton>
        </BtnCol>
        <ResendNotification
          handleResendNotificationClick={handleResendNotificationClick}
          resendAuthorizationCode={resendAuthorizationCode}
          isResendingAuthorizationCode={isResendingAuthorizationCode}
          suspensionMessageId={suspensionMessageId}
          documentType={documentType}
          signingStatus={document?.signingStatus}
          signingOrder={document?.signingOrder}
          status={document?.status}
          inActive={isGdprSuspended}
        />
        {enableDownloadRecipientList && (
          <BtnCol oneThirdSplit>
            <LoadingActionButton
              isLoading={isDownloadingRecipientsList}
              onClick={handleDownloadRecipientsList}
              outline
            >
              <FormattedMessage
                id="Download the list of recipients"
                defaultMessage="Download the list of recipients"
              />
            </LoadingActionButton>
          </BtnCol>
        )}
      </Row>
      <Row>
        {enableNotificationHistory && (
          <NotificationHistory
            handleNotificationHistoryModalOpen={
              handleNotificationHistoryModalOpen
            }
            suspensionMessageId={suspensionMessageId}
            inActive={isGdprSuspended}
          />
        )}
        {enablePublicResendNotification && (
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol oneThirdSplit>
              <LoadingActionButton
                onClick={handleResendNotificationClick}
                outline
              >
                <FormattedMessage
                  id="Resend notification"
                  defaultMessage="Resend notification"
                />
              </LoadingActionButton>
            </BtnCol>
          </ProtectedComponent>
        )}
        {enableUpdateRecipients && (
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol oneThirdSplit>
              <UpdateRecipientListButton
                publicDocumentType={document?.publicDocumentType}
              />
            </BtnCol>
          </ProtectedComponent>
        )}
      </Row>
    </>
  );
};

export default ManageSection;
