import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../../../constraints';
import CardHeader from '../../../CardHeader';
import { Grid, Utils } from 'billon-ui';

const { Row, Col } = Grid;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const HeaderWithLoader = ({ primaryUrl, archiveUrl }) => (
  <Row md={12}>
    <Col md={12}>
      <CardHeader primaryUrl={primaryUrl} archiveUrl={archiveUrl}>
        <FormattedMessage
          id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES}
          defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES}
        />
      </CardHeader>
      <PageLoader />
    </Col>
  </Row>
);

export default HeaderWithLoader;
