import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

// constants
import {
  MAP_VALIDATION_USER_ERRORS,
  CUSTOMER,
  UserRoles,
} from '../../../../constraints';

// hooks
import { useSchema } from './useSchema';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// Components
import * as Styled from './styled';
import DataTab from './DataTab';
import { Form as FormModule, Utils, Grid } from 'billon-ui';

const { Col } = Grid;
const { FormGroup } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const RegisterForm = ({
  defaultValues,
  onSuccess,
  onBack,
  isLoading,
  errors: deliveredErrors,
}) => {
  const { customer } = useConfigContext();
  const isPhoneNumberRequired = [CUSTOMER.DEFAULT, CUSTOMER.DIPLOMA].includes(
    customer,
  );
  const submitButtonMessageId = 'Next';

  const schema = useSchema({
    isPhoneNumberRequired,
  });

  const formMethods = useForm({
    defaultValues: {
      role: UserRoles.VISITOR,
      isActive: true,
      phoneNumber: '+48',
      ...defaultValues,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = formMethods;

  const handleFormSubmit = (data) => {
    onSuccess(data);
  };

  useEffect(() => {
    if (deliveredErrors?.PHONE_NUMBER === 'NON_UNIQUE') {
      setError('phoneNumber', {
        type: 'custom',
        message: MAP_VALIDATION_USER_ERRORS.NON_UNIQUE_PHONE_NUMBER,
      });
    }
    if (deliveredErrors?.PHONE_NUMBER === 'INVALID') {
      setError('phoneNumber', {
        type: 'custom',
        message: MAP_VALIDATION_USER_ERRORS.INVALID_DOCUMENT_NUMBER,
      });
    }
    if (deliveredErrors?.EMAIL === 'NON_UNIQUE') {
      setError('email', {
        type: 'custom',
        message: MAP_VALIDATION_USER_ERRORS.NON_UNIQUE_EMAIL,
      });
    }
  }, [deliveredErrors]);

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
        <div>
          <Styled.Title>
            <FormattedMessage
              id="Enter Your account info"
              defaultMessage="Enter Your account info"
            />
          </Styled.Title>
        </div>
        <br />
        <DataTab />
        <FormGroup>
          <Col md={12} padding={0}>
            {isLoading ? (
              <ButtonLoader block size="lg" />
            ) : (
              <Button type="submit" size="lg" block>
                <FormattedMessage
                  id={submitButtonMessageId}
                  defaultMessage={submitButtonMessageId}
                />
              </Button>
            )}
          </Col>
        </FormGroup>
        <Styled.BackButton secondary onClick={onBack}>
          <FormattedMessage id="back" defaultMessage="back" />
        </Styled.BackButton>
      </Styled.Form>
    </FormProvider>
  );
};

export default RegisterForm;
