import React from 'react';

import * as Styled from './styled';

const NewTabBadgeAnchor = ({ children, href, enableIfMobile, active }) => (
  <Styled.Badge
    rel="noopener noreferrer"
    href={href}
    target="_blank"
    enableIfMobile={enableIfMobile}
    active={active}
  >
    {children}
  </Styled.Badge>
);

export default NewTabBadgeAnchor;
